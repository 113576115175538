import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse,
    HttpInterceptor
} from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { RouterService } from "src/app/shared/services/router.service";
import { LoginComponent } from "src/app/ui/frontend/login/login.component";

@Injectable()
export class NotAuthorizedInterceptor implements HttpInterceptor {

    constructor(public router: RouterService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.router.navigateTo(LoginComponent);
                }
                return throwError(() => error);
            }
            )
        );
    }
}
