import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LoginDto } from "src/app/common/dtos/login/body/login.dto";
import { Token } from "../../../common/models/token/token.model";

@Injectable({
    providedIn : "root"
})
export abstract class LocalStorageRepositoryInterface {
    public abstract saveToken(token: Token | undefined | null): void;

    public abstract loadToken(): Token | undefined | null;

    public abstract deleteToken(): void;

    public abstract login(body:LoginDto): Observable<Token>;

    public abstract saveUsername(username: string | undefined | null): void;

    public abstract loadUsername(): string | undefined | null;

    public abstract saveRole(role: string | undefined | null): void;

}