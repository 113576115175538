import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "src/app/shared/shared.module";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ProjectsComponent } from "./projects.component";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [
        ProjectsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        SharedModule,
        MatButtonModule,
        MatCheckboxModule,
        MatIconModule
    ],
    exports: [
        ProjectsComponent
    ]
})
export class ProjectsModule { }