import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BusinessUnitResponseDto } from "src/app/common/dtos/business-unit/business-unit.response";
import { BusinessUnitManager } from "../managers/business-unit.manager";

@Injectable({
    providedIn: "root"
})
export class BusinessUnitService {

    constructor(
        private businessUnitManager: BusinessUnitManager
    ) {}

    public getBusinessUnits(): Observable<BusinessUnitResponseDto> {
        return this.businessUnitManager.getBusinessUnits();
    }
}