import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CountryResponseDto } from "src/app/common/dtos/country/country.response";
import { CountriesRepositoryInterface } from "../repositories/countries/countries.interface";

@Injectable({
    providedIn:"root"
})
export class CountryManager {

    constructor(
        private countryRepository: CountriesRepositoryInterface
    ) {}

    public getCountries(): Observable<CountryResponseDto> {
        return this.countryRepository.getCountries();
    }

}