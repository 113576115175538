import { Injectable } from "@angular/core";
import { HttpBackend, HttpEvent, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export abstract class HttpBackendWithBaseUrl extends HttpBackend {
    constructor(
        protected http: HttpBackend
    ) {
        super();
    }

    public abstract get baseUrl(): string;

    override handle(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
        return this.http.handle(request.clone({
            url: this.baseUrl + request.url
        }));
    }
}