import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@ngx-translate/core";
import { LoadingOverlayComponent } from "./loading-overlay.component";
import { LoadingOverlayService } from "./services/loading-overlay.service";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";

@NgModule({
    declarations: [
        LoadingOverlayComponent
    ],
    providers: [
        LoadingOverlayService
    ],
    imports: [
        CommonModule,
        NgbModule,
        MatProgressSpinnerModule,
        TranslateModule.forChild()
    ],
    exports: [
        LoadingOverlayComponent
    ]
})
export class LoadingOverlayModule {

}