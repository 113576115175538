import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProjectCreateDto } from "src/app/common/dtos/project/project-create.dto";
import { ProjectUpdateDto } from "src/app/common/dtos/project/project-update.dto";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { Project } from "src/app/common/models/project/project.model";

@Injectable({
    providedIn : "root"
})
export abstract class ProjectsRepositoryInterface {

    public abstract getProjects(searchString?: string, page?: number, size?: number, sortBy?: string)
    : Observable<ProjectResponseDto>;

    public abstract getAllProjects() : Observable<ProjectResponseDto>;

    public abstract createProject(project: ProjectCreateDto): Observable<Project>;

    public abstract updateProject(id: string, project: ProjectUpdateDto): Observable<Project>;

    public abstract deleteProject(id: string): Observable<void>;
}