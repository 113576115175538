import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";

export class BusinessUnit extends Entity {

    @AutoMap()
    public code!: string;

    @AutoMap()
    public description!: string;

}