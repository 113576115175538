import { NgModule } from "@angular/core";
import { AuthGuard } from "./guards/auth.guard";
import { LocalStorageRepositoryModule } from "./repositories/local-storage/local-storage.module";
import { AuthInterceptorModule } from "./interceptors/auth/auth.module";
import { HttpClientModule } from "@angular/common/http";
import { LoaderModule } from "./components/loader/loader.module";
import { VersionModule } from "./components/version/version.module";
import { TranslateModule } from "@ngx-translate/core";
import { ErrorModule } from "./components/error/error.module";
import { UsersRepositoryModule } from "./repositories/users/users.module";
import { LoadingOverlayModule } from "./components/loading-overlay/loading-overlay.module";
import { TableModule } from "./components/table/table.module";
import { TableComponent } from "./components/table/table.component";
import { DialogPrototypeModule } from "./components/dialog-prototype/dialog-prototype.module";
import { DialogPrototypeComponent } from "./components/dialog-prototype/dialog-prototype.component";
import { FilterModule } from "./components/table/filter/filter.module";
import { FilterComponent } from "./components/table/filter/filter.component";
import { ObservationsRepositoryModule } from "./repositories/observations/observations.module";
import { ActivityTypeRepositoryModule } from "./repositories/activity-type/activity-type.module";
import { ReferencesRepositoryModule } from "./repositories/references/references.module";
import { DialogStepperComponent } from "./components/dialog-stepper/dialog-stepper.component";
import { DialogStepperModule } from "./components/dialog-stepper/dialog-stepper.module";
import { ProjectsRepositoryModule } from "./repositories/projects/projects.module";
import { CountriesRepositoryModule } from "./repositories/countries/countries.module";
import { DialogErrorModule } from "./components/dialog-stepper/dialog-error/dialog-error.module";
import { DialogMessageModule } from "./components/dialog-stepper/dialog-message/dialog-message.module";
import { StatusesRepositoryModule } from "./repositories/statuses/statuses.module";
import { BusinessUnitsRepositoryModule } from "./repositories/business-unit/business-units.module";
import { ReferenceClassificationsRepositoryModule }
    from "./repositories/reference-classification/reference-classifications.module";
import { TranslationRepositoryModule } from "./repositories/translation/translation.module";

@NgModule({
    imports: [
        UsersRepositoryModule,
        ObservationsRepositoryModule,
        ActivityTypeRepositoryModule,
        ReferencesRepositoryModule,
        StatusesRepositoryModule,
        ProjectsRepositoryModule,
        CountriesRepositoryModule,
        BusinessUnitsRepositoryModule,
        ReferenceClassificationsRepositoryModule,
        LoadingOverlayModule,
        TableModule,
        FilterModule,
        DialogPrototypeModule,
        DialogStepperModule,
        DialogMessageModule,
        TranslationRepositoryModule
    ],
    providers: [
        AuthGuard
    ],
    exports: [
        VersionModule,
        HttpClientModule,
        LoaderModule,
        ErrorModule,
        DialogErrorModule,
        DialogMessageModule,
        LocalStorageRepositoryModule,
        AuthInterceptorModule,
        TranslateModule,
        TableComponent,
        FilterComponent,
        DialogPrototypeComponent,
        DialogStepperComponent
    ],
    declarations: []
})
export class SharedModule {}