import { registerLocaleData } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import localeIt from "@angular/common/locales/it";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: [
        "./app.component.scss"
    ]
})
export class AppComponent implements OnInit {
    constructor(private translate: TranslateService) {

    }

    public ngOnInit(): void {
        registerLocaleData(localeIt);
        // TODO: Browser Lang
        // console.log(navigator.language)
        const browserLang = this.translate.getBrowserLang();
        this.translate.currentLang = typeof browserLang === "string" ? browserLang : "default";
        this.translate.setDefaultLang(this.translate.currentLang);
    }
}
