import { Routes } from "@angular/router";
import { FrontendComponent } from "./ui/frontend/frontend.component";
import { frontendRoutes } from "./ui/frontend/frontend.routes";
import { BackofficeComponent } from "./ui/backoffice/backoffice.component";
import { backofficeRoutes } from "./ui/backoffice/backoffice.routes";
import { AuthGuard } from "./shared/guards/auth.guard";

export const routes: Routes = [
    {
        path: "",
        component: FrontendComponent,
        children: frontendRoutes
    },
    {
        path: "backoffice",
        component: BackofficeComponent,
        children: backofficeRoutes,
        canActivate: [
            AuthGuard
        ]
    },
    {
        path: "**",
        redirectTo: "",
        pathMatch: "full"
    }
];