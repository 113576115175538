import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslationRepositoryInterface } from "../repositories/translation/translation.interface";
import { TranslationResponseDto } from "src/app/common/dtos/translation/translation.response";

@Injectable({
    providedIn:"root"
})
export class TranslationManager {

    constructor(
        private translationRepository: TranslationRepositoryInterface
    ) {}

    public getTranslations(): Observable<TranslationResponseDto> {
        return this.translationRepository.getTranslations();
    }

}