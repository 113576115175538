import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { DialogData } from "./dialog-data";
import { DialogField } from "./dialog-field";
import { DialogResponse } from "./dialog-response";
import { Option } from "../table/filter/option";
import { Observable, lastValueFrom } from "rxjs";
import { MatSelect } from "@angular/material/select";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { DialogMessageComponent } from "../dialog-stepper/dialog-message/dialog-message.component";
import { ObservationService } from "../../services/observation.service";
import { ObservationMediaCreateDto } from "src/app/common/dtos/observation-media/observation-media-create.dto";
import { environment } from "src/environments/environment";
import { ObservationMediaDto } from "src/app/common/dtos/observation-media/observation-media.dto";
import { UserService } from "../../services/user.service";
import { UserUpdatePasswordDto } from "src/app/common/dtos/user/user-update-password.dto";
import { HttpClient } from "@angular/common/http";
import { User } from "src/app/common/models/user/user.model";
import { ProjectDto } from "src/app/common/dtos/project/project.dto";
import { UserProjectDto } from "src/app/common/dtos/user-project/user-project.dto";

@Component({
    selector: "app-dialog-prototype",
    templateUrl: "./dialog-prototype.component.html",
    styleUrls: [
        "./dialog-prototype.component.scss"
    ]
})
export class DialogPrototypeComponent implements OnInit {

    filteredOptions!: Observable<Option[]>;

    currentFile?: File;
    fileName = "Select File";
    message: string[] = [];
    media!: ObservationMediaCreateDto;
    fileInfos?: ObservationMediaDto[];
    projectUserOption?: Option[];
    disableUpload = true;
    @ViewChild("searchField") searchField!: ElementRef;
    isLoading = true;

    constructor(
        private snackBar: MatSnackBar,
        private dialogRef: MatDialogRef<DialogPrototypeComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData,
        private translate: TranslateService,
        public dialog: MatDialog,
        public observationService: ObservationService,
        public userService: UserService,
        protected http: HttpClient) { }

    ngOnInit(): void {
        this.data.fields.forEach(async(field) => {
            // If it is a number type input and the boundaries are set
            // Check if it exceed the boundaries and limit the input accordingly
            if (field.type === "number" && field.boundaries) {
                field.formControl.valueChanges.subscribe((value) => {
                    if (!field.formControl.valid) {
                        if (field.boundaries) {
                            field.formControl.setValue(
                                Math.min(
                                    Math.max(value, field.boundaries.min), field.boundaries.max));
                        }
                    }
                });
            } else if (field.type === "select" && field.autocomplete) {
                field.autocomplete.valueChanges.subscribe((value) => {
                    this.filterOptions(value, field);
                });
            } else if (field.type === "multiSelect" && field.autocomplete) {
                field.autocomplete.valueChanges.subscribe((value) => {
                    this.filterOptions(value, field);
                });
            } else if (field.type === "autocomplete" && field.autocomplete) {
                field.autocomplete.valueChanges.subscribe((value) => {
                    this.filterOptions(value, field);
                });
            } else if (field.type === "file") {
                await this.getObservationMedia(this.data.uuid!);
                for (const elemento of this.fileInfos!) {
                    const item = elemento;
                    item.url = environment.apiUrl + "/media/" + item.id + "/file";
                }
                this.isLoading = false;
            } else if (field.type === "list") {
                await this.getUserProjects(this.data.uuid!);
            }
        });
    }

    async getUserProjects(userId: string) {
        const result = await lastValueFrom(this.observationService.getProjectsByUser(userId));
        if (result?.user_project) {
            const optionList = result?.user_project!.map((projectItem: UserProjectDto) => {
                return {

                    key: projectItem?.project.code?.toString()
                        + " - " + projectItem?.project.description?.toString()
                        + " - " + projectItem?.project.country.description,
                    value: projectItem?.id.toString()
                };
            }).sort((a, b) => parseInt(b.value, 10) - parseInt(a.value, 10));
            this.projectUserOption = optionList;
        } else {
            this.projectUserOption = [];
        }

    }

    async getObservationMedia(id: string) {
        const result = await lastValueFrom(this.observationService.getObservationMedia(id));
        const optionList: ObservationMediaDto[] = result?.media.map((mediaItem) => {
            return {
                id: mediaItem.id.toString(), name: mediaItem.name
            };
        });
        this.fileInfos = optionList;
        this.checkFileUpload();
    }

    selectedProjects: string[] = [];

    toggleSelection(index: number) {
        const projectId = this.projectUserOption![index].value;
        if (this.selectedProjects.includes(projectId)) {
            const indexToRemove = this.selectedProjects.indexOf(projectId);
            this.selectedProjects.splice(indexToRemove, 1);
        } else {
            this.selectedProjects.push(projectId);
        }
    }

    async buttonClicked(buttonType: string) {

        switch (buttonType) {
            case "cancel": {
                this.dialogRef.close();
                break;
            }
            case "add": {
                const formResult: DialogResponse = {
                    uuid: this.data.uuid || "",
                    dataToSave: {}
                };
                for (const field of this.data.fields) {
                    if (field.type === "select" || field.type === "multiSelect") {
                        if (field.formControl.value) {
                            formResult.dataToSave[field.name] = field.formControl.value;
                        }
                    } else {
                        if (field.formControl.getRawValue() != null) {
                            formResult.dataToSave[field.name] = field.formControl.getRawValue();
                        }
                    }
                }
                // eslint-disable-next-line
                if (!formResult.dataToSave["project_id"]) {
                    this.snackBar.open(this.translate.instant("message.remove.user.project.warning"),
                        undefined, {
                            duration: 3000,
                            panelClass: "app-notification-warning"
                        });
                    break;
                } else {
                    // eslint-disable-next-line
                    const id = formResult.dataToSave["project_id"] as string;
                    const isPresent = this.checkIfIdIsPresent(id);

                    if (isPresent) {
                        this.snackBar.open("Progetto già presente",
                            undefined, {
                                duration: 3000,
                                panelClass: "app-notification-warning"
                            });
                        break;
                    } else {
                        // eslint-disable-next-line
                        await this.observationService.createUserProject(this.data.uuid!, formResult.dataToSave["project_id"] as string)
                            .subscribe(() => {
                                this.getUserProjects(this.data.uuid!);
                                this.snackBar.open(this.translate.instant("message.add.user.project.success"),
                                    undefined, {
                                        duration: 3000,
                                        panelClass: "app-notification-success"
                                    });
                            });
                    }

                }

                break;
            }
            case "removeSelected": {
                if (this.selectedProjects.length < 1) {
                    this.snackBar.open(this.translate.instant("message.remove.user.project.warning"),
                        undefined, {
                            duration: 3000,
                            panelClass: "app-notification-warning"
                        });
                    break;
                } else {
                    this.selectedProjects.forEach((projectId) => {

                        this.observationService.deleteUserProject(projectId)
                            .subscribe(() => {
                                this.snackBar.open(this.translate.instant("message.remove.user.project.success"),
                                    undefined, {
                                        duration: 3000,
                                        panelClass: "app-notification-success"
                                    });
                            });
                    });
                    this.projectUserOption = this.projectUserOption!.filter((option) =>
                        !this.selectedProjects.includes(option.value)
                    );
                }
                this.selectedProjects = [];
                break;
            }
            case "back": {
                const msg = this.translate.instant("message.close.window");
                const dialogRef = this.dialog.open(DialogMessageComponent, { data: { msg } });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        this.dialogRef.close();
                    }
                });

                break;
            }
            case "submit": {
                const formResult: DialogResponse = {
                    uuid: this.data.uuid || "",
                    dataToSave: {}
                };

                for (const field of this.data.fields) {
                    if (field.formControl.invalid) {
                        const translatedLabel = this.translate.instant(field.label);
                        this.translate.get("errorMessages.fieldRequired", { fieldName: field.label })
                            .subscribe((translatedMessage: string) => {

                                this.snackBar.open(translatedMessage.replace("fieldName", translatedLabel), undefined, {
                                    duration: 2500,
                                    panelClass: "snackbarError"
                                });
                            });
                        return;
                    }
                    if (field.type === "select" || field.type === "multiSelect") {
                        if (field.formControl.value) {
                            formResult.dataToSave[field.name] = field.formControl.value;
                        }
                    } else {
                        if (field.formControl.getRawValue() != null) {
                            formResult.dataToSave[field.name] = field.formControl.getRawValue();
                        }
                    }
                }

                this.dialogRef.close(formResult);
                break;
            }
            case "reset": {

                const msg = this.translate.instant("message.reset.password");
                const dialogRef = this.dialog.open(DialogMessageComponent, { data: { msg } });

                dialogRef.afterClosed().subscribe((result) => {
                    if (result) {
                        const formResult: DialogResponse = {
                            uuid: this.data.uuid || "",
                            dataToSave: {}
                        };
                        for (const field of this.data.fields) {
                            if (field.type === "select" || field.type === "multiSelect") {
                                if (field.formControl.value) {
                                    formResult.dataToSave[field.name] = field.formControl.value;
                                }
                            } else {
                                if (field.formControl.getRawValue() != null) {
                                    formResult.dataToSave[field.name] = field.formControl.getRawValue();
                                }
                            }
                        }

                        const userUpdatePasswordDto: UserUpdatePasswordDto = {
                            // eslint-disable-next-line
                            id: this.data.uuid as string,
                            // eslint-disable-next-line
                            first_name: formResult.dataToSave["first_name"] as string,
                            // eslint-disable-next-line
                            last_name: formResult.dataToSave["last_name"] as string,
                            // eslint-disable-next-line
                            role: formResult.dataToSave["role"] as string,
                            // eslint-disable-next-line
                            username: formResult.dataToSave["username"] as string
                        };

                        this.userService.resetPassword(userUpdatePasswordDto.username)
                            .subscribe(() => {
                                this.snackBar.open(this.translate.instant("message.reset.password.success"),
                                    undefined, {
                                        duration: 3000,
                                        panelClass: "app-notification-success"
                                    });
                            });
                        this.dialogRef.close();
                    }
                });

                break;
            }
            default: {
                this.dialogRef.close();
                break;
            }
        }
    }

    checkIfIdIsPresent(id: string): boolean {
        return this.projectUserOption!.some((option) => {
            // eslint-disable-next-line
            return option.value.toString() == id;
        });
    }

    checkDisabled(field: DialogField) {
        if (field.mutuallyExcludes) {
            const fieldToExclude = this.data.fields.find((f) => f.name === field.mutuallyExcludes);

            if (fieldToExclude?.formControl.getRawValue()) {
                field.formControl.disable();
                return true;
            } else {
                field.formControl.enable();
                return false;
            }
        }

        return false;
    }

    clearSelection(field: DialogField) {
        field.formControl.setValue("");
    }

    openSelect(selectElement: MatSelect) {
        selectElement.open();
    }

    onSelectOpened(opened: boolean): void {
        if (opened) {
            this.searchField.nativeElement.focus();
        }
    }

    filterOptions(filterString: string, field: DialogField) {
        field.filteredOptions = field.options?.filter((option) =>
            option.key.toLowerCase().includes(filterString.toLowerCase()));
    }

    selectFile(event: Event): void {
        const inputElement = event.target as HTMLInputElement;
        if (inputElement.files && inputElement.files[0]) {
            const file: File = inputElement.files[0];
            this.currentFile = file;
            this.fileName = this.currentFile.name;
        } else {
            this.fileName = "Select File";
        }
    }

    async downloadFile(file: ObservationMediaDto) {
        try {
            const apiUrl = `${environment.apiUrl}/media/${file.id}/file`;
            const response = await fetch(apiUrl);

            if (!response.ok) {
                throw new Error(`Errore nella richiesta: ${response.status} - ${response.statusText}`);
            }

            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = blobUrl;
            const parts = file.name.split(".");
            const extension = parts.length > 1 ? parts.pop() : "";

            if (!extension) {
                file.name += ".png";
            }
            a.download = file.name;
            document.body.appendChild(a);
            a.click();
            URL.revokeObjectURL(blobUrl);

        } catch (error) {
            // console.error("Errore durante il download del file:", error);
            throw new Error(`Errore durante il download del file: ${error}`);
        }
    }

    async upload(data: DialogData): Promise<void> {
        const fileToUpload = this.currentFile;
        const maxFileSizeMB = environment.maxFileUpload;
        const maxSizeInBytes = maxFileSizeMB * 1024 * 1024;
        if (fileToUpload!.size > maxSizeInBytes) {
            const msg = this.translate.instant("message.upload.image.size").replace("maxFileSizeMB", maxFileSizeMB);
            this.snackBar.open(msg, undefined, {
                duration: 3000,
                panelClass: "app-notification-warning"
            });
        } else {
            const formData = new FormData();
            formData.append("file", fileToUpload!);

            const createMediaDto: ObservationMediaCreateDto = {
                name: fileToUpload!.name,
                type: fileToUpload!.type
            };

            formData.append("name", createMediaDto.name);
            formData.append("type", createMediaDto.type);
            this.observationService.createObservationMedia(data.uuid!, formData).subscribe(
                (response) => {
                    this.fileName = "Select File";
                    this.currentFile = undefined;
                    this.getObservationMedia(this.data.uuid!);

                },
                (error) => {
                    throw new Error(`Errore durante upload del file: ${error}`);
                });
        }

    }

    deleteFile(index: string, fileName: string) {
        const msg = this.translate.instant("message.delete.file");
        const dialogRef = this.dialog.open(DialogMessageComponent, { data: { msg } });
        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.observationService.deleteObservationMedia(index)
                    .subscribe(async() => {
                        await this.getObservationMedia(this.data.uuid!);
                        this.snackBar.open(this.translate.instant("File " + fileName + " Eliminato"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });
            }
        });

    }

    filterSelectOptions(event: Event, field: DialogField) {
        const inputElement = event.target as HTMLInputElement;
        field.filteredOptions = field.options?.filter((option) =>
            option.key.toLowerCase().includes(inputElement.value.toLowerCase()));
    }

    checkFileUpload() {
        if (this.fileInfos!.length < 3) {
            this.disableUpload = false;
        } else {
            this.disableUpload = true;
        }
    }

}
