import { ObservationsComponent } from "./components/observations/observations.component";
import { Routes } from "@angular/router";
import { UsersComponent } from "./components/users/users.component";
import { ProjectsComponent } from "./components/projects/projects.component";
import { AuthGuard } from "src/app/shared/guards/auth.guard";
import { ReferencesComponent } from "./components/references/references.component";

export const backofficeRoutes: Routes = [
    // {
    //     path: "",
    //     redirectTo: "backoffice",
    //     pathMatch: "full"
    // },
    // {
    //     path: "login",
    //     component: LoginComponent
    // },
    // {
    //     path: "",
    //     component: BackofficeComponent
    // },
    {
        path: "users",
        component: UsersComponent, canActivate:[
            AuthGuard
        ]
    },
    {
        path: "observations",
        component: ObservationsComponent, canActivate:[
            AuthGuard
        ]
    },
    {
        path: "projects",
        component: ProjectsComponent, canActivate:[
            AuthGuard
        ]
    },
    {
        path: "references",
        component: ReferencesComponent, canActivate:[
            AuthGuard
        ]
    }
];