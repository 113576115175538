<div class="row mx-1 mt-3">
  <div class="col-7">
    <h4 class="pageTitle">{{ "observationcard.title" | translate }}</h4>
  </div>
  <div class="col-5" style="text-align: end">
    <button
      mat-icon-button
      *ngIf="visibleButtonExport"
      [disabled]="isDownloading"
      color="basic"
      style="border: trasparent; background-color: transparent"
      (click)="downloadCsv()"
    >
      <ng-container *ngIf="!isDownloading; else spinnerTemplate">
        <img src="../../../../../assets/images/icon_csv.png" alt="Export CSV" />
      </ng-container>
      <ng-template #spinnerTemplate>
        <mat-spinner diameter="24"></mat-spinner>
      </ng-template>
    </button>
    <button
      mat-raised-button
      color="primary"
      style="max-width: 200px"
      (click)="openStepperDialog()"
    >
      <mat-icon>add_card</mat-icon>
      {{ "button.create" | translate }}
    </button>
  </div>
  <hr />
</div>
<app-loader *ngIf="isLoading"></app-loader>
<div [class.disabled]="isDisabled">
  <app-filter
    [localFilters]="false"
    [fields]="filters"
    (applyClicked)="onFilterUpdate($event)"
  ></app-filter>

  <app-table
    [filters]="filtersData"
    [columns]="tableColumns"
    [dataSource]="tableData"
    [rowActionIcons]="[
      'upload',
      'manage_history',
      'edit',
      'event_note',
      'delete'
    ]"
    (rowAction)="onActionFromTableEmitted($event)"
    (page)="requestPage($event)"
    [totalElements]="requestTotalElements"
    [resetPageEvent]="resetPageEventEmitter"
    (columnSort)="onSortTable($event)"
  >
  </app-table>
</div>
