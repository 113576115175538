import { AutoMap } from "@automapper/classes";
import { User } from "../../models/user/user.model";
import { ActivityType } from "../../models/activity-type/activity-type.model";
import { Reference } from "../../models/reference/reference.model";
import { Project } from "../../models/project/project.model";
import { ObservationStatus } from "../../models/observation-status/observation-status.model";
import { Status } from "../../models/status/status.model";
import { ObservationMedia } from "../../models/observation-media/observation-media.model";

export class ObservationDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public "first_name"!:string;

    @AutoMap()
    public "last_name"!:string;

    @AutoMap()
    public role!:string;

    @AutoMap()
    public "insert_date"!: Date;

    @AutoMap()
    public "card_number"!: string;

    @AutoMap()
    public location!: string;

    @AutoMap()
    public action?: string;

    public activity?: string;

    @AutoMap()
    public user?: User;

    @AutoMap()
    public "user_id"?: string;

    @AutoMap()
    public status?: Status;

    @AutoMap()
    public classification?: number;

    @AutoMap()
    public company?: string;

    @AutoMap()
    public jobPosition?:string;

    @AutoMap()
    public observation?: string;

    @AutoMap()
    public "activity_type"!: ActivityType;

    @AutoMap()
    public project?: Project;

    @AutoMap()
    public "observation_status"!: ObservationStatus[];

    @AutoMap()
    public "observation_reference"?: Reference[];

    @AutoMap(()=>Number)
    public ref!: number[];

    @AutoMap()
    public refString?: string;

    @AutoMap()
    public note?: boolean;

    @AutoMap()
    public countnote?: string;

    @AutoMap()
    public descrizionenote?: string;

    @AutoMap()
    public countmedia?: string;

    @AutoMap()
    public media!: ObservationMedia[];

    @AutoMap()
    public origin?: string;

    @AutoMap()
    /* eslint-disable camelcase */
    public status_description?: string;
}