import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { ActivityTypeDto } from "src/app/common/dtos/activity-type/activity-type.dto";
import { ActivityTypeResponseDto } from "src/app/common/dtos/activity-type/activity-type.response";
import { ActivityTypeRepositoryInterface } from "./activity-type.interface";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class ActivityTypeRepository extends Repository implements ActivityTypeRepositoryInterface {

    public getActivities(lang: string): Observable<ActivityTypeResponseDto> {
        let httpParams: HttpParams = new HttpParams();
        if(lang) {
            httpParams = httpParams.set("lang", lang);
        }
        return this.http.get<ActivityTypeDto[]>("/activity-types", { params: httpParams })
            .pipe(map((activityTypeDto: ActivityTypeDto[]) => {
                const dto = new ActivityTypeResponseDto();
                dto.content = activityTypeDto;
                return dto;
            }));
    }
}