import { Component, Inject } from "@angular/core";
import { APP_VERSION } from "./version.token";

@Component({
    selector: "app-version",
    templateUrl: "./version.component.html",
    styleUrls: [
        "./version.component.scss"
    ]
})
export class VersionComponent {
    constructor(
        @Inject(APP_VERSION) public version: string) {
    }
}