import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ObservationReference } from "./observation-reference.model";
import { ObservationReferenceDto } from "../../dtos/observation-reference/observation-reference.dto";

export const observationReferenceProfile: MappingProfile = (mapper) => {
    createMap(mapper, ObservationReferenceDto, ObservationReference, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};