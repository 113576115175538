import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { CountryDto } from "src/app/common/dtos/country/country.dto";
import { CountryResponseDto } from "src/app/common/dtos/country/country.response";
import { CountriesRepositoryInterface } from "./countries.interface";

@Injectable()
export class CountriesRepository extends Repository implements CountriesRepositoryInterface {

    public getCountries(): Observable<CountryResponseDto> {
        return this.http.get<CountryDto[]>("/countries").pipe(map((countryDto: CountryDto[]) => {
            const dto = new CountryResponseDto();
            dto.content = countryDto;
            return dto;
        }));
    }
}