import { ReferenceClassificationsDto }
    from "./../../../common/dtos/reference-classifications/reference-classifications.dto";
import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { ReferenceClassificationsResponseDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.response";
import { ReferenceClassificationsRepositoryInterface }
    from "./reference-classifications.interface";

@Injectable()
export class ReferenceClassificationsRepository extends Repository
    implements ReferenceClassificationsRepositoryInterface {

    public getReferenceClassifications(searchString?: string, page?: number, size?: number)
        : Observable<ReferenceClassificationsResponseDto> {
        let httpParams: HttpParams = new HttpParams();
        if (page) {
            httpParams = httpParams.set("page", page);
        }
        if (size) {
            httpParams = httpParams.set("size", size);
        }
        if (searchString) {
            httpParams = httpParams.set("search", searchString);
        }

        return this.http.get<ReferenceClassificationsDto[]>("/reference-classifications", { params: httpParams }).
            pipe(map((referenceClassificatonsDto: ReferenceClassificationsDto[]) => {
                const dto = new ReferenceClassificationsResponseDto();
                dto.content = referenceClassificatonsDto;
                return dto;
            }));
    }

    public getReferenceClassification(id: string): Observable<ReferenceClassificationsDto> {
        return this.http.get<ReferenceClassificationsDto>(`/reference-classifications/${id}`).
            pipe(map((referenceClassificatonsDto: ReferenceClassificationsDto) => {
                return referenceClassificatonsDto;
            }));
    }

}