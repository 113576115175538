import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    forMember,
    mapFrom,
    namingConventions
} from "@automapper/core";
import { Observation } from "./observation.model";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";
import { ObservationExport } from "./observation-export.model";

export const observationProfile: MappingProfile = (mapper) => {
    createMap(mapper, ObservationDto, Observation, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }),
    forMember(
        (destination) => destination.activity,
        mapFrom(
            (source) => source.activity
        )
    ));
    createMap(mapper, ObservationDto, ObservationExport, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }),
    forMember(
        (destination) => destination.activity,
        mapFrom(
            (source) => source.activity
        )
    ),
    forMember(
        (destination) => destination.role,
        mapFrom(
            (source) => source.role
        )
    )
    );
};
