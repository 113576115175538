import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { TranslateService } from "@ngx-translate/core";
import { UserDto } from "src/app/common/dtos/user/user.dto";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { User } from "src/app/common/models/user/user.model";
import { DialogData } from "src/app/shared/components/dialog-prototype/dialog-data";
import { DialogPrototypeComponent } from "src/app/shared/components/dialog-prototype/dialog-prototype.component";
import { Column } from "src/app/shared/components/table/column";
import { Field } from "src/app/shared/components/table/filter/field";
import { UserService } from "src/app/shared/services/user.service";
import { Option } from "./../../../../shared/components/table/filter/option";
import { ObservationService } from "src/app/shared/services/observation.service";
import { lastValueFrom } from "rxjs";
import { ProjectDto } from "src/app/common/dtos/project/project.dto";
import { ProjectService } from "src/app/shared/services/project.service";

@Component({
    selector: "app-users",
    templateUrl: "./users.component.html",
    styleUrls: [
        "./users.component.scss"
    ]
})
export class UsersComponent implements OnInit {

    tableData!: User[];
    tableColumns!: Column[];

    filters: Field[] = this.getFilters();

    filterSource!: MatTableDataSource<unknown>;

    filtersData!: Map<string,
    { customValidation?: (filterValue: unknown, dataValue: unknown) => boolean, value: string }>;

    currentFilterSearchString = "";

    currentPageSize = 5;

    requestTotalElements = 0;

    sortTable = "id:DESC";

    projectOption!: Option[];
    projectUserOption! : Option[];

    resetPageEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    constructor(
        private userService: UserService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private projectService: ProjectService,
        private observationService: ObservationService
    ) { }

    ngOnInit(): void {
        this.tableColumns = this.getColumns();
        this.getUsers(undefined, 0, this.currentPageSize);
        this.getAllProjects();
    }

    requestPage(page: { page: number, size: number }) {
        this.currentPageSize = page.size;
        this.getUsers(this.currentFilterSearchString, page.page + 1, page.size);
    }

    getColumns(): Column[] {
        return [
            { caption: "users.form.name", field: "firstName", sort: true },
            { caption: "users.form.lastname", field: "lastName", sort: true },
            { caption: "users.form.email", field: "username", sort: true },
            { caption: "users.form.role", field: "role", sort: true },
            { caption: "users.form.company", field: "company", sort: true, format(value) {
                if(String(value) === "[object Object]") {
                    return "";
                }else{
                    return String(value);
                }
            } },
            { caption: "users.form.isactive", field: "isActive", sort: true, format(value) {
                if(String(value) === "true") {
                    return "ACTIVE";
                }else{
                    return "DISACTIVE";
                }
            } }

        ];
    }

    async getAllProjects(): Promise<Option[]> {
        const result = await lastValueFrom(this.projectService.getAllProjects());

        const optionList = result?.content!.map((projectItem: ProjectDto) => {
            return {
                key: projectItem?.code?.toString()
                + " - " + projectItem?.description?.toString()
                + " - " + projectItem?.country.description,
                value: projectItem?.id
            };
        });
        optionList.sort((a, b) => a.key.localeCompare(b.key));
        this.projectOption = optionList;
        return optionList;
    }

    getUsers(searchString?: string, page?: number, size?: number, sortBy?: string) {
        this.userService.getUsers(searchString, page, size, sortBy).subscribe((result) => {
            this.requestTotalElements = result.meta?.totalItems || 0;
            this.tableData = mapper.mapArray(result?.data, UserDto, User);
        });
    }

    getFilters(): Field[] {
        return [
            {
                id: "firstName",
                caption: "users.form.name",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "firstName",
                selectedFilter: "",
                options: []
            },
            {
                id: "lastName",
                caption: "users.form.lastname",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "lastName",
                selectedFilter: "",
                options: []
            },
            {
                id: "email",
                caption: "users.form.email",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "username",
                selectedFilter: "",
                options: []
            },
            {
                id: "company",
                caption: "users.form.company",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "company",
                selectedFilter: "",
                options: []
            },
            {
                id: "isActive",
                caption: "users.form.isactive",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "isActive",
                selectedFilter: "",
                options: [
                    { key:"Attivo", value:"true" }, { key:"Non Attivo", value:"false" }
                ]
            }
        ];
    }

    onSortTable(eventData: unknown) {
        const dataEmitted = eventData as { data: string };
        const splitted = this.sortTable.split(":");
        if(splitted[1] === "DESC") {
            this.sortTable = dataEmitted.data + ":ASC";
        }else{
            this.sortTable = dataEmitted.data + ":DESC";
        }

        this.getUsers(this.currentFilterSearchString, 1, this.currentPageSize, this.sortTable);
    }

    onActionFromTableEmitted(eventData: unknown) {
        const dataEmitted = eventData as { action: string, data: User };

        if (dataEmitted.action === "delete") {
            this.openDeleteDialog(dataEmitted.data);
        } else if (dataEmitted.action === "edit") {
            this.openEditDialog(dataEmitted.data);
        }else if (dataEmitted.action === "account_tree") {
            this.openAuthProjectsDialog(dataEmitted.data);
        }
    }

    onFilterDataSourceReceived(eventData: unknown) {
        const data = eventData as MatTableDataSource<unknown>;
        this.filterSource = data;
    }

    openCreateDialog(): void {
        const dialogFields: DialogData = {
            title: "users.create.title",
            visibleButtonClose: true,
            fields: [
                {
                    name: "first_name",
                    type: "text",
                    label: "users.create.name",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    },
                    [
                        Validators.required
                    ])
                },
                {
                    name: "last_name",
                    type: "text",
                    label: "users.create.lastname",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    },
                    [
                        Validators.required
                    ])
                },
                {
                    name: "username",
                    type: "email",
                    label: "users.create.email",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required,
                        Validators.pattern(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
                        Validators.minLength(5),
                        Validators.maxLength(50)
                    ])
                },
                {
                    name: "password",
                    type: "password",
                    label: "users.create.password",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required,
                        Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-=_+{};:',.<>?]).{8,20}$/),
                        Validators.minLength(8),
                        Validators.maxLength(20)
                    ])
                },
                {
                    name: "role",
                    type: "select",
                    label: "users.create.role",
                    options: [
                        { key:"USER", value:"USER" }, { key:"ADMIN", value:"ADMIN" }
                    ],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "company",
                    type: "text",
                    label: "users.create.company",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "is_active",
                    type: "checkbox",
                    label: "users.create.isactive",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.create",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.userService.createUser(response.dataToSave).subscribe(() => {
                        this.resetPageEventEmitter.emit();
                        const translatedLabel = this.translate.instant("users.create.success");
                        this.snackBar.open(translatedLabel.replace("username", response.dataToSave.username),
                            undefined, {
                                duration: 3000,
                                panelClass: "app-notification-success"
                            });
                    });
                }
            });
    }

    openAuthProjectsDialog(data: User) {
        const dialogFields: DialogData = {
            title: "user.project.title",
            uuid: data.id,
            visibleButtonClose: false,
            fields: [
                {
                    name: "project_id",
                    type: "selectInput",
                    label: "user.project.add.project",
                    options: this.projectOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "list_id",
                    type: "list",
                    label: "user.project.list.project",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.add",
                    type: "add",
                    icon: "add",
                    color: "primary"
                },
                {
                    label: "button.remove",
                    type: "removeSelected",
                    icon: "playlist_remove",
                    color: "accent"
                },
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.snackBar.open(data.username, undefined, {
                    duration: 3000,
                    panelClass: "app-notification-success"
                });
            }
        });
    }

    openEditDialog(data: User) {
        const dialogFields: DialogData = {
            title: "users.detail.title",
            uuid: data.id,
            visibleButtonClose: true,
            fields: [
                {
                    name: "first_name",
                    type: "text",
                    label: "users.detail.name",
                    options: [],
                    formControl: new FormControl({
                        value: data.firstName,
                        disabled: false
                    })
                },
                {
                    name: "last_name",
                    type: "text",
                    label: "users.detail.lastname",
                    options: [],
                    formControl: new FormControl({
                        value: data.lastName,
                        disabled: false
                    })
                },
                {
                    name: "username",
                    type: "email",
                    label: "users.detail.email",
                    options: [],
                    formControl: new FormControl({
                        value: data.username,
                        disabled: false
                    }, [
                        Validators.required,
                        Validators.pattern(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
                        Validators.minLength(5),
                        Validators.maxLength(50)
                    ])
                },
                {
                    name: "role",
                    type: "text",
                    label: "users.detail.role",
                    options: [],
                    formControl: new FormControl({
                        value: data.role,
                        disabled: false
                    })
                },
                {
                    name: "company",
                    type: "text",
                    label: "users.detail.company",
                    options: [],
                    formControl: new FormControl({
                        value: data.company,
                        disabled: false
                    })
                },
                {
                    name: "is_active",
                    type: "checkbox",
                    label: "users.detail.isactive",
                    options: [],
                    formControl: new FormControl({
                        value: data.isActive,
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.save",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                },
                {
                    label: "button.resetPsw",
                    type: "reset",
                    icon: "lock_reset",
                    color: "danger"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.userService.updateUser(response.uuid, response.dataToSave).subscribe(() => {
                    this.resetPageEventEmitter.emit();

                    const translatedLabel = this.translate.instant("users.detail.success");
                    this.snackBar.open(translatedLabel.replace("username", response.dataToSave.username), undefined, {
                        duration: 3000,
                        panelClass: "app-notification-success"
                    });
                });
            }
        });
    }

    openDeleteDialog(data: User) {
        const translatedLabel = this.translate.instant("users.delete.headertext").replace("username", data.username);

        const dialogFields: DialogData = {
            title: "users.delete.title",
            headerText: translatedLabel,
            visibleButtonClose: false,
            fields: [],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "button.delete",
                    type: "submit",
                    icon: "delete",
                    color: "accent"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.userService.deleteUser(data.id).subscribe(() => {
                        this.resetPageEventEmitter.emit();

                        const translated = this.translate.instant("users.delete.success");
                        this.snackBar.open(translated, undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });
                }
            });
    }

    onFilterUpdate(searchString: string): void {
        this.currentFilterSearchString = searchString;
        this.resetPageEventEmitter.emit();
    }

    // synchronizeUsers(): void {
    //     this.userService.synchronizeUsers().subscribe(() => {
    //         this.resetPageEventEmitter.emit();
    //     });
    // }

}
