import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import { DialogErrorComponent } from "./dialog-error.component";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
    declarations:[
        DialogErrorComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        TranslateModule.forChild()
    ],
    exports: [
        DialogErrorComponent
    ]
})
export class DialogErrorModule {}
