import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ObservationMediaResponseDto } from "src/app/common/dtos/observation-media/observation-media.response";
import { ObservationStatusResponseDto } from "src/app/common/dtos/observation-status/observation-status.response";
import { ObservationCreateDto } from "src/app/common/dtos/observation/observation-create.dto";
import { ObservationUpdateDto } from "src/app/common/dtos/observation/observation-update.dto";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";
import { ObservationResponseDto } from "src/app/common/dtos/observation/observation.response";
import { ObservationResponseAllDto } from "src/app/common/dtos/observation/observation.response.all";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { UserProjectResponseDto } from "src/app/common/dtos/user-project/user-project.response";
import { ObservationMedia } from "src/app/common/models/observation-media/observation-media.model";
import { ObservationStatus } from "src/app/common/models/observation-status/observation-status.model";
import { Observation } from "src/app/common/models/observation/observation.model";
import { UserProject } from "src/app/common/models/user-project/user-project.model";

@Injectable({
    providedIn : "root"
})
export abstract class ObservationsRepositoryInterface {

    public abstract getObservations(searchString?: string,
        page?: number,
        size?: number,
        sortBy?: string,
        lang?: string,
        projects? : string):
    Observable<ObservationResponseDto>;

    public abstract getAllObservations(searchString?: string, sortBy?: string, lang?: string, projects?: string):
    Observable<ObservationResponseAllDto>;

    public abstract getObservation(id: string,
        lang?: string):
    Observable<Observation>;

    public abstract createObservation(observation: ObservationCreateDto): Observable<Observation>;

    public abstract updateObservation(id: string, observation: ObservationUpdateDto):
    Observable<Observation>;

    public abstract deleteObservation(id: string): Observable<void>;

    public abstract getObservationStatus(id: string) : Observable<ObservationStatusResponseDto>;

    public abstract createObservationStatus(observationId: string,
        statusId: string,
        comment?:string):
    Observable<ObservationStatus>;

    public abstract getObservationMedia(id: string) : Observable<ObservationMediaResponseDto>;

    public abstract createObservationMedia(observationId: string, formData: FormData):
    Observable<ObservationMedia>;

    public abstract deleteObservationMedia(id: string): Observable<void>;

    public abstract getProjectsByUser(id:string):Observable<UserProjectResponseDto>;

    public abstract createUserProject(userId: string, projectId: string):
    Observable<UserProject>;

    public abstract deleteUserProject(id: string): Observable<void>;
}