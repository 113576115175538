/* eslint-disable @typescript-eslint/no-explicit-any */
import { Location } from "@angular/common";
import { Injectable, Type } from "@angular/core";
import { NavigationExtras, Router, Routes } from "@angular/router";
import { from, Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class RouterService {
    private componentPaths: Map<Type<any>, string>;

    constructor(
        public router: Router,
        public location?: Location
    ) {
        this.componentPaths = new Map<Type<any>, string>(
            RouterService.recursiveComponentPaths(this.router.config)
                .map((value) => value as [Type<any>, string])
        );
    }

    public navigateTo(
        component: Type<any>,
        extras?: NavigationExtras & { segments?: Record<string, any>}): Observable<boolean> {
        const path = this.componentPaths.get(component) ?? "";
        return from(this.router.navigate([
            this.replaceSegments(path, extras?.segments)
        ], extras));
    }

    public goBackRoute(): void {
        this.location?.back();
    }

    private static recursiveComponentPaths(routes: Routes | undefined, previousPath = ""): [[Type<any>, string]?] {
        if(routes === undefined) return [];

        return routes.reduce<[[Type<any>, string]?]>((previous, route) => {
            if(route.path === undefined || route.component === undefined) return previous;

            const path = route.path ? `${previousPath}/${route.path}` : route.path;
            const _routes: [[Type<any>, string]] = [
                [
                    route.component, path
                ]
            ];
            _routes.push(...previous.map((value) => value as [Type<any>, string]));
            _routes.push(
                ...this.recursiveComponentPaths(route.children, path)
                    .map((value) => value as [Type<any>, string])
            );
            return _routes;
        }, []);
    }

    private replaceSegments(path: string, segments?: Record<string, any>): string {
        if(segments == null) return path;

        for(const segment in segments) {
            if(segments[segment] != null) {
                path = path.replaceAll(`:${segment}`, segments[segment].toString());
            }
        }

        return path;
    }
}