import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SidebarComponent } from "./sidebar.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { AppRoutingModule } from "src/app/app-routing.module";
import { ToolbarModule } from "../toolbar/toolbar.module";
import { MatIconModule } from "@angular/material/icon";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
    declarations: [
        SidebarComponent
    ],
    imports: [
        ToolbarModule,
        CommonModule,
        AppRoutingModule,
        BrowserModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatSidenavModule,
        MatListModule,
        MatIconModule,
        TranslateModule
    ],
    exports: [
        SidebarComponent
    ]
})
export class SidebarModule { }
