import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StatusResponseDto } from "src/app/common/dtos/status/status.response";

@Injectable({
    providedIn : "root"
})
export abstract class StatusesRepositoryInterface {

    public abstract getStatus(): Observable<StatusResponseDto>;

}