import { Component } from "@angular/core";

@Component({
    selector: "app-backoffice",
    templateUrl: "./backoffice.component.html",
    styleUrls: [
        "./backoffice.component.scss"
    ]
})
export class BackofficeComponent {

}
