import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActivityTypeResponseDto } from "src/app/common/dtos/activity-type/activity-type.response";

@Injectable({
    providedIn : "root"
})
export abstract class ActivityTypeRepositoryInterface {

    public abstract getActivities(lang:string): Observable<ActivityTypeResponseDto>;

}