<h2 class="modal-title" mat-dialog-title>{{ data.error.title }}</h2>
<mat-dialog-content class="mat-typography">
  <div class="modal-body">
    <p *ngFor="let item of data.error.message">{{ item }}</p>
  </div>
</mat-dialog-content>
<div class="modal-footer">
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close class="dimension">Ok</button>
  </mat-dialog-actions>
</div>
