import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { BusinessUnit } from "./business-unit.model";
import { BusinessUnitDto } from "../../dtos/business-unit/business-unit.dto";

export const businessUnitProfile: MappingProfile = (mapper) => {
    createMap(mapper, BusinessUnitDto, BusinessUnit, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};