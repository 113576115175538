import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { FilterComponent } from "./filter.component";
import { AutoCompleteFilterModule } from "../auto-complete-filter/auto-complete-filter.module";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatIconModule } from "@angular/material/icon";
import { MatSelectModule } from "@angular/material/select";
import { SelectFilterModule } from "../select-filter/select-filter.module";
import { MatButtonModule } from "@angular/material/button";
import { TranslateModule } from "@ngx-translate/core";
import { MatRadioModule } from "@angular/material/radio";
import { MatTooltipModule } from "@angular/material/tooltip";

export const DATE_FORMATS = {
    parse: {
        dateInput: "DD/MM/YYYY"
    },
    display: {
        dateInput: "DD/MM/YYYY",
        monthYearLabel: "MM YYYY",
        dateA11yLabel: "DD/MM/YYYY",
        monthYearA11yLabel: "MM YYYY"
    }
};
@NgModule({
    declarations: [
        FilterComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MatInputModule,
        AutoCompleteFilterModule,
        SelectFilterModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatCheckboxModule,
        MatIconModule,
        MatSelectModule,
        MatButtonModule,
        TranslateModule,
        MatRadioModule,
        MatTooltipModule
    ],
    exports: [
        FilterComponent
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [
                MAT_DATE_LOCALE
            ]
        }, {
            provide: MAT_DATE_FORMATS,
            useValue: DATE_FORMATS
        }
    ]
})
export class FilterModule { }