import { Component, Input, OnInit, ViewChild,
    AfterViewInit, Output, EventEmitter,
    ViewEncapsulation } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatPaginator, PageEvent } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { Column } from "./column";
import { TranslateService } from "@ngx-translate/core";
import { LoaderService } from "../loader/services/loader.service";

@Component({
    selector: "app-table",
    templateUrl: "./table.component.html",
    styleUrls: [
        "./table.component.scss"
    ],
    encapsulation: ViewEncapsulation.None
})

export class TableComponent implements OnInit, AfterViewInit {

    public _dataSource = new MatTableDataSource<unknown>([]);
    private data: unknown[] = [];
    public displayedColumns!: string[];

    @ViewChild(MatSort) sort!: MatSort;

    @ViewChild("paginator") paginator!: MatPaginator;

    @Output() filterDataSource = new EventEmitter();

    @Output() rowAction: EventEmitter<unknown> = new EventEmitter<unknown>();

    @Output() columnSort: EventEmitter<unknown> = new EventEmitter<unknown>();

    @Output() page = new EventEmitter<{ page: number, size: number }>();

    @Input() totalElements = 0;

    @Input() showFirstLastButtons = true;

    @Input() resetPageEvent!: EventEmitter<void>;

    @Input() columns!: Column[];

    @Input() rowActionIcons!: string[];

    @Input() set dataSource(data: unknown[]) {
        this.setData(data);
    }

    @Input() set filters(filtersData: Map<string, {
        customValidation?: (filterValue: unknown, dataValue: unknown) => boolean, value: string
    }>) {
        this.applyFilters(filtersData);
    }

    constructor(private translate: TranslateService, private loaderService: LoaderService) {

    }

    ngOnInit(): void {
        const columnNames = this.columns.map((tableColumn: Column) => tableColumn.caption);
        if (this.rowActionIcons) {
            this.displayedColumns = [
                ...columnNames, ...this.rowActionIcons
            ];
        } else {
            this.displayedColumns = columnNames;
        }
        this.resetPageEvent.subscribe(() => {
            if(this.paginator.pageIndex === 0) {
                this.page.emit({ page: this.paginator.pageIndex, size: this.paginator.pageSize });
            } else {
                this.paginator.firstPage();
            }
        });
    }

    emitPageEvent(event: PageEvent) {
        this.page.emit({ page: event.pageIndex, size: event.pageSize });
    }

    ngAfterViewInit(): void {
        this._dataSource.sort = this.sort;
    }

    setData(data: unknown[]) {
        this.data = data;
        this.setDataSource(this.data);
    }

    setDataSource(data: unknown[]) {
        this._dataSource = new MatTableDataSource<unknown>(data);
        this.filterDataSource.emit(this._dataSource);
    }

    emitRowAction(action: unknown, row: unknown) {
        this.rowAction.emit({ action, data: row });
    }

    emitColumnSort(field: string) {
        this.paginator.firstPage();
        this.columnSort.emit({ data: field });
    }

    applyFilters(filterObject: Map<string, {
        customValidation?: (filterValue: unknown, dataValue: unknown) => boolean,
        value: string
    }>) {
        const filteredData = this.data?.filter(
            (data: unknown) => {
                for(const [
                    key, value
                ] of filterObject.entries()) {
                    // Parse properties, in case they are nested
                    const keysList: string[] = key.split(".");
                    let parsedValue = data;

                    keysList.forEach((k) => {
                        const prop = k as keyof typeof parsedValue;
                        if(parsedValue != null) {
                            if(Object.hasOwn(parsedValue, prop)) {
                                parsedValue = parsedValue[prop];
                            } else {
                                // console.error("Wrong field name provided to filters");
                                parsedValue = "";
                            }
                        }
                    });

                    if(value.value !== "") {
                        if(value.customValidation) {
                            if(!value.customValidation(value.value, parsedValue)) {
                                return false;
                            }
                        } else {
                            if (!(parsedValue as string || "").toLowerCase().includes(value.value)) {
                                return false;
                            }
                        }
                    }
                }

                return true;
            }
        );

        this.setDataSource(filteredData);
    }

    parseColumn(element: Record<string, unknown>, propertyString: string,
        format?: (value: unknown) => string, mutuallyExcludes?: string) {
        let value;
        if(mutuallyExcludes) {
            value = this.parseProperty(element, propertyString) || this.parseProperty(element, mutuallyExcludes);
        } else {
            value = this.parseProperty(element, propertyString);
        }

        if(format) {
            return format(value);
        }

        return value;
    }

    parseProperty(element: Record<string, unknown>, propertyString: string) {
        const propertiesList: string[] = propertyString.split(".");
        let parsedProperty: Record<string, unknown> = element;

        propertiesList.forEach((property) => {
            if(parsedProperty != null) {
                if(parsedProperty[property]) {
                    parsedProperty = parsedProperty[property] as Record<string, unknown>;
                }
            }
        });
        this.translate.get(parsedProperty.toString()).subscribe((data:Record<string, unknown>)=> {
            parsedProperty = data;
        });

        return parsedProperty;
    }
}
