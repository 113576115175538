import { AutoMap } from "@automapper/classes";

export class LinksResponseDto {

    @AutoMap()
    public current?: string;

    @AutoMap()
    public next?: string;

    @AutoMap()
    public last? : string;

}