import { AutoMap } from "@automapper/classes";
import { Country } from "../../models/country/country.model";
import { BusinessUnit } from "../../models/business-unit/business-unit.model";

export class ProjectDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public description!:string;

    @AutoMap()
    public code?:string;

    @AutoMap()
    public "is_closed"!:boolean;

    @AutoMap()
    public country!: Country;

    @AutoMap()
    public "business_unit"!: BusinessUnit;
}