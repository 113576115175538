import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";
import { User } from "../user/user.model";
import { Project } from "../project/project.model";

export class UserProject extends Entity {

    @AutoMap()
    public user!: User;

    @AutoMap()
    public project!: Project;

}
