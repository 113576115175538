import { AutoMap } from "@automapper/classes";

export class ActivityTypeDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public description!: string;

}