import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { ReferenceDto } from "src/app/common/dtos/reference/reference.dto";
import { ReferenceResponseDto } from "src/app/common/dtos/reference/reference.response";
import { ReferencesRepositoryInterface } from "./references.interface";
import { ReferenceCreateDto } from "src/app/common/dtos/reference/reference-create.dto";
import { Reference } from "src/app/common/models/reference/reference.model";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { ReferenceUpdateDto } from "src/app/common/dtos/reference/reference-update.dto";

@Injectable()
export class ReferencesRepository extends Repository implements ReferencesRepositoryInterface {

    public getReferences(searchString?: string, page?: number, size?: number, sortBy?:string, lang?:string):
    Observable<ReferenceResponseDto> {
        let httpParams: HttpParams = new HttpParams();
        if (page) {
            httpParams = httpParams.set("page", page);
        }
        if (size) {
            httpParams = httpParams.set("limit", size);
        }
        if (searchString) {
            httpParams = httpParams.set("search", searchString);
        }
        if (lang) {
            httpParams = httpParams.set("lang", lang);
        }

        httpParams = httpParams.set("sortBy", sortBy!);

        return this.http.get<ReferenceResponseDto>("/references", { params: httpParams }).
            pipe(map((referencesDto: ReferenceResponseDto) => {
                const dto = new ReferenceResponseDto();
                dto.data = referencesDto.data;
                dto.meta = referencesDto.meta;
                dto.links = referencesDto.links;
                return dto;
            }));
    }

    public createReference(reference: ReferenceCreateDto): Observable<Reference> {
        return this.http.post<ReferenceDto>("/references", reference).pipe(
            map((createdReference) => mapper.map(createdReference, ReferenceDto, Reference))
        );
    }

    public updateReference(id: string, reference: ReferenceUpdateDto): Observable<Reference> {
        return this.http.put<ReferenceDto>(`/references/${id}`, reference).pipe(
            map((updateReference) => mapper.map(updateReference, ReferenceDto, Reference))
        );
    }

    public deleteReference(id: string): Observable<void> {
        return this.http.delete<void>(`/references/${id}`);
    }
}