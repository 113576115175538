import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BusinessUnitResponseDto } from "src/app/common/dtos/business-unit/business-unit.response";

@Injectable({
    providedIn : "root"
})
export abstract class BusinessUnitsRepositoryInterface {

    public abstract getBusinessUnits(): Observable<BusinessUnitResponseDto>;

}