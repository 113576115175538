import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReferenceClassificationsDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.dto";
import { ReferenceClassificationsResponseDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.response";

@Injectable({
    providedIn : "root"
})
export abstract class ReferenceClassificationsRepositoryInterface {

    public abstract getReferenceClassifications(searchString?: string,
        page?: number,
        size?: number):
    Observable<ReferenceClassificationsResponseDto>;

    public abstract getReferenceClassification(id: string):
    Observable<ReferenceClassificationsDto>;

}