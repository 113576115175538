import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StatusResponseDto } from "src/app/common/dtos/status/status.response";
import { StatusesRepositoryInterface } from "../repositories/statuses/statuses.interface";

@Injectable({
    providedIn:"root"
})
export class StatusManager {

    constructor(
        private statusRepository: StatusesRepositoryInterface
    ) {}

    public getStatus(): Observable<StatusResponseDto> {
        return this.statusRepository.getStatus();
    }

}