<form class="filtersForm">
  <!--<p class="filtersLabel">Filtri</p>-->
  <div class="container">
    <ng-container *ngFor="let f of fields" [ngSwitch]="f.fieldType">
      <mat-form-field class="item" *ngSwitchCase="'text'">
        <mat-label>{{ f.caption | translate }}</mat-label>
        <input
          [readonly]="checkDisabled(f)"
          matInput
          [formControl]="f.formControl"
        />
      </mat-form-field>

      <mat-form-field class="item" *ngSwitchCase="'date'">
        <mat-label>{{ f.caption | translate }}</mat-label>
        <input
          [readonly]="checkDisabled(f)"
          matInput
          [matDatepicker]="datePicker"
          [formControl]="f.formControl"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>

      <mat-form-field *ngSwitchCase="'number'" class="item">
        <mat-label>{{ f.caption | translate }}</mat-label>
        <input
          [readonly]="checkDisabled(f)"
          type="number"
          matInput
          [formControl]="f.formControl"
        />
      </mat-form-field>
      <app-auto-complete-filter
        [disabled]="checkDisabled(f)"
        [field]="f"
        *ngSwitchCase="'autocomplete'"
        class="item"
        style="min-width: 12%"
      ></app-auto-complete-filter>

      <app-select-filter
        [disabled]="checkDisabled(f)"
        [field]="f"
        *ngSwitchCase="'select'"
        class="item"
      ></app-select-filter>

      <mat-checkbox
        [disabled]="checkDisabled(f)"
        [formControl]="f.formControl"
        *ngSwitchCase="'checkbox'"
        class="item"
      >
        {{ f.caption | translate }}
      </mat-checkbox>

      <!--RANGEDATE-->
      <mat-form-field
        appearance="fill"
        *ngSwitchCase="'rangedate'"
        class="item"
      >
        <mat-label>{{ f.caption | translate }}</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
          <input
            matStartDate
            formControlName="start"
            placeholder="Start date"
          />
          <input matEndDate formControlName="end" placeholder="End date" />
        </mat-date-range-input>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
      </mat-form-field>
      <ng-container *ngSwitchCase="'rangecheckbox'">
        <div class="radio-group-container-wrapper">
          <label class="radio-group-label">{{ f.caption | translate }}</label>
          <div class="radio-group-container">
            <mat-radio-group
              [formControl]="f.formControl"
              class="radio-group-horizontal"
            >
              <mat-radio-button
                *ngFor="let opt of f.options"
                [value]="opt.value"
                class="radio-button-horizontal"
              >
                <mat-icon matTooltip="{{ opt.value }}">{{ opt.key }}</mat-icon>
              </mat-radio-button>
            </mat-radio-group>
          </div>
        </div>
      </ng-container>
      <!--RANGE DATE END-->

      <!--
      <div
      *ngSwitchCase="'select'"      
      class="containerHorizontal"
    >
      <mat-form-field class="itemHorizontal">
        <mat-label>{{ f.caption }}</mat-label>
        <input
          matInput
          [readonly]="checkDisabled(f)"
          (focus)="openSelect(selectElement)"
          [formControl]="f.formControl"
        />
        <mat-select
          #selectElement
          [disabled]="checkDisabled(f)"
          [formControl]="f.formControl"
        >
        <mat-option
        *ngFor="let opt of f.options"
        [value]="opt.value"
        >
            {{ opt.key }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <span
        class="itemHorizontal"
        *ngIf="f.formControl.getRawValue()"
        (click)="clearSelection(f)"
      >
        <mat-icon>close</mat-icon>
      </span>
    </div>
      -->
    </ng-container>
    <div class="containerHorizontal" style="margin-left: 1%">
      <button
        mat-raised-button
        color="warn"
        style="min-width: auto; margin-right: 10px"
        (click)="onApplyClicked()"
        matTooltip="Search"
      >
        <mat-icon>search</mat-icon>{{ "button.search" | translate }}
      </button>
      <button
        mat-raised-button
        color="warn"
        style="min-width: auto; margin-right: 10px"
        (click)="onClearClicked()"
        matTooltip="Reset"
      >
        <mat-icon>close</mat-icon>{{ "button.reset" | translate }}
      </button>
      <button
        mat-icon-button
        color="basic"
        style="min-width: auto; margin-right: 10px"
        (click)="onRefreshClicked()"
        matTooltip="Refresh"
      >
        <mat-icon>refresh</mat-icon>
      </button>
    </div>
    <!--  
  <button (click)="onApplyClicked()" mat-raised-button class="filterButtons">
    Apply Filters
  </button>
  <button (click)="onClearClicked()" mat-raised-button class="filterButtons">
    Clear Filters
  </button>-->
  </div>
</form>
