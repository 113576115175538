import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { ProjectCreateDto } from "src/app/common/dtos/project/project-create.dto";
import { ProjectDto } from "src/app/common/dtos/project/project.dto";
import { Project } from "src/app/common/models/project/project.model";
import { ProjectUpdateDto } from "src/app/common/dtos/project/project-update.dto";
import { ProjectsRepositoryInterface } from "./projects.interface";

@Injectable()
export class ProjectsRepository extends Repository implements ProjectsRepositoryInterface {

    public getProjects(searchString?: string, page?: number, size?: number, sortBy?: string)
        : Observable<ProjectResponseDto> {
        let httpParams: HttpParams = new HttpParams();
        if(page) {
            httpParams = httpParams.set("page", page);
        }
        if(size) {
            httpParams = httpParams.set("limit", size);
        }
        if(searchString) {
            searchString = searchString.replace(/,$/, "");
            httpParams = httpParams.set("search", searchString);
        }

        httpParams = httpParams.set("sortBy", sortBy!);

        return this.http.get<ProjectResponseDto>("/projects", { params: httpParams })
            .pipe(map((projectsDto: ProjectResponseDto) => {
                const dto = new ProjectResponseDto();
                dto.data = projectsDto.data;
                dto.meta = projectsDto.meta;
                dto.links = projectsDto.links;
                return dto;
            }));
    }

    public getAllProjects() : Observable<ProjectResponseDto> {
        return this.http.get<ProjectDto[]>("/projects/all").pipe(map((projectsDto: ProjectDto[]) => {
            const dto = new ProjectResponseDto();
            dto.content = projectsDto;
            return dto;
        }));
    }

    public createProject(project: ProjectCreateDto): Observable<Project> {
        return this.http.post<ProjectDto>("/projects", project).pipe(
            map((createdProject) => mapper.map(createdProject, ProjectDto, Project))
        );
    }

    public updateProject(id: string, project: ProjectUpdateDto): Observable<Project> {
        return this.http.put<ProjectDto>(`/projects/${id}`, project).pipe(
            map((updateProject) => mapper.map(updateProject, ProjectDto, Project))
        );
    }

    public deleteProject(id: string): Observable<void> {
        return this.http.delete<void>(`/projects/${id}`);
    }
}