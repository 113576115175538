import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ReferenceClassifications } from "./reference-classifications.model";
import { ReferenceClassificationsDto } from "../../dtos/reference-classifications/reference-classifications.dto";

export const referenceClassificationsProfile: MappingProfile = (mapper) => {
    createMap(mapper, ReferenceClassificationsDto, ReferenceClassifications, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};