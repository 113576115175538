import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { lastValueFrom } from "rxjs";
import { UserDto } from "src/app/common/dtos/user/user.dto";
import { DialogData } from "src/app/shared/components/dialog-prototype/dialog-data";
import { DialogPrototypeComponent } from "src/app/shared/components/dialog-prototype/dialog-prototype.component";
import { RouterService } from "src/app/shared/services/router.service";
import { UserService } from "src/app/shared/services/user.service";
import { AuthService } from "src/app/shared/services/user/auth.service";
import { LoginComponent } from "src/app/ui/frontend/login/login.component";

@Component({
    selector: "app-toolbar",
    templateUrl: "./toolbar.component.html",
    styleUrls: [
        "./toolbar.component.scss"
    ]
})
export class ToolbarComponent implements OnInit {

    @Output() toggleSidenav = new EventEmitter<void>();
    username!: string | undefined;
    user!: UserDto | undefined;
    constructor(
        private authService: AuthService,
        private router: RouterService,
        private dialog: MatDialog,
        public translate: TranslateService,
        private snackBar: MatSnackBar,
        private userService: UserService
    ) {
        this.username = this.authService.getUsername()?.replace(/"|\u0027/g, "");
        translate.addLangs([
            "it", "en", "es", "fr", "pt", "ro"
        ]);
    }

    async ngOnInit(): Promise<void> {
        this.username = this.authService.getUsername()?.replace(/"|\u0027/g, "");
        const result = await lastValueFrom(this.userService.getUsers("username=" + this.username));
        this.user = result.data[0];
    }

    switchLang(lang: string) {
        this.translate.use(lang);
    }

    onUserLogout() {
        const dialogFields: DialogData = {
            title: "Logout",
            headerText: "logout.message",
            visibleButtonClose: false,
            fields: [],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "button.ok",
                    type: "submit",
                    icon: "check",
                    color: "primary"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            data: dialogFields
        }).afterClosed().subscribe((response)=> {
            if(response) {
                this.authService.logout();
                this.router.navigateTo(LoginComponent);
            }
        });
    }

    /*
    resetPassword() {
        const dialogFields: DialogData = {
            title: "Reset Password",
            headerText: "reset.message",
            visibleButtonClose: false,
            fields: [],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "button.ok",
                    type: "submit",
                    icon: "check",
                    color: "primary"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            data: dialogFields
        }).afterClosed().subscribe((response)=> {
            if(response) {
                this.snackBar.open("Inviata richiesta di Reset Password",
                    undefined, {
                        duration: 3000,
                        panelClass: "app-notification-success"
                    });
                // this.router.navigateTo(LoginComponent);
            }
        });
    }
*/
    changePassword() {
        const dialogFields: DialogData = {
            title: "change.title",
            uuid: this.user!.id,
            headerText: "",
            visibleButtonClose: false,
            fields: [
                {
                    name: "password",
                    type: "password",
                    label: "change.password",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required,
                        Validators.pattern(/^(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()\-=_+{};:',.<>?]).{8,20}$/),
                        Validators.minLength(8),
                        Validators.maxLength(20)
                    ])
                }
            ],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "button.ok",
                    type: "submit",
                    icon: "check",
                    color: "primary"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { data: dialogFields })
            .afterClosed().subscribe((response)=> {
                if(response) {
                    this.userService.updateUserPassword(this.user!.id, response.dataToSave).subscribe(() => {
                        this.snackBar.open(this.translate.instant("message.change.password.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });

                }
            });
    }

}
