import { Component } from "@angular/core";

@Component({
    selector: "app-frontend",
    templateUrl: "./frontend.component.html",
    styleUrls: [
        "./frontend.component.scss"
    ]
})
export class FrontendComponent {

}
