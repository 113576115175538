import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReferenceClassificationsResponseDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.response";
import { ReferenceClassificationsRepositoryInterface }
    from "../repositories/reference-classification/reference-classifications.interface";
import { ReferenceClassificationsDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.dto";

@Injectable({
    providedIn:"root"
})
export class ReferenceClassificationManager {

    constructor(
        private referenceClassificationRepository: ReferenceClassificationsRepositoryInterface
    ) {}

    public getReferenceClassifications(searchString?: string,
        page?: number,
        size?: number): Observable<ReferenceClassificationsResponseDto> {
        return this.referenceClassificationRepository
            .getReferenceClassifications(searchString, page, size);
    }

    public getReferenceClassification(id: string): Observable<ReferenceClassificationsDto> {
        return this.referenceClassificationRepository.getReferenceClassification(id);
    }

}