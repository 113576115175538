import { AutoMap } from "@automapper/classes";
import { Status } from "../../models/status/status.model";

export class ObservationStatusDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public "updated_at": string;

    @AutoMap()
    public "created_at": string;

    @AutoMap()
    public status!: Status;

    @AutoMap()
    public comment?: string;

}