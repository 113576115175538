import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";
import { Status } from "../status/status.model";

export class ObservationStatus extends Entity {

    @AutoMap()
    public comment!: string;

    @AutoMap()
    public status!: Status;

    @AutoMap()
    public "updated_at": string;

    @AutoMap()
    public "created_at": string;

}
