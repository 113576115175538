import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";

export class User extends Entity {

    @AutoMap()
    public firstName!: string;

    @AutoMap()
    public lastName!: string;

    @AutoMap()
    public readonly isActive!: boolean;

    @AutoMap()
    public username!: string;

    @AutoMap()
    public role!: string;

    @AutoMap()
    public company!: string;

    public get fullName(): string {
        return `${this.firstName} ${this.lastName}`;
    }

    public get initials(): string {
        const nameInitial = this.firstName[0];
        const surnameInitial = this.lastName[0];
        return nameInitial.concat(surnameInitial);
    }
}

