import { Injectable } from "@angular/core";
import { UserManager } from "../../managers/user.manager";
import { BehaviorSubject } from "rxjs";
import { AuthManager } from "../../managers/auth.manager";
import { ActiveUser } from "./active-user.service";

@Injectable()
export class AuthService {
    public user: BehaviorSubject<ActiveUser | undefined> = new BehaviorSubject<ActiveUser | undefined>(undefined);

    constructor(
        private authManager: AuthManager,
        private userManager: UserManager
    ) {

    }

    public get logged(): boolean {
        return this.authManager.logged;
    }

    public logout() {
        this.authManager.logout();
        this.user.next(undefined);
    }

    public getUsername(): string | undefined {
        return this.authManager.getUsername();
    }
}