import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CountryResponseDto } from "src/app/common/dtos/country/country.response";

@Injectable({
    providedIn : "root"
})
export abstract class CountriesRepositoryInterface {

    public abstract getCountries(): Observable<CountryResponseDto>;

}