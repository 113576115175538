import { TranslateService } from "@ngx-translate/core";
import { Component, EventEmitter, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { Project } from "src/app/common/models/project/project.model";
import { Column } from "src/app/shared/components/table/column";
import { Field } from "src/app/shared/components/table/filter/field";
import { Option } from "./../../../../shared/components/table/filter/option";
import { ProjectService } from "src/app/shared/services/project.service";
import { MatDialog } from "@angular/material/dialog";
import { ProjectDto } from "src/app/common/dtos/project/project.dto";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { DialogData } from "src/app/shared/components/dialog-prototype/dialog-data";
import { DialogPrototypeComponent } from "src/app/shared/components/dialog-prototype/dialog-prototype.component";
import { CountryService } from "src/app/shared/services/country.service";
import { CountryDto } from "src/app/common/dtos/country/country.dto";
import { MatSnackBar } from "@angular/material/snack-bar";
import { FormControl, Validators } from "@angular/forms";
import { lastValueFrom } from "rxjs";
import { BusinessUnitService } from "src/app/shared/services/business-unit.service";
import { BusinessUnitDto } from "src/app/common/dtos/business-unit/business-unit.dto";
import { ExportService } from "src/app/shared/services/export.service";

@Component({
    selector: "app-projects",
    templateUrl: "./projects.component.html",
    styleUrls: [
        "./projects.component.scss"
    ]
})
export class ProjectsComponent implements OnInit {

    tableData!: Project[];
    tableColumns!: Column[];

    filters!: Field[]; // = this.getFilters();

    filterSource!: MatTableDataSource<unknown>;

    filtersData!: Map<string,
    { customValidation?: (filterValue: unknown, dataValue: unknown) => boolean, value: string }>;

    currentFilterSearchString = "";

    currentPageSize = 5;

    requestTotalElements = 0;

    sortTable = "id:DESC";

    resetPageEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    countriesOption!: Option[];

    businessUnitOption!: Option[];

    constructor(
        private projectService: ProjectService,
        private countryService: CountryService,
        private businessUnitService: BusinessUnitService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private exportService: ExportService
    ) { }

    async ngOnInit(): Promise<void> {
        this.tableColumns = this.getColumns();
        this.filters = await this.getFilters();
        this.getProjects(undefined, 0, this.currentPageSize, this.sortTable);
        this.getCountries();
        this.getBusinessUnit();
    }

    requestPage(page: { page: number, size: number }) {
        this.currentPageSize = page.size;
        this.getProjects(this.currentFilterSearchString, page.page + 1, page.size, this.sortTable);
        this.getCountries();
        this.getBusinessUnit();
    }

    getColumns(): Column[] {
        return [
            { caption: "projects.form.code", field: "code", sort: true },
            { caption: "projects.form.description", field: "description", sort: true },
            { caption: "projects.form.country", field: "country.description" },
            { caption: "projects.form.businessunit", field: "businessUnit.description" },
            {
                caption: "projects.form.isclosed", field: "isClosed", sort: true, format(value) {
                    if (String(value) === "true") {
                        return "CLOSE";
                    } else {
                        return "OPEN";
                    }
                }
            }
        ];
    }

    getProjects(searchString?: string, page?: number, size?: number, sortBy?: string) {
        this.projectService.getProjects(searchString, page, size, sortBy).subscribe((result) => {
            this.requestTotalElements = result.meta?.totalItems || 0;
            this.tableData = mapper.mapArray(result?.data, ProjectDto, Project);
        });
    }

    getBusinessUnit(): Option[] {

        this.businessUnitService.getBusinessUnits().subscribe((result) => {
            const optionList: Option[] =
                result.content.map((businessUnitItem: BusinessUnitDto) => {
                    return {
                        key: businessUnitItem?.code?.toString() + " - " + businessUnitItem?.description?.toString(),
                        value: businessUnitItem?.id
                    };
                });
            this.businessUnitOption = optionList;
            return optionList;
        });
        return this.businessUnitOption;

    }

    async getBusinessUnitAsync(): Promise<Option[]> {
        const result = await lastValueFrom(this.businessUnitService.getBusinessUnits());
        return result?.content.map((businessUnitItem: BusinessUnitDto) => {
            return {
                key: businessUnitItem?.code?.toString() + " - " + businessUnitItem?.description?.toString(),
                value: businessUnitItem?.id
            };
        });
    }

    getCountries(): Option[] {
        this.countryService.getCountries().subscribe((result) => {
            const optionList: Option[] =
                result.content.map((countryItem: CountryDto) => {
                    return {
                        key: countryItem?.code?.toString() + " - " + countryItem?.description?.toString(),
                        value: countryItem?.id
                    };
                });
            this.countriesOption = optionList;
            return optionList;
        });
        return this.countriesOption;
    }

    async getCountriesAsync(): Promise<Option[]> {
        const result = await lastValueFrom(this.countryService.getCountries());
        return result?.content?.map((countryItem: CountryDto) => {
            return {
                key: countryItem?.code?.toString() + " - " + countryItem?.description?.toString(),
                value: countryItem?.id
            };
        });
    }

    onSortTable(eventData: unknown) {
        const dataEmitted = eventData as { data: string };
        const splitted = this.sortTable.split(":");
        if (splitted[1] === "DESC") {
            this.sortTable = dataEmitted.data + ":ASC";
        } else {
            this.sortTable = dataEmitted.data + ":DESC";
        }

        this.getProjects(this.currentFilterSearchString, 1, this.currentPageSize, this.sortTable);
    }

    async getFilters(): Promise<Field[]> {
        return [
            {
                id: "code",
                caption: "projects.form.code",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "code",
                selectedFilter: "",
                options: []
            },
            {
                id: "description",
                caption: "projects.form.description",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "description",
                selectedFilter: "",
                options: []
            },
            {
                id: "country",
                caption: "projects.form.country",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "country",
                selectedFilter: "",
                options: await this.getCountriesAsync()
            },
            {
                id: "businessUnit",
                caption: "projects.form.businessunit",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "businessUnit",
                selectedFilter: "",
                options: await this.getBusinessUnitAsync()
            },
            {
                id: "isClosed",
                caption: "projects.form.isclosed",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "isClosed",
                selectedFilter: "",
                options: [
                    { key:"CLOSE", value:"true" }, { key:"OPEN", value:"false" }
                ]
            }
        ];
    }

    onActionFromTableEmitted(eventData: unknown) {
        const dataEmitted = eventData as { action: string, data: Project };

        if (dataEmitted.action === "delete") {
            this.openDeleteDialog(dataEmitted.data);
        } else if (dataEmitted.action === "edit") {
            this.openEditDialog(dataEmitted.data);
        }
    }

    onFilterDataSourceReceived(eventData: unknown) {
        const data = eventData as MatTableDataSource<unknown>;
        this.filterSource = data;
    }

    openCreateDialog(): void {
        const dialogFields: DialogData = {
            title: "projects.button.create",
            visibleButtonClose: true,
            fields: [
                {
                    name: "description",
                    type: "text",
                    label: "projects.detail.description",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "code",
                    type: "text",
                    label: "projects.detail.code",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "country_id",
                    type: "select",
                    label: "projects.detail.country",
                    options: this.countriesOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "business_unit_id",
                    type: "select",
                    label: "projects.detail.businessunit",
                    options: this.businessUnitOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "is_closed",
                    type: "checkbox",
                    label: "projects.detail.isclosed",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.create",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.projectService.createProject(response.dataToSave).subscribe(() => {
                        this.resetPageEventEmitter.emit();

                        this.snackBar.open(this.translate.instant("projects.create.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });
                }
            });
    }

    openEditDialog(data: Project) {
        const dialogFields: DialogData = {
            title: "projects.detail.title",
            headerText: "Number: " + data.id,
            visibleButtonClose: true,
            uuid: data.id,
            fields: [
                {
                    name: "description",
                    type: "text",
                    label: "projects.detail.description",
                    options: [],
                    formControl: new FormControl({
                        value: data.description,
                        disabled: false
                    })
                },
                {
                    name: "code",
                    type: "text",
                    label: "projects.detail.code",
                    options: [],
                    formControl: new FormControl({
                        value: data.code,
                        disabled: false
                    })
                },
                {
                    name: "country_id",
                    type: "select",
                    label: "projects.detail.country",
                    options: this.countriesOption,
                    formControl: new FormControl({
                        value: data.country.id,
                        disabled: false
                    })
                },
                {
                    name: "business_unit_id",
                    type: "select",
                    label: "projects.detail.businessunit",
                    options: this.businessUnitOption,
                    formControl: new FormControl({
                        value: data.businessUnit.id,
                        disabled: false
                    })
                },
                {
                    name: "is_closed",
                    type: "checkbox",
                    label: "projects.detail.isclosed",
                    options: [],
                    formControl: new FormControl({
                        value: data.isClosed,
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.save",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.projectService.updateProject(response.uuid, response.dataToSave).subscribe(() => {
                    this.resetPageEventEmitter.emit();
                    this.snackBar.open(this.translate.instant("projects.detail.success"), undefined, {
                        duration: 3000,
                        panelClass: "app-notification-success"
                    });
                });
            }
        });
    }

    openDeleteDialog(data: Project) {
        const translatedLabel = this.translate.instant("projects.delete.headertext")
            .replace("projectname", data.code + "-" + data.description);

        const dialogFields: DialogData = {
            title: "projects.delete.title",
            headerText: translatedLabel,
            visibleButtonClose: false,
            fields: [],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "button.delete",
                    type: "submit",
                    icon: "delete",
                    color: "accent"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.projectService.deleteProject(data.id).subscribe(() => {
                        this.resetPageEventEmitter.emit();
                        this.snackBar.open(this.translate.instant("projects.delete.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });

                }
            });
    }

    onFilterUpdate(searchString: string): void {
        this.currentFilterSearchString = searchString;
        this.resetPageEventEmitter.emit();
    }
}
