import { AutoMap } from "@automapper/classes";
import { Project } from "../../models/project/project.model";
import { User } from "../../models/user/user.model";

export class UserProjectDto {

    @AutoMap()
    public id! : number;

    @AutoMap()
    public project!: Project;

    @AutoMap()
    public user!: User;

}