import { AutoMap } from "@automapper/classes";
import { UserDto } from "./user.dto";
import { MetaResponseDto } from "../page/meta.response.";
import { LinksResponseDto } from "../page/links.response";

export class UserResponseDto {

    @AutoMap()
    public data!: UserDto[];

    @AutoMap()
    public meta?:MetaResponseDto;

    @AutoMap()
    public links?:LinksResponseDto;

}