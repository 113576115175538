import { NgModule } from "@angular/core";
import { VersionComponent } from "./version.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import packageJson from "../../../../../package.json";
import { TranslateModule } from "@ngx-translate/core";
import { APP_VERSION } from "./version.token";

@NgModule({
    declarations: [
        VersionComponent
    ],
    providers: [
        { provide: APP_VERSION, useValue: packageJson.version }
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forChild()
    ],
    exports: [
        VersionComponent
    ]
})
export class VersionModule {}