import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReferenceCreateDto } from "src/app/common/dtos/reference/reference-create.dto";
import { ReferenceUpdateDto } from "src/app/common/dtos/reference/reference-update.dto";
import { ReferenceResponseDto } from "src/app/common/dtos/reference/reference.response";
import { Reference } from "src/app/common/models/reference/reference.model";

@Injectable({
    providedIn : "root"
})
export abstract class ReferencesRepositoryInterface {

    public abstract getReferences(searchString?: string, page?: number, size?: number, sortBy?:string, lang?:string):
    Observable<ReferenceResponseDto>;

    public abstract createReference(reference: ReferenceCreateDto): Observable<Reference>;

    public abstract updateReference(id: string, reference: ReferenceUpdateDto): Observable<Reference>;

    public abstract deleteReference(id: string): Observable<void>;
}