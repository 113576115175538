import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";
import { User } from "../user/user.model";
import { ActivityType } from "../activity-type/activity-type.model";
import { Reference } from "../reference/reference.model";
import { Project } from "../project/project.model";
import { ObservationStatus } from "../observation-status/observation-status.model";
import { Status } from "../status/status.model";

export class Observation extends Entity {

    @AutoMap()
    public firstName!: string;

    @AutoMap()
    public lastName!: string;

    @AutoMap()
    public role!: string;

    @AutoMap()
    public insertDate!: Date;

    @AutoMap()
    public cardNumber!: string;

    @AutoMap()
    public location!: string;

    @AutoMap()
    public action!: string;

    public activity?: string;

    @AutoMap()
    public activityType!: ActivityType;

    @AutoMap()
    public user?: User;

    @AutoMap()
    // eslint-disable-next-line
    public user_id?:string;

    @AutoMap()
    public status?: Status;

    @AutoMap()
    public classification!:number;

    @AutoMap()
    public company! : string;

    @AutoMap()
    public observation! : string;

    @AutoMap()
    public observationReference?: Reference[];

    @AutoMap()
    public project!:Project;

    @AutoMap()
    public observationStatus?: ObservationStatus[];

    @AutoMap(()=>Number)
    public ref?: number[];

    @AutoMap()
    public note?: boolean;

    @AutoMap()
    public countnote?: string;

    @AutoMap()
    public descrizionenote?: string;

    @AutoMap()
    public countmedia?: string;

    @AutoMap()
    public origin?: string;

}
