import { HttpBackend, HttpErrorResponse, HttpEvent, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, catchError, finalize, throwError } from "rxjs";
import { environment } from "src/environments/environment";
import { HttpBackendWithBaseUrl } from "../base/base-url.backend";
import { LocalStorageRepositoryInterface } from "../local-storage/local-storage.interface";
import { LoadingOverlayService } from "../../components/loading-overlay/services/loading-overlay.service";
import { RouterService } from "../../services/router.service";
import { TokenService } from "../../services/user/token.service";
import { LoginComponent } from "src/app/ui/frontend/login/login.component";

@Injectable()
export class UsersHttpBackend extends HttpBackendWithBaseUrl {

    constructor(
        protected override http: HttpBackend,
        protected localStorage: LocalStorageRepositoryInterface,
        private router: RouterService,
        private loadingOverlayService: LoadingOverlayService,
        private tokenService: TokenService
    ) {
        super(http);
    }

    public get baseUrl(): string {
        return environment.apiUrl;
    }

    override handle(request: HttpRequest<unknown>): Observable<HttpEvent<unknown>> {
        // this.loadingOverlayService.setLoading(true);

        const token = this.localStorage.loadToken();

        if (token?.accessToken != null) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${token.accessToken}`
                }
            });
        }

        return super.handle(request).pipe(
            catchError((error) => {

                if (error instanceof HttpErrorResponse && error.status === 401) {
                    this.localStorage.deleteToken();
                    this.router.navigateTo(LoginComponent);
                    /*
                     this.tokenService.refreshToken().subscribe(
                         (refreshedToken) => {
                             if(token) {
                                 token.token = refreshedToken.token;
                                 this.localStorage.saveToken(token);
                                 // TODO: remake the call instead of reloading the page
                                 window.location.reload();
                             }
                         }
                     );
                     */
                }
                return throwError(() => error);
            }
            ),
            finalize(() => {
                // this.loadingOverlayService.setLoading(false);
            })
        );
    }

}