import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { UserProjectDto } from "../../dtos/user-project/user-project.dto";
import { UserProject } from "./user-project.model";

export const userProjectProfile: MappingProfile = (mapper) => {
    createMap(mapper, UserProjectDto, UserProject, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};