import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, lastValueFrom, map, timeout } from "rxjs";
import { HttpParams } from "@angular/common/http";
import { ObservationsRepositoryInterface } from "./observations.interface";
import { ObservationResponseDto } from "src/app/common/dtos/observation/observation.response";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";
import { ObservationUpdateDto } from "src/app/common/dtos/observation/observation-update.dto";
import { Observation } from "src/app/common/models/observation/observation.model";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { ObservationCreateDto } from "src/app/common/dtos/observation/observation-create.dto";
import { ObservationStatusResponseDto } from "src/app/common/dtos/observation-status/observation-status.response";
import { ObservationStatus } from "src/app/common/models/observation-status/observation-status.model";
import { ObservationStatusDto } from "src/app/common/dtos/observation-status/observation-status.dto";
import { ObservationMediaResponseDto } from "src/app/common/dtos/observation-media/observation-media.response";
import { ObservationMedia } from "src/app/common/models/observation-media/observation-media.model";
import { ObservationMediaDto } from "src/app/common/dtos/observation-media/observation-media.dto";
import { DatePipe } from "@angular/common";
import { ObservationResponseAllDto } from "src/app/common/dtos/observation/observation.response.all";
import { UserProjectResponseDto } from "src/app/common/dtos/user-project/user-project.response";
import { UserProjectDto } from "src/app/common/dtos/user-project/user-project.dto";
import { UserDto } from "src/app/common/dtos/user/user.dto";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { ProjectDto } from "src/app/common/dtos/project/project.dto";
import { UserProject } from "src/app/common/models/user-project/user-project.model";

@Injectable()
export class ObservationsRepository extends Repository implements ObservationsRepositoryInterface {

    public getObservations(searchString?: string, page?: number, size?: number, sortBy?: string,
        lang?: string, projects?: string)
        : Observable<ObservationResponseDto> {
        let httpParams: HttpParams = new HttpParams();
        if(page) {
            httpParams = httpParams.set("page", page);
        }
        if(size) {
            httpParams = httpParams.set("limit", size);
        }

        if(projects) {
            httpParams = httpParams.set("project", projects);
        }

        if(searchString) {
            if(searchString.indexOf("observationStatus.status=") > -1) {
                const pattern = /observationStatus\.status=[0-9],/;
                const patternIdStatus = /observationStatus\.status=([0-9]),/;
                const match = searchString.match(patternIdStatus);
                if (match) {
                    const statusId = match[1];
                    httpParams = httpParams.set("status", statusId);
                }
                searchString = searchString.replace(pattern, "");
            }

            if(searchString) {
                searchString = searchString.replace(/,$/, "");
                httpParams = httpParams.set("search", searchString);
            }
        }
        if(lang) {
            httpParams = httpParams.set("lang", lang);
        }

        httpParams = httpParams.set("sortBy", sortBy!);

        return this.http.get<ObservationResponseDto>("/observations", { params: httpParams })
            .pipe(map((observationsDto: ObservationResponseDto) => {
                const dto = new ObservationResponseDto();

                dto.data = observationsDto.data;
                dto.meta = observationsDto.meta;
                dto.links = observationsDto.links;

                for(const element of dto.data) {

                    if(element.observation_status !== undefined && element.observation_status.length > 0) {
                        const latestObject = element.observation_status?.reduce((latest, current) => {
                            const latestDate = new Date(latest.updated_at?.replace(/ /g, "T"));
                            const currentDate = new Date(current.updated_at?.replace(/ /g, "T"));
                            if (currentDate > latestDate) {
                                return current;
                            } else {
                                return latest;
                            }
                        });
                        element.status = latestObject?.status;
                    }

                    element.ref = element.observation_reference!.map((obj) => parseInt(obj.id, 10));
                    element.note = element.observation_status
                        .some((item) => item.comment !== null && item.comment !== undefined && item.comment !== "");
                    element.countnote = element.observation_status
                        .filter((item) => item.comment !== null && item.comment !== "").length.toString();
                    const datepipe: DatePipe = new DatePipe("en-US");
                    const commentsArray = element.observation_status
                        .filter((status) => status.comment !== null && status.comment !== "")
                        .map((status) =>
                            `${datepipe.transform(status.created_at, "dd/MM/YYYY HH:mm")} - ${status.comment}`);
                    element.countmedia = element.media?.length.toString();
                    element.descrizionenote = commentsArray.join("\n\n");
                }
                return dto;
            }));
    }

    public getAllObservations(searchString?: string, sortBy?: string, lang?: string, projects?: string)
        : Observable<ObservationResponseAllDto> {
        let httpParams: HttpParams = new HttpParams();

        if(lang) {
            httpParams = httpParams.set("lang", lang);
        }

        if(projects) {
            httpParams = httpParams.set("project", projects);
        }

        if(searchString) {
            searchString = searchString.replace(/,$/, "");

            if(searchString.indexOf("observationStatus.status=") > -1) {
                const regex = /observationStatus\.status=(\d+)/;
                const match = searchString.match(regex);
                if (match) {
                    const statusId = match[1];
                    httpParams = httpParams.set("status", statusId);
                }
                searchString = searchString.replace(regex, "");
            }

            if(searchString.indexOf("project=") > -1) {
                const regex = /project=(\d+)/;
                const match = searchString.match(regex);
                if (match) {
                    const projectId = match[1];
                    httpParams = httpParams.set("project", projectId);
                }
                searchString = searchString.replace(regex, "");
            }
            searchString = searchString.replaceAll(",&", "&");
            searchString = searchString.replaceAll(",,,", ",");
            searchString = searchString.replaceAll(",,", ",");
            searchString = searchString.replaceAll(",", ";");
            if(searchString.endsWith(";")) {
                searchString = searchString.substring(0, searchString.length - 1);
            }
            httpParams = httpParams.set("search", searchString);
        }

        return this.http.get<ObservationDto[]>("/observations/export", { params: httpParams })
            .pipe(map((observationsDto: ObservationDto[]) => {
                const dto = new ObservationResponseAllDto();
                dto.content = observationsDto;

                for(const element of dto.content) {
                    const observationReferenceString = element.observation_reference?.join(" - ");
                    element.refString = observationReferenceString;
                }
                return dto;
            }));
    }

    public getObservation(id: string, lang?: string)
        : Observable<Observation> {

        let httpParams: HttpParams = new HttpParams();

        if(lang) {
            httpParams = httpParams.set("lang", lang);
        }

        return this.http.get<ObservationDto>("/observations/" + id, { params: httpParams })
            .pipe(map((observationsDto: ObservationDto) => {
                let dto = new ObservationDto();
                dto = observationsDto;
                if(dto.observation_status !== undefined && dto.observation_status.length > 0) {
                    const latestObject = dto.observation_status?.reduce((latest, current) => {
                        const latestDate = new Date(latest.updated_at?.replace(/ /g, "T"));
                        const currentDate = new Date(current.updated_at?.replace(/ /g, "T"));
                        if (currentDate > latestDate) {
                            return current;
                        } else {
                            return latest;
                        }
                    });
                    dto.status = latestObject?.status;
                }

                dto.ref = dto.observation_reference!.map((obj) => parseInt(obj.id, 10));
                dto.note = dto.observation_status
                    .some((item) => item.comment !== null && item.comment !== undefined && item.comment !== "");
                dto.countnote = dto.observation_status
                    .filter((item) => item.comment !== null && item.comment !== "").length.toString();
                const datepipe: DatePipe = new DatePipe("en-US");
                const commentsArray = dto.observation_status
                    .filter((status) => status.comment !== null && status.comment !== "")
                    .map((status) =>
                        `${datepipe.transform(status.created_at, "dd/MM/YYYY HH:mm")} - ${status.comment}`);
                dto.countmedia = dto.media?.length.toString();
                dto.descrizionenote = commentsArray.join("\n\n");

                return mapper.map(dto, ObservationDto, Observation);
            }));
    }

    public createObservation(observation: ObservationCreateDto): Observable<Observation> {

        // gestire questo pezzo di codice//
        observation.classification = 1;
        observation.origin = "WEB_APP";
        // eslint-disable-next-line
        //observation.card_number = "-1"
        // //////////////////////////////

        return this.http.post<ObservationDto>("/observations", observation).pipe(
            map((createdObservation) => mapper.map(createdObservation, ObservationDto, Observation))
        );
    }

    public updateObservation(id: string, observation: ObservationUpdateDto): Observable<Observation> {
        return this.http.put<ObservationDto>(`/observations/${id}`, observation).pipe(
            map((updateObservation) => mapper.map(updateObservation, ObservationDto, Observation))
        );
    }

    public deleteObservation(id: string): Observable<void> {
        return this.http.delete<void>(`/observations/${id}`);
    }

    public getObservationStatus(id: string): Observable<ObservationStatusResponseDto> {

        return this.http.get<ObservationStatusResponseDto>(`/observations/${id}/status`);
    }

    public createObservationStatus(observationId: string,
        statusId: string,
        comment?:string):
        Observable<ObservationStatus> {
        return this.http.post<ObservationStatusDto>(`/observations/${observationId}/status/${statusId}`,
            { comment }).pipe(
            map((createdObservation) =>
                mapper.map(createdObservation, ObservationStatusDto, ObservationStatus))
        );
    }

    public getObservationMedia(id: string): Observable<ObservationMediaResponseDto> {
        return this.http.get<ObservationMediaResponseDto>(`/observations/${id}/media`);
    }

    public createObservationMedia(observationId: string, formData: FormData):
    Observable<ObservationMedia> {
        return this.http.post<ObservationMediaDto>(`/observations/${observationId}/media/`, formData).pipe(
            map((createdObservation) =>
                mapper.map(createdObservation, ObservationMediaDto, ObservationMedia))
        );
    }

    public deleteObservationMedia(id: string): Observable<void> {
        return this.http.delete<void>(`/media/${id}`);
    }

    public getProjectsByUser(id: string) : Observable<UserProjectResponseDto> {
        return this.http.get<UserProjectDto[]>(`/user-project/${id}`).pipe(map((projectsDto: UserProjectDto[]) => {
            const dto = new UserProjectResponseDto();
            // eslint-disable-next-line
            dto.user_project = projectsDto;
            return dto;
        }));
    }

    public createUserProject(userId: string, projectId: string):
    Observable<UserProject> {
        return this.http.post<UserProjectDto>(`/user-project/${userId}/project/${projectId}`, {}).pipe(
            map((createdUserProject) =>
                mapper.map(createdUserProject, UserProjectDto, UserProject))
        );
    }

    public deleteUserProject(id: string): Observable<void> {
        return this.http.delete<void>(`/user-project/${id}`);
    }
}

