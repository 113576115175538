import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { User } from "./user.model";
import { UserDto } from "src/app/common/dtos/user/user.dto";

export const userProfile: MappingProfile = (mapper) => {
    createMap(mapper, UserDto, User, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};
