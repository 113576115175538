import { Injectable } from "@angular/core";
import { UsersRepositoryInterface } from "../repositories/users/users.interface";
import { Observable } from "rxjs";
import { UserResponseDto } from "src/app/common/dtos/user/user.response";
import { UserUpdateDto } from "src/app/common/dtos/user/user-update.dto";
import { User } from "src/app/common/models/user/user.model";
import { UserCreateDto } from "src/app/common/dtos/user/user-create.dto";
import { UserUpdatePasswordDto } from "src/app/common/dtos/user/user-update-password.dto";

@Injectable({
    providedIn:"root"
})
export class UserManager {

    constructor(
        private userRepository: UsersRepositoryInterface
    ) {}

    public getUsers(searchString?: string, page?: number, size?: number, sortBy?: string): Observable<UserResponseDto> {
        return this.userRepository.getUsers(searchString, page, size, sortBy);
    }

    public getUser(id: string): Observable<User> {
        return this.userRepository.getUser(id);
    }

    public createUser(user: UserCreateDto):Observable<User> {
        return this.userRepository.createUser(user);
    }

    public updateUser(id: string, user: UserUpdateDto):Observable<User> {
        return this.userRepository.updateUser(id, user);
    }

    public deleteUser(id: string): Observable<void> {
        return this.userRepository.deleteUser(id);
    }

    public updateUserPassword(id: string, user: UserUpdatePasswordDto):Observable<User> {
        return this.userRepository.updateUserPassword(id, user);
    }

    public resetPassword(username: string): Observable<User> {
        return this.userRepository.resetPassword(username);
    }

}