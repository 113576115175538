import { Injectable } from "@angular/core";
import { Observable, tap } from "rxjs";
import { LoginDto } from "src/app/common/dtos/login/body/login.dto";
import { LocalStorageRepositoryInterface } from "src/app/shared/repositories/local-storage/local-storage.interface";
import { Token } from "src/app/common/models/token/token.model";

@Injectable({
    providedIn:"root"
})
export class AuthManager {

    constructor(
        private localRepository : LocalStorageRepositoryInterface
    ) {}

    public login(body: LoginDto): Observable<Token> {
        return this.localRepository.login(body).pipe(
            tap(
                (res)=> {
                    this.localRepository.saveToken(res);
                    this.localRepository.saveUsername(body.username);
                }
            )
        );
    }

    public logout(): void {
        this.localRepository.deleteToken();
    }

    public get logged():boolean {
        return this.localRepository.loadToken()?.accessToken != null ?? false;
    }

    public getToken(): string | undefined {
        return this.localRepository.loadToken()?.accessToken;
    }

    public getUsername(): string | undefined {
        return this.localRepository.loadUsername()?.toString();
    }

}
