import { AutoMap } from "@automapper/classes";
import { ObservationDto } from "./observation.dto";
import { MetaResponseDto } from "../page/meta.response.";
import { LinksResponseDto } from "../page/links.response";

export class ObservationResponseDto {

    @AutoMap()
    public data!: ObservationDto[];

    @AutoMap()
    public meta?:MetaResponseDto;

    @AutoMap()
    public links?:LinksResponseDto;

}