import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReferenceResponseDto } from "src/app/common/dtos/reference/reference.response";
import { ReferencesRepositoryInterface } from "../repositories/references/references.interface";
import { ReferenceCreateDto } from "src/app/common/dtos/reference/reference-create.dto";
import { Reference } from "src/app/common/models/reference/reference.model";
import { ReferenceUpdateDto } from "src/app/common/dtos/reference/reference-update.dto";

@Injectable({
    providedIn:"root"
})
export class ReferenceManager {

    constructor(
        private referenceRepository: ReferencesRepositoryInterface
    ) {}

    public getReferences(searchString?: string, page?: number, size?: number, sortBy?: string, lang?:string):
    Observable<ReferenceResponseDto> {
        return this.referenceRepository.getReferences(searchString, page, size, sortBy, lang);
    }

    public createReference(reference: ReferenceCreateDto):Observable<Reference> {
        return this.referenceRepository.createReference(reference);
    }

    public updateReference(id: string, reference: ReferenceUpdateDto):Observable<Reference> {
        return this.referenceRepository.updateReference(id, reference);
    }

    public deleteReference(id: string): Observable<void> {
        return this.referenceRepository.deleteReference(id);
    }

}