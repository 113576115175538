import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "src/app/shared/shared.module";
import { FrontendComponent } from "./frontend.component";
import { LoginModule } from "./login/login.module";
import { RouterModule } from "@angular/router";
import { BackofficeModule } from "../backoffice/backoffice.module";

@NgModule({
    declarations: [
        FrontendComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        LoginModule,
        RouterModule,
        BackofficeModule
    ]
})
export class FrontendModule {}

