import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from "src/app/common/models/user/user.model";
import { UserManager } from "../../managers/user.manager";

@Injectable()
export class ActiveUser {
    public subject: BehaviorSubject<User>;

    constructor(private userManager: UserManager, user: User) {
        this.subject = new BehaviorSubject(user);
    }

    private get user(): User {
        return this.subject.value;
    }

    public get id(): string {
        return this.user.id;
    }

    public get firstName(): string {
        return this.user.firstName;
    }

    // public get surname(): string {
    //     return this.user.surname;
    // }

    // public get active(): boolean {
    //     return this.user.active;
    // }

    // public get email(): string {
    //     return this.user.email;
    // }

    // public get nickname(): string | undefined {
    //     return this.user.nickname;
    // }

    public get fullName(): string {
        return this.user.fullName;
    }

    public get initials(): string {
        return this.user.initials;
    }

    public equals(obj: unknown): boolean {
        return this.subject.value.equals(obj);
    }
}