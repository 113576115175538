import { AutoMap } from "@automapper/classes";

export class BusinessUnitDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public code!: string;

    @AutoMap()
    public description!: string;

}