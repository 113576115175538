<div class="modal-header">
  <h4 class="modal-title">
    {{ "error.title" | translate }}
  </h4>
</div>
<div class="modal-body">
  <p class="text-break">{{ error?.message }}</p>
</div>
<div class="modal-footer">
  <button class="dimension" (click)="onCloseClick()">
    {{ "error.close" | translate }}
  </button>
</div>
