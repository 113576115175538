import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatCardModule } from "@angular/material/card";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateModule } from "@ngx-translate/core";
import { MatDialogModule } from "@angular/material/dialog";
import { DialogMessageComponent } from "./dialog-message.component";
import { MatButtonModule } from "@angular/material/button";

@NgModule({
    declarations:[
        DialogMessageComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatCardModule,
        FormsModule,
        ReactiveFormsModule,
        MatDialogModule,
        MatButtonModule,
        TranslateModule.forChild()
    ],
    exports: [
        DialogMessageComponent
    ]
})
export class DialogMessageModule {}
