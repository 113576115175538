import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActivityTypeResponseDto } from "src/app/common/dtos/activity-type/activity-type.response";
import { ActivityTypeRepositoryInterface } from "../repositories/activity-type/activity-type.interface";

@Injectable({
    providedIn:"root"
})
export class ActivityTypeManager {

    constructor(
        private activityTypeRepository: ActivityTypeRepositoryInterface
    ) {}

    public getActivities(lang : string): Observable<ActivityTypeResponseDto> {
        return this.activityTypeRepository.getActivities(lang);
    }

}