import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";

export class ObservationMedia extends Entity {

    @AutoMap()
    public name!: string;

    @AutoMap()
    public type!: string;

    @AutoMap()
    public "file_name": string;

}
