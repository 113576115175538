import { AutoMap } from "@automapper/classes";

export class ObservationMediaDto {
    @AutoMap()
    public id!: string;

    @AutoMap()
    public name!: string;

    @AutoMap()
    public type?: string;

    @AutoMap()
    public "file_name"?: string;

    @AutoMap()
    public url?: string;
}