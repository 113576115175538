import { NgModule } from "@angular/core";
import { AuthService } from "./auth.service";
import { ActiveUser } from "./active-user.service";

@NgModule({

})
export class UserModule {
    static forRoot() {
        return {
            ngModule: UserModule,
            providers: [
                AuthService
            ]
        };
    }

    static forChild() {
        return {
            ngModule: UserModule,
            providers: [
                {
                    provide: ActiveUser,
                    useFactory: (service: AuthService) => {
                        return service.user.value;
                    },
                    deps: [
                        AuthService
                    ]
                }
            ]
        };
    }
}