import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { CountryDto } from "../../dtos/country/country.dto";
import { Country } from "./country.model";

export const countryProfile: MappingProfile = (mapper) => {
    createMap(mapper, CountryDto, Country, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};