import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { TranslationDto } from "src/app/common/dtos/translation/translation.dto";
import { TranslationResponseDto } from "src/app/common/dtos/translation/translation.response";
import { TranslationRepositoryInterface } from "./translation.interface";

@Injectable()
export class TranslationRepository extends Repository implements TranslationRepositoryInterface {

    public getTranslations(): Observable<TranslationResponseDto> {

        return this.http.get<TranslationDto[]>("/translation/language").pipe(map((translationDto: TranslationDto[]) => {
            const dto = new TranslationResponseDto();
            dto.content = translationDto;
            return dto;
        }));
    }
}