import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ReferenceDto } from "../../dtos/reference/reference.dto";
import { Reference } from "./reference.model";

export const referenceProfile: MappingProfile = (mapper) => {
    createMap(mapper, ReferenceDto, Reference, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};