import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BusinessUnitsRepositoryInterface } from "../repositories/business-unit/business-units.interface";
import { BusinessUnitResponseDto } from "src/app/common/dtos/business-unit/business-unit.response";

@Injectable({
    providedIn:"root"
})
export class BusinessUnitManager {

    constructor(
        private businessUnitRepository: BusinessUnitsRepositoryInterface
    ) {}

    public getBusinessUnits(): Observable<BusinessUnitResponseDto> {
        return this.businessUnitRepository.getBusinessUnits();
    }

}