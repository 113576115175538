import { Component, OnInit, Inject, ViewChild, ElementRef } from "@angular/core";
import { DialogStepperField } from "./dialog-stepper-field";
import { DialogStepperData } from "./dialog-stepper-data";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatSelect } from "@angular/material/select";
import { DialogPrototypeComponent } from "../dialog-prototype/dialog-prototype.component";
import { DialogStepperResponse } from "./dialog-stepper-response";
import { DialogErrorComponent } from "./dialog-error/dialog-error.component";
import { DialogError } from "./dialog-error/dialog-error";
import { ThemePalette } from "@angular/material/core";
import { DialogMessageComponent } from "./dialog-message/dialog-message.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
    selector: "app-dialog-stepper",
    templateUrl: "./dialog-stepper.component.html",
    styleUrls: [
        "./dialog-stepper.component.scss"
    ]
})
export class DialogStepperComponent implements OnInit {

    public date!: moment.Moment;
    public disabled = false;
    public showSpinners = true;
    public showSeconds = false;
    public touchUi = false;
    public enableMeridian = false;
    public minDate!: moment.Moment;
    public maxDate!: moment.Moment;
    maxDateTime: Date = new Date();
    public stepHour = 1;
    public stepMinute = 1;
    public stepSecond = 1;
    public color: ThemePalette = "primary";
    firstField = false;

    @ViewChild("searchField") searchField!: ElementRef;
    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogStepperData,
        private dialogRef: MatDialogRef<DialogPrototypeComponent>,
        public dialog: MatDialog,
        private translate: TranslateService) { }

    ngOnInit(): void {
        this.data.steps.forEach((element) => {
            element.fields.forEach((field) => {
                if (field.type === "number" && field.boundaries) {
                    field.formControl?.valueChanges.subscribe((value) => {
                        if (!field.formControl?.valid) {
                            if (field.boundaries) {
                                field.formControl?.setValue(
                                    Math.min(
                                        Math.max(value, field.boundaries.min), field.boundaries.max));
                            }
                        }
                    });
                } else if (field.type === "select" && field.autocomplete) {
                    field.autocomplete.valueChanges.subscribe((value) => {
                        this.filterOptions(value, field);
                    });
                } else if (field.type === "multiSelect" && field.autocomplete) {
                    field.autocomplete.valueChanges.subscribe((value) => {
                        this.filterOptions(value, field);
                    });
                } else if (field.type === "selectInput" && field.autocomplete) {
                    field.autocomplete.valueChanges.subscribe((value) => {
                        this.filterOptions(value, field);
                    });
                }
            });
        });
    }

    clearSelection(field: DialogStepperField) {
        field.formControl?.setValue("");
    }

    openSelect(selectElement: MatSelect) {
        selectElement.open();
    }

    onSelectOpened(opened: boolean): void {
        if (opened) {
            this.searchField.nativeElement.focus();
        }
    }

    filterOptions(filterString: string, field: DialogStepperField) {
        field.filteredOptions = field.options?.filter((option) =>
            option.key.toLowerCase().includes(filterString.toLowerCase()));
    }

    filterSelectOptions(event: Event, field: DialogStepperField) {
        const inputElement = event.target as HTMLInputElement;
        field.filteredOptions = field.options?.filter((option) =>
            option.key.toLowerCase().includes(inputElement.value.toLowerCase()));
    }

    buttonClicked(buttonType: string) {

        switch (buttonType) {
            case "cancel": {
                this.dialogRef.close();
                break;
            }
            case "back":{
                const msg = this.translate.instant("message.close.window");
                const dialogRef = this.dialog.open(DialogMessageComponent, { data: { msg } });
                dialogRef.afterClosed().subscribe((result) => {
                    if(result) {
                        this.dialogRef.close();
                    }
                });
                break;
            }
            case "submit": {
                const formResult: DialogStepperResponse = {
                    uuid: this.data.uuid || "",
                    dataToSave: {}
                };

                const listaValidations: string[] = [];
                for (const step of this.data.steps) {
                    for (const field of step.fields) {
                        if (step.form?.get(field.name)?.invalid) {
                            const str = "Campo '" + field.label + "' obbligatorio nello step '" + step.title + "'\n";
                            listaValidations.push(str);
                        }
                    }
                }

                if (listaValidations.length > 0) {
                    const error: DialogError = {
                        title:"Attenzione",
                        message: listaValidations
                    };

                    this.dialog.open(DialogErrorComponent, {
                        data: { error }
                    });
                    return;
                }

                for(const f of this.data.steps) {
                    for (const field of f.fields) {
                        formResult.dataToSave[field.name] = f?.form!.get(field.name)?.value;
                    }
                }

                this.dialogRef.close(formResult);
                break;
            }
            default: {
                this.dialogRef.close();
                break;
            }
        }
    }
}
