import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ReferenceManager } from "../managers/reference.manager";
import { ReferenceResponseDto } from "src/app/common/dtos/reference/reference.response";
import { ReferenceCreateDto } from "src/app/common/dtos/reference/reference-create.dto";
import { Reference } from "src/app/common/models/reference/reference.model";
import { ReferenceUpdateDto } from "src/app/common/dtos/reference/reference-update.dto";

@Injectable({
    providedIn: "root"
})
export class ReferenceService {

    constructor(
        private referenceManager: ReferenceManager
    ) {}

    public getReferences(searchString?: string, page?: number, size?: number, sortBy?: string, lang?: string):
    Observable<ReferenceResponseDto> {
        return this.referenceManager.getReferences(searchString, page, size, sortBy, lang);
    }

    public createReference(reference: ReferenceCreateDto): Observable<Reference | undefined> {
        if(reference == null) return of(undefined);

        return this.referenceManager.createReference(reference);
    }

    public updateReference(id: string, reference: ReferenceUpdateDto): Observable<Reference | undefined> {
        if(reference == null) return of(undefined);

        return this.referenceManager.updateReference(id, reference);
    }

    public deleteReference(id: string): Observable<void> {
        return this.referenceManager.deleteReference(id);
    }
}