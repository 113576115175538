<div>
  <mat-form-field class="formFieldExpanded">
    <mat-label>{{ field.caption }}</mat-label>

    <input
      [readonly]="checkDisabled(field)"
      matInput
      [formControl]="field.formControl"
      (focus)="openSelect(selectElement)"
    />

    <mat-select #selectElement [formControl]="field.formControl">
      <mat-option *ngFor="let option of field.options" [value]="option">
        {{ option.key }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <span
    class="formFieldExpanded"
    *ngIf="field.formControl.getRawValue()"
    (click)="clearSelection(field)"
  >
    <mat-icon>close</mat-icon>
  </span>
</div>
