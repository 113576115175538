import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";
import { Country } from "../country/country.model";
import { BusinessUnit } from "../business-unit/business-unit.model";

export class Project extends Entity {

    @AutoMap()
    public description!: string;

    @AutoMap()
    public code!: string;

    @AutoMap()
    public isClosed!: boolean;

    @AutoMap()
    public country!: Country;

    @AutoMap()
    public businessUnit!: BusinessUnit;
}
