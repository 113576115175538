<div class="m-3 mat-elevation-z8">
  <div class="table-container">
    <table mat-table [dataSource]="_dataSource">
      <ng-container
        *ngFor="let tableColumn of columns"
        [matColumnDef]="tableColumn.caption"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          (click)="tableColumn.sort && emitColumnSort(tableColumn.field)"
          [ngClass]="{ pointer: tableColumn.sort }"
        >
          {{ tableColumn.caption | translate }}
          <mat-icon *ngIf="tableColumn.sort" style="vertical-align: middle"
            >swap_vert</mat-icon
          >
        </th>

        <td mat-cell *matCellDef="let element">
          <ng-container
            *ngIf="tableColumn.field === 'origin'; else defaultContent"
          >
            <ng-container *ngIf="element.origin === 'APP_MOBILE'">
              <mat-icon>phone_android</mat-icon>
            </ng-container>
            <ng-container *ngIf="element.origin === 'QR_CODE'">
              <mat-icon>qr_code_2</mat-icon>
            </ng-container>
            <ng-container *ngIf="element.origin === 'WEB_APP'">
              <mat-icon>language</mat-icon>
            </ng-container>
            <ng-container
              *ngIf="
                element !== 'APP_MOBILE' &&
                element !== 'QR_CODE' &&
                element !== 'WEB_APP'
              "
            >
            </ng-container>
          </ng-container>
          <ng-template #defaultContent>
            {{
              parseColumn(
                element,
                tableColumn.field,
                tableColumn.format,
                tableColumn.mutuallyExcludes
              )
            }}
          </ng-template>
        </td>
      </ng-container>
      <ng-container *ngFor="let rowActionIcon of rowActionIcons">
        <ng-container
          *ngIf="rowActionIcon?.length"
          [matColumnDef]="rowActionIcon"
        >
          <th mat-header-cell *matHeaderCellDef></th>
          <td
            mat-cell
            *matCellDef="let element"
            [id]="rowActionIcon"
            (click)="emitRowAction(rowActionIcon, element)"
          >
            <span
              *ngIf="rowActionIcon !== 'event_note' || element.note"
              class="clickable"
              [style.color]="rowActionIcon === 'delete' ? 'red' : ''"
            >
              <ng-container
                *ngIf="rowActionIcon === 'event_note'; else otherIcons"
              >
                <mat-icon
                  [matBadge]="element.countnote"
                  matBadgeSize="small"
                  matBadgeColor="primary"
                  matTooltipClass="myTooltip"
                  [matTooltip]="element.descrizionenote"
                  matTooltipPosition="left"
                  >{{ rowActionIcon }}</mat-icon
                >
              </ng-container>

              <ng-template #otherIcons>
                <span *ngIf="rowActionIcon === 'upload'; else regularIcon">
                  <mat-icon
                    [matBadge]="element.countmedia"
                    matBadgeSize="small"
                    matBadgeColor="primary"
                    [matBadgeHidden]="element.countmedia < 1"
                  >
                    {{ rowActionIcon }}
                  </mat-icon>
                </span>
                <ng-template #regularIcon>
                  <mat-icon>{{ rowActionIcon }}</mat-icon>
                </ng-template>
              </ng-template>
            </span>
          </td>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
  <mat-paginator
    #paginator
    [pageSizeOptions]="[5, 10]"
    [length]="totalElements"
    (page)="emitPageEvent($event)"
    aria-label="Select page"
    [showFirstLastButtons]="showFirstLastButtons"
  >
  </mat-paginator>
</div>
