import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ActivityTypeDto } from "../../dtos/activity-type/activity-type.dto";
import { ActivityType } from "./activity-type.model";

export const activityTypeProfile: MappingProfile = (mapper) => {
    createMap(mapper, ActivityTypeDto, ActivityType, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};