import { NgModule } from "@angular/core";
import { RepositoryFactoryProvider } from "src/app/common/utils/backend.provider";
import { LocalStorageRepositoryInterface } from "../local-storage/local-storage.interface";
import { LoadingOverlayService } from "../../components/loading-overlay/services/loading-overlay.service";
import { RouterService } from "../../services/router.service";
import { TokenService } from "../../services/user/token.service";
import { UsersHttpBackend } from "../users/users.backend";
import { ReferenceClassificationsRepositoryInterface } from "./reference-classifications.interface";
import { ReferenceClassificationsRepository } from "./reference-classifications.repository";

@NgModule({
    providers: [
        RepositoryFactoryProvider({
            provide: ReferenceClassificationsRepositoryInterface,
            repository: ReferenceClassificationsRepository,
            backend: UsersHttpBackend,
            deps: [
                LocalStorageRepositoryInterface,
                RouterService,
                LoadingOverlayService,
                TokenService
            ]
        })
    ]
})
export class ReferenceClassificationsRepositoryModule { }