<div class="main-container">
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav
      #sidenav
      class="sidenav"
      fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false"
    >
      <div class="containerImg">
        <img
          src="./assets/images/bonatti-logo-text.jpg"
          alt="image"
          style="width: 130px"
          class="centered-image"
        />
      </div>
      <mat-nav-list>
        <mat-list-item
          *ngFor="let item of getMenuItemsBasedOnRole()"
          mat-list-item
          [routerLink]="item.routerLink"
        >
          <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
          <div matListItemTitle>{{ item.title | translate }}</div>
        </mat-list-item>
      </mat-nav-list>
      <!--
      <mat-nav-list>
        <mat-list-item
          *ngFor="let item of menuItems"
          mat-list-item
          [routerLink]="item.routerLink"
        >
          <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
          <div matListItemTitle>{{ item.title | translate }}</div>
        </mat-list-item>
      </mat-nav-list>
      <mat-divider></mat-divider>
      <mat-nav-list>
        <mat-list-item
          *ngFor="let item of menuItemsAdmin"
          mat-list-item
          [routerLink]="item.routerLink"
        >
          <mat-icon matListItemIcon>{{ item.icon }}</mat-icon>
          <div matListItemTitle>{{ item.title | translate }}</div>
        </mat-list-item>
      </mat-nav-list>-->
      <div style="display: flex; justify-content: center; align-items: center">
        <div style="position: absolute; bottom: 0">
          <img
            src="../../../../../assets/images/StopNoIncident.png"
            style="width: 70%; margin-left: 15%; opacity: 0.7"
          />
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <app-toolbar (toggleSidenav)="sidenav.toggle()"></app-toolbar>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
