<h2 mat-dialog-title>{{ "dialog.message.title" | translate }}</h2>
<mat-dialog-content>
  <h3>{{ data.msg }}</h3>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>
    {{ "button.cancel" | translate }}
  </button>
  <button
    mat-raised-button
    [mat-dialog-close]="true"
    cdkFocusInitial
    color="primary"
  >
    {{ "button.ok" | translate }}
  </button>
</mat-dialog-actions>
