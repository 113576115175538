<mat-form-field class="formFieldExpanded">
  <mat-label>{{ field.caption | translate }}</mat-label>
  <input
    [readonly]="disabled"
    matInput
    type="text"
    [formControl]="field.formControl"
    [matAutocomplete]="auto"
  />
  <mat-autocomplete
    #auto="matAutocomplete"
    (optionSelected)="selectOption($event.option)"
    [displayWith]="displayFunction"
  >
    <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
      {{ option.key | translate }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
