import { NgModule } from "@angular/core";
import { RepositoryFactoryProvider } from "src/app/common/utils/backend.provider";
import { LocalStorageRepositoryInterface } from "../local-storage/local-storage.interface";
import { LoadingOverlayService } from "../../components/loading-overlay/services/loading-overlay.service";
import { RouterService } from "../../services/router.service";
import { TokenService } from "../../services/user/token.service";
import { ObservationsRepositoryInterface } from "./observations.interface";
import { ObservationsRepository } from "./observations.repository";
import { UsersHttpBackend } from "../users/users.backend";

@NgModule({
    providers: [
        RepositoryFactoryProvider({
            provide: ObservationsRepositoryInterface,
            repository: ObservationsRepository,
            backend: UsersHttpBackend,
            deps: [
                LocalStorageRepositoryInterface,
                RouterService,
                LoadingOverlayService,
                TokenService
            ]
        })
    ]
})
export class ObservationsRepositoryModule { }