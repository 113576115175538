import { Injectable } from "@angular/core";
import { RouterService } from "../services/router.service";
import { AuthService } from "../services/user/auth.service";
import { LoginComponent } from "src/app/ui/frontend/login/login.component";

@Injectable()
export class AuthGuard {
    constructor(
        private router: RouterService,
        private userService: AuthService
    ) {}

    canActivate(): boolean {
        if (!this.userService.logged) {
            this.router.navigateTo(LoginComponent);
        }
        return true;
    }
}
