import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { CountryResponseDto } from "src/app/common/dtos/country/country.response";
import { CountryManager } from "../managers/country.manager";

@Injectable({
    providedIn: "root"
})
export class CountryService {

    constructor(
        private countryManager: CountryManager
    ) {}

    public getCountries(): Observable<CountryResponseDto> {
        return this.countryManager.getCountries();
    }
}