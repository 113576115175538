import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SharedModule } from "src/app/shared/shared.module";
import { CommonModule } from "@angular/common";
import { MatButtonModule } from "@angular/material/button";
import { ObservationsComponent } from "./observations.component";
import { MatIconModule } from "@angular/material/icon";
import { MatBadgeModule } from "@angular/material/badge";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { LoaderModule } from "src/app/shared/components/loader/loader.module";

@NgModule({
    declarations: [
        ObservationsComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        CommonModule,
        SharedModule,
        MatButtonModule,
        MatIconModule,
        MatBadgeModule,
        MatProgressSpinnerModule,
        LoaderModule
    ],
    exports: [
        ObservationsComponent
    ]
})
export class ObservationsModule { }