import { NgModule } from "@angular/core";
import { RepositoryFactoryProvider } from "src/app/common/utils/backend.provider";
import { LocalStorageRepositoryInterface } from "../local-storage/local-storage.interface";
import { LoadingOverlayService } from "../../components/loading-overlay/services/loading-overlay.service";
import { RouterService } from "../../services/router.service";
import { TokenService } from "../../services/user/token.service";
import { UsersHttpBackend } from "../users/users.backend";
import { ActivityTypeRepositoryInterface } from "./activity-type.interface";
import { ActivityTypeRepository } from "./activity-type.repository";

@NgModule({
    providers: [
        RepositoryFactoryProvider({
            provide: ActivityTypeRepositoryInterface,
            repository: ActivityTypeRepository,
            backend: UsersHttpBackend,
            deps: [
                LocalStorageRepositoryInterface,
                RouterService,
                LoadingOverlayService,
                TokenService
            ]
        })
    ]
})
export class ActivityTypeRepositoryModule { }