import { AutoMap } from "@automapper/classes";
import { ReferenceDto } from "./reference.dto";
import { MetaResponseDto } from "../page/meta.response.";
import { LinksResponseDto } from "../page/links.response";

export class ReferenceResponseDto {

    @AutoMap()
    public data!: ReferenceDto[];

    @AutoMap()
    public meta?: MetaResponseDto;

    @AutoMap()
    public links?: LinksResponseDto;

}