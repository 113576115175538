import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ProjectDto } from "../../dtos/project/project.dto";
import { Project } from "./project.model";

export const projectProfile: MappingProfile = (mapper) => {
    createMap(mapper, ProjectDto, Project, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};