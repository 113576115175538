import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslationResponseDto } from "src/app/common/dtos/translation/translation.response";

@Injectable({
    providedIn : "root"
})
export abstract class TranslationRepositoryInterface {

    public abstract getTranslations(): Observable<TranslationResponseDto>;

}