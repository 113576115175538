import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { StatusDto } from "src/app/common/dtos/status/status.dto";
import { StatusResponseDto } from "src/app/common/dtos/status/status.response";
import { StatusesRepositoryInterface } from "./statuses.interface";

@Injectable()
export class StatusesRepository extends Repository implements StatusesRepositoryInterface {

    public getStatus(): Observable<StatusResponseDto> {
        return this.http.get<StatusDto[]>("/statuses").pipe(map((activityTypeDto: StatusDto[]) => {
            const dto = new StatusResponseDto();
            dto.content = activityTypeDto;
            return dto;
        }));
    }
}