import { AutoMap } from "@automapper/classes";
import { Reference } from "../../models/reference/reference.model";
import { Observation } from "../../models/observation/observation.model";

export class ObservationReferenceDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public reference!:Reference;

    @AutoMap()
    public observation!:Observation;

}