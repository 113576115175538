import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ActivityTypeResponseDto } from "src/app/common/dtos/activity-type/activity-type.response";
import { ActivityTypeManager } from "../managers/activity-type.manager";

@Injectable({
    providedIn: "root"
})
export class ActivityTypeService {

    constructor(
        private activityTypeManager: ActivityTypeManager
    ) {}

    public getActivities(lang: string): Observable<ActivityTypeResponseDto> {
        return this.activityTypeManager.getActivities(lang);
    }
}