import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";
import { ReferenceClassifications } from "../reference-classifications/reference-classifications.model";

export class Reference extends Entity {

    @AutoMap()
    public summary!: string;

    @AutoMap()
    public referenceClassifications! : ReferenceClassifications;

    @AutoMap()
    public pictogram!: string;

}