import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ObservationManager } from "../managers/observation.manager";
import { ObservationResponseDto } from "src/app/common/dtos/observation/observation.response";
import { Observation } from "src/app/common/models/observation/observation.model";
import { ObservationUpdateDto } from "src/app/common/dtos/observation/observation-update.dto";
import { ObservationCreateDto } from "src/app/common/dtos/observation/observation-create.dto";
import { ObservationStatusResponseDto } from "src/app/common/dtos/observation-status/observation-status.response";
import { ObservationStatus } from "src/app/common/models/observation-status/observation-status.model";
import { ObservationMediaResponseDto } from "src/app/common/dtos/observation-media/observation-media.response";
import { ObservationMedia } from "src/app/common/models/observation-media/observation-media.model";
import { ObservationResponseAllDto } from "src/app/common/dtos/observation/observation.response.all";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { UserProject } from "src/app/common/models/user-project/user-project.model";
import { UserProjectResponseDto } from "src/app/common/dtos/user-project/user-project.response";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";

@Injectable({
    providedIn: "root"
})
export class ObservationService {

    constructor(
        private observationManager: ObservationManager
    ) {}

    public getObservations(searchString?: string, page?: number, size?: number, sortBy?: string, lang?: string,
        projects?: string)
        : Observable<ObservationResponseDto> {
        return this.observationManager.getObservations(searchString, page, size, sortBy, lang, projects);
    }

    public getAllObservations(searchString?: string, sortBy?: string, lang?: string, projects?: string)
        : Observable<ObservationResponseAllDto> {
        return this.observationManager.getAllObservations(searchString, sortBy, lang, projects);
    }

    public getObservation(id:string, lang?: string)
        : Observable<Observation> {
        return this.observationManager.getObservation(id, lang);
    }

    public createObservation(observation: ObservationCreateDto): Observable<Observation | undefined> {
        if(observation == null) return of(undefined);

        return this.observationManager.createObservation(observation);
    }

    public updateObservation(id: string, observation: ObservationUpdateDto): Observable<Observation | undefined> {
        if(observation == null) return of(undefined);

        return this.observationManager.updateObservation(id, observation);
    }

    public deleteObservation(id: string): Observable<void> {
        return this.observationManager.deleteObservation(id);
    }

    public getObservationStatus(id: string): Observable<ObservationStatusResponseDto> {
        return this.observationManager.getObservationStatus(id);
    }

    public createObservationStatus(observationId: string,
        statusId: string,
        comment?:string):
        Observable<ObservationStatus | undefined> {
        if(observationId == null && statusId == null) return of(undefined);

        return this.observationManager.createObservationStatus(observationId, statusId, comment);
    }

    public getObservationMedia(observationId: string): Observable<ObservationMediaResponseDto> {
        return this.observationManager.getObservationMedia(observationId);
    }

    public createObservationMedia(observationId: string, formData: FormData):
    Observable<ObservationMedia | undefined> {
        if(observationId == null) return of(undefined);

        return this.observationManager.createObservationMedia(observationId, formData);
    }

    public deleteObservationMedia(id: string): Observable<void> {
        return this.observationManager.deleteObservationMedia(id);
    }

    public getProjectsByUser(id: string) : Observable<UserProjectResponseDto> {
        return this.observationManager.getProjectsByUser(id);
    }

    public createUserProject(userId: string, projectId: string):
    Observable<UserProject | undefined> {
        if(userId == null) return of(undefined);

        return this.observationManager.createUserProject(userId, projectId);
    }

    public deleteUserProject(id: string): Observable<void> {
        if(id == null) return of(undefined);
        return this.observationManager.deleteUserProject(id);
    }
}