import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ObservationMedia } from "./observation-media.model";
import { ObservationMediaDto } from "../../dtos/observation-media/observation-media.dto";

export const observationMediaProfile: MappingProfile = (mapper) => {
    createMap(mapper, ObservationMediaDto, ObservationMedia, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};