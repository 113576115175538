import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { ObservationStatus } from "./observation-status.model";
import { ObservationStatusDto } from "../../dtos/observation-status/observation-status.dto";

export const observationStatusProfile: MappingProfile = (mapper) => {
    createMap(mapper, ObservationStatusDto, ObservationStatus, namingConventions({
        source: new SnakeCaseNamingConvention(),
        destination: new CamelCaseNamingConvention()
    }));
};