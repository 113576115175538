import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { DialogError } from "./dialog-error";

@Component({
    selector: "app-dialog-error",
    styleUrls: [
        "./dialog-error.component.scss"
    ],
    templateUrl: "./dialog-error.component.html"
})

export class DialogErrorComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogErrorComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {error: DialogError}
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }

}
