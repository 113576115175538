import { AuthService } from "./../../../../shared/services/user/auth.service";
import { Option } from "./../../../../shared/components/table/filter/option";
import { DatePipe } from "@angular/common";
import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormBuilder, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatTableDataSource } from "@angular/material/table";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { Observation } from "src/app/common/models/observation/observation.model";
import { DialogData } from "src/app/shared/components/dialog-prototype/dialog-data";
import { DialogPrototypeComponent } from "src/app/shared/components/dialog-prototype/dialog-prototype.component";
import { Column } from "src/app/shared/components/table/column";
import { Field } from "src/app/shared/components/table/filter/field";
import { ObservationService } from "src/app/shared/services/observation.service";
import { ActivityTypeService } from "src/app/shared/services/activity-type.service";
import { ActivityTypeDto } from "src/app/common/dtos/activity-type/activity-type.dto";
import { ReferenceDto } from "src/app/common/dtos/reference/reference.dto";
import { ReferenceService } from "src/app/shared/services/reference.service";
import { DialogStepperData } from "src/app/shared/components/dialog-stepper/dialog-stepper-data";
import { DialogStepperComponent } from "src/app/shared/components/dialog-stepper/dialog-stepper.component";
import { ProjectService } from "src/app/shared/services/project.service";
import { ProjectDto } from "src/app/common/dtos/project/project.dto";
import { StatusDto } from "src/app/common/dtos/status/status.dto";
import { MatSnackBar } from "@angular/material/snack-bar";
import { lastValueFrom } from "rxjs";
import { StatusService } from "src/app/shared/services/status.service";
import { TranslateService } from "@ngx-translate/core";
import { ObservationMedia } from "src/app/common/models/observation-media/observation-media.model";
import { ExportService } from "src/app/shared/services/export.service";
import { TranslationService } from "src/app/shared/services/translation.service";
import { TranslationResponseDto } from "src/app/common/dtos/translation/translation.response";
import { UserService } from "src/app/shared/services/user.service";
import { ObservationUpdateDto } from "src/app/common/dtos/observation/observation-update.dto";
import moment from "moment";
import { ObservationExport } from "src/app/common/models/observation/observation-export.model";
import { UserDto } from "src/app/common/dtos/user/user.dto";
import { UserProjectDto } from "src/app/common/dtos/user-project/user-project.dto";

@Component({
    selector: "app-observations",
    templateUrl: "./observations.component.html",
    styleUrls: [
        "./observations.component.scss"
    ]
})
export class ObservationsComponent implements OnInit {

    tableData!: Observation[];
    tableColumns!: Column[];

    filters!: Field[];// =  this.getFilters();

    filterSource!: MatTableDataSource<unknown>;

    filtersData!: Map<string,
    { customValidation?: (filterValue: unknown, dataValue: unknown) => boolean, value: string }>;

    currentFilterSearchString = "";

    currentPageSize = 5;

    requestTotalElements = 0;

    sortTable = "id:DESC";

    resetPageEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    activityOption!: Option[];
    referencesOption!: Option[];
    projectOption!: Option[];
    statusOption!: Option[];
    mediaOption!: Option[];
    fileInfos!: ObservationMedia[];
    username = "";
    user!: UserDto | undefined;
    obsUpdate!: ObservationUpdateDto;
    visibleButtonExport = false;
    filteredItems!: ObservationDto[];
    projects = "";
    isDownloading = false;
    isLoading = true;
    isDisabled = false;

    constructor(
        private observationService: ObservationService,
        public dialog: MatDialog,
        private activityTypeService: ActivityTypeService,
        private referenceService: ReferenceService,
        private projectService: ProjectService,
        private statusService: StatusService,
        private formBuilder: FormBuilder,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private exportService: ExportService,
        private translationService: TranslationService,
        private userService: UserService,
        private authService: AuthService
    ) {
        this.username = this.authService.getUsername()!.replace(/"|\u0027/g, "");
    }

    async ngOnInit(): Promise<void> {
        this.tableColumns = this.getColumns();
        this.filters = await this.getFilters();
        const result = await lastValueFrom(this.userService.getUsers("username=" + this.username));
        this.user = result.data[0];
        this.getUserProjects(this.user!);
        this.getActivities();
        this.getReferences(undefined, 1, 100, "referenceClassifications:ASC");
        this.getAllProjects();
        this.getStatuses();
        this.getObservations(undefined, 1, this.currentPageSize, this.sortTable, this.projects);
    }

    requestPage(page: { page: number, size: number }) {
        this.currentPageSize = page.size;
        this.getObservations(this.currentFilterSearchString, page.page + 1, page.size, this.sortTable, this.projects);
    }

    getColumns(): Column[] {
        return [
            {
                caption: "observationcard.form.cardnumber", field: "cardNumber", sort: true, format(value) {
                    return value!.toString().padStart(8, "0");
                }
            },
            { caption: " ", field: "origin" },
            {
                caption: "observationcard.form.datetime", field: "insertDate", sort: true, format(value) {
                    const datepipe: DatePipe = new DatePipe("en-US");
                    const formattedDate = datepipe.transform(value as Date, "dd/MM/YYYY HH:mm");
                    if (formattedDate) {
                        return formattedDate?.toString();
                    } else {
                        return "";
                    }
                }
            },
            { caption: "observationcard.form.type", field: "activityType.description" },
            { caption: "observationcard.form.project", field: "project.description" },
            { caption: "observationcard.form.status", field: "status.description" }
        ];
    }

    async getObservations(searchString?: string, page?: number, size?: number, sortBy?: string, projects?: string) {
        const languages = await this.getTraslations();
        const lang = languages.content.find((language) => language.code === this.translate.currentLang);
        this.isLoading = true;
        this.isDisabled = true;
        this.visibleButtonExport = false;
        await this.observationService.getObservations(searchString, page, size, sortBy, lang?.id, projects)
            .subscribe(async(result) => {
                this.requestTotalElements = result.meta?.totalItems || 0;
                this.tableData = mapper.mapArray(result?.data, ObservationDto, Observation);
                this.isLoading = false;
                this.isDisabled = false;
                this.visibleButtonExport = this.manageVisibilityExport(this.currentFilterSearchString);
            });
    }

    async getObservation(id: string): Promise<Observation> {
        const languages = await this.getTraslations();
        const lang = languages.content.find((language) => language.code === this.translate.currentLang);
        const result = await lastValueFrom(this.observationService.getObservation(id, lang?.id));
        return result;
    }

    async getUserProjects(user: UserDto) {
        await this.observationService.getProjectsByUser(user?.id)
            .subscribe(async(result) => {
                const projectIdString = result.user_project!.map((item) => item.project.id).join(",");
                this.projects = projectIdString;
            });
    }

    async getObservationMedia(id: string) {
        const result = await lastValueFrom(this.observationService.getObservationMedia(id));
        const optionList: Option[] = result?.media.map((mediaItem) => {
            return {
                key: mediaItem.id.toString(),
                value: mediaItem.name
            };
        });
        this.mediaOption = optionList;
    }

    async getTraslations(): Promise<TranslationResponseDto> {
        const result = await lastValueFrom(this.translationService.getTranslations());
        return result;
    }

    async getActivities(): Promise<Option[]> {

        const languages = await this.getTraslations();
        const lang = languages.content.find((language) => language.code === this.translate.currentLang);

        const result = await lastValueFrom(this.activityTypeService.getActivities(lang!.id!));

        const optionList = result?.content.map((activityItem: ActivityTypeDto) => {
            return {
                key: activityItem?.description?.toString(),
                value: activityItem?.id
            };
        });
        optionList.sort((a, b) => a.key.localeCompare(b.key));
        this.activityOption = optionList;
        return optionList;
    }

    async getReferences(searchString?: string, page?: number, size?: number, sortBy?: string): Promise<Option[]> {
        const result = await lastValueFrom(this.referenceService.getReferences(searchString, page, size, sortBy));

        const optionList = result?.data.map((referenceItem: ReferenceDto) => {
            return {
                key: referenceItem?.reference_classifications.description
                    + " - " + referenceItem?.summary?.toString(),
                value: referenceItem?.id
            };
        });
        this.referencesOption = optionList;
        return optionList;
    }

    async getAllProjects(): Promise<Option[]> {
        if (this.user === undefined) {
            const r = await lastValueFrom(this.userService.getUsers("username=" + this.username));
            this.user = r.data[0];
            this.getUserProjects(this.user);
        }
        // eslint-disable-next-line
        const result = await lastValueFrom(this.observationService.getProjectsByUser(this.user?.id!));
        // eslint-disable-next-line
        if (result.user_project?.length == 0) {
            const resultAll = await lastValueFrom(this.projectService.getAllProjects());
            const optionListAll = resultAll?.content!.map((projectItem: ProjectDto) => {
                return {
                    key: projectItem?.code?.toString()
                        + " - " + projectItem?.description?.toString()
                        + " - " + projectItem?.country.description,
                    value: projectItem?.id
                };
            });
            optionListAll.sort((a, b) => a.key.localeCompare(b.key));
            this.projectOption = optionListAll;
            return optionListAll;
        } else {
            const optionList = result?.user_project!.map((projectItem: UserProjectDto) => {
                return {
                    key: projectItem?.project.code?.toString()
                        + " - " + projectItem?.project.description?.toString()
                        + " - " + projectItem?.project.country.description,
                    value: projectItem?.project.id.toString()
                };
            });
            optionList.sort((a, b) => a.key.localeCompare(b.key));
            this.projectOption = optionList;
            return optionList;
        }

    }

    async getStatuses(): Promise<Option[]> {
        const result = await lastValueFrom(this.statusService.getStatus());
        return result?.content.map((statusItem: StatusDto) => {
            return {
                key: statusItem?.description?.toString(),
                value: statusItem?.id
            };
        });

    }

    async getFilters(): Promise<Field[]> {
        return [
            {
                id: "card_number",
                caption: "observationcard.form.cardnumber",
                fieldType: "number",
                formControl: new FormControl(""),
                field: "cardNumber",
                selectedFilter: "",
                options: []
            },
            {
                id: "insertDate",
                caption: "observationcard.form.insertdate",
                fieldType: "rangedate",
                formControl: new FormControl(""),
                field: "insertDate",
                selectedFilter: "",
                options: []
            },
            {
                id: "activityType",
                caption: "observationcard.form.activityType",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "activityType",
                selectedFilter: "",
                options: await this.getActivities()
            },
            {
                id: "project",
                caption: "observationcard.form.project",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "project",
                selectedFilter: "",
                options: await this.getAllProjects()
            },
            {
                id: "status",
                caption: "observationcard.form.status",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "observationStatus.status",
                selectedFilter: "",
                options: await this.getStatuses()
            },
            {
                id: "origin",
                caption: "observationcard.form.origin",
                fieldType: "rangecheckbox",
                formControl: new FormControl(""),
                field: "origin",
                selectedFilter: "",
                options: [
                    { key: "phone_android", value: "APP_MOBILE" },
                    { key: "qr_code_2", value: "QR_CODE" },
                    { key: "language", value: "WEB_APP" }
                ]
            }

        ];
    }

    onActionFromTableEmitted(eventData: unknown) {
        const dataEmitted = eventData as { action: string, data: Observation };

        if (dataEmitted.action === "delete") {
            this.openDeleteDialog(dataEmitted.data);
        } else if (dataEmitted.action === "edit") {
            this.openEditDialog(dataEmitted.data);
            // this.openEditDialog(dataEmitted.data.id);
        } else if (dataEmitted.action === "manage_history") {
            this.openChangeStatusDialog(dataEmitted.data);
        } else if (dataEmitted.action === "upload") {
            this.openUploadDialog(dataEmitted.data);
        } else if (dataEmitted.action === "info") {
            this.viewDialog(dataEmitted.data);
        }
    }

    onFilterDataSourceReceived(eventData: unknown) {
        const data = eventData as MatTableDataSource<unknown>;
        this.filterSource = data;
    }

    onSortTable(eventData: unknown) {
        const dataEmitted = eventData as { data: string };
        const splitted = this.sortTable.split(":");
        if (splitted[1] === "DESC") {
            this.sortTable = dataEmitted.data + ":ASC";
        } else {
            this.sortTable = dataEmitted.data + ":DESC";
        }

        this.getObservations(this.currentFilterSearchString, 1, this.currentPageSize, this.sortTable);
    }

    openStepperDialog(): void {
        const dialogFields: DialogStepperData = {
            title: "observationcard.create.title",
            steps: [
                {
                    title: "observationcard.stepper.title1",
                    completed: false,
                    lastStep: false,
                    form: this.formBuilder.group({
                        "first_name": [
                            ""
                        ],
                        "last_name": [
                            ""
                        ],
                        role: [
                            ""
                        ]
                    }),
                    fields: [
                        {
                            name: "last_name",
                            type: "text",
                            label: "observationcard.stepper.lastname",
                            fieldName: "last_name",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "first_name",
                            type: "text",
                            label: "observationcard.stepper.firstname",
                            fieldName: "first_name",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "role",
                            type: "text",
                            label: "observationcard.stepper.role",
                            fieldName: "role",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        }
                    ],
                    buttons: [
                        {
                            label: "button.next",
                            type: "next",
                            icon: "east",
                            color: "primary"
                        }
                    ]
                },
                {
                    title: "observationcard.stepper.title2",
                    completed: false,
                    lastStep: false,
                    form: this.formBuilder.group({
                        "insert_date": [
                            new Date(), Validators.required
                        ],
                        company: [
                            "", Validators.required
                        ],
                        location: [
                            ""
                        ],
                        activity: [
                            ""
                        ],
                        "activity_type_id": [
                            "", Validators.required
                        ],
                        "project_id": [
                            "", Validators.required
                        ]
                    }),
                    fields: [
                        {
                            name: "insert_date",
                            type: "datetime",
                            label: "observationcard.stepper.insertdate",
                            fieldName: "insert_date",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "activity_type_id",
                            type: "select",
                            label: "observationcard.stepper.type",
                            fieldName: "activity_type_id",
                            options: this.activityOption,
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "activity",
                            type: "text",
                            label: "observationcard.stepper.activity",
                            fieldName: "activity",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "company",
                            type: "text",
                            label: "observationcard.stepper.company",
                            fieldName: "company",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "location",
                            type: "text",
                            label: "observationcard.stepper.location",
                            fieldName: "location",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        },
                        {
                            name: "project_id",
                            type: "selectInput",
                            label: "observationcard.stepper.project",
                            fieldName: "project_id",
                            options: this.projectOption,
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        }
                    ],
                    buttons: [
                        {
                            label: "button.previous",
                            type: "previous",
                            icon: "west"
                        },
                        {
                            label: "button.next",
                            type: "next",
                            icon: "east",
                            color: "primary"
                        }
                    ]
                },
                {
                    title: "observationcard.stepper.title3",
                    completed: false,
                    lastStep: false,
                    form: this.formBuilder.group({
                        observation: [
                            "", Validators.required
                        ]
                    }),
                    fields: [
                        {
                            name: "observation",
                            type: "textarea",
                            label: "observationcard.stepper.observation",
                            fieldName: "observation",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        }
                    ],
                    buttons: [
                        {
                            label: "button.previous",
                            type: "previous",
                            icon: "west"
                        },
                        {
                            label: "button.next",
                            type: "next",
                            icon: "east",
                            color: "primary"
                        }
                    ]
                },
                {
                    title: "observationcard.stepper.title4",
                    completed: false,
                    lastStep: false,
                    form: this.formBuilder.group({
                        action: [
                            ""
                        ]
                    }),
                    fields: [
                        {
                            name: "action",
                            type: "textarea",
                            label: "observationcard.stepper.action",
                            fieldName: "action",
                            options: [],
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        }
                    ],
                    buttons: [
                        {
                            label: "button.previous",
                            type: "previous",
                            icon: "west"
                        },
                        {
                            label: "button.next",
                            type: "next",
                            icon: "east",
                            color: "primary"
                        }
                    ]
                },
                {
                    title: "observationcard.stepper.title5",
                    completed: false,
                    lastStep: true,
                    form: this.formBuilder.group({
                        "observation_reference_ids": [
                            "", Validators.required
                        ]
                    }),
                    fields: [
                        {
                            name: "observation_reference_ids",
                            type: "multiSelect",
                            label: "observationcard.stepper.references",
                            fieldName: "observation_reference_ids",
                            options: this.referencesOption,
                            formControl: new FormControl({
                                value: "",
                                disabled: false
                            })
                        }
                    ],
                    buttons: [
                        {
                            label: "button.previous",
                            type: "previous",
                            icon: "west"
                        },
                        {
                            label: "button.save",
                            type: "submit",
                            icon: "save",
                            color: "primary"
                        }
                    ]

                }
            ]
        };

        this.dialog.open(DialogStepperComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {

                    this.observationService.createObservation(response.dataToSave).subscribe((result) => {
                        // this.observationService.createObservationStatus(result!.id, "1").subscribe(() => {
                        this.resetPageEventEmitter.emit();
                        this.snackBar.open(this.translate.instant("observationcard.create.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                        // });
                    });
                }
            });
    }

    openCreateDialog(): void {
        const dialogFields: DialogData = {
            title: "Create Observation Card",
            fields: [
                {
                    name: "first_name",
                    type: "text",
                    label: "Name",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "last_name",
                    type: "text",
                    label: "Surname",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "role",
                    type: "text",
                    label: "Role",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "insert_date",
                    type: "date",
                    label: "Insert Date",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "card_number",
                    type: "text",
                    label: "Card Number",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "project_id",
                    type: "select",
                    label: "Project",
                    options: this.projectOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "location",
                    type: "text",
                    label: "Location",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "action",
                    type: "text",
                    label: "Action",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "activity_type_id",
                    type: "select",
                    label: "ActivityType",
                    options: this.activityOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "company",
                    type: "text",
                    label: "Company",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "observation",
                    type: "text",
                    label: "Observation",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "observation_reference_ids",
                    type: "multiSelect",
                    label: "References",
                    options: this.referencesOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                },
                {
                    name: "classification",
                    type: "number",
                    label: "Classification",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "Cancel",
                    type: "cancel"
                },
                {
                    label: "Create",
                    type: "submit"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.observationService.createObservation(response.dataToSave).subscribe(() => {
                        this.resetPageEventEmitter.emit();
                    });
                }
            });
    }

    async openChangeStatusDialog(data: Observation) {
        const dialogFields: DialogData = {
            title: "observationcard.change.title",
            headerText: "Observation Card #" + data.cardNumber,
            visibleButtonClose: true,
            uuid: data.id,
            fields: [
                {
                    name: "status",
                    type: "select",
                    label: "observationcard.change.status",
                    options: [
                        { key: "VERIFICATA", value: 3 },
                        { key: "SCARTATA", value: 4 }
                    ], // await this.getStatuses(),
                    formControl: new FormControl({
                        value: data.status?.id,
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "comment",
                    type: "textarea",
                    label: "observationcard.change.comment",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.save",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.observationService.createObservationStatus(response.uuid,
                    response.dataToSave.status,
                    response.dataToSave.comment)
                    .subscribe(() => {
                        this.resetPageEventEmitter.emit();
                    });
            }
        });
    }

    async openUploadDialog(data: Observation) {
        const dialogFields: DialogData = {
            title: "observationcard.upload.title",
            visibleButtonClose: false,
            headerText: "Observation Card #" + data.cardNumber,
            uuid: data.id,
            fields: [
                {
                    name: "upload",
                    type: "file",
                    label: "",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        });
    }

    async setInProgress(data: Observation) {
        const username = this.authService.getUsername()?.replace(/"|\u0027/g, "");
        const res = await lastValueFrom(this.userService.getUsers("username=" + username));
        const user = await lastValueFrom(this.userService.getUser(res.data[0].id));
        data.user = user;
        // eslint-disable-next-line
        data.user_id = user.id;
        await this.observationService.updateObservation(data.id, data).subscribe(async() => {
            await this.observationService.createObservationStatus(data.id, "2", "Presa in carico").subscribe(() => {
                this.resetPageEventEmitter.emit();
                this.snackBar.open(this.translate.instant("observationcard.detail.progress")
                    .replace("cardobs", data.cardNumber), undefined, {
                    duration: 3000,
                    panelClass: "app-notification-success"
                });
            });
        });
    }

    async viewDialog(data: Observation) {
        const dialogFields: DialogData = {
            title: "observationcard.detail.title",
            headerText: "Card N° #" + data.cardNumber!.toString().padStart(8, "0") + " - " + data.status?.description,
            visibleButtonClose: false,
            uuid: data.id,
            fields: [
                {
                    name: "first_name",
                    type: "text",
                    label: "observationcard.detail.firstname",
                    options: [],
                    formControl: new FormControl({
                        value: data.firstName,
                        disabled: true
                    })
                },
                {
                    name: "last_name",
                    type: "text",
                    label: "observationcard.detail.lastname",
                    options: [],
                    formControl: new FormControl({
                        value: data.lastName,
                        disabled: true
                    })
                },
                {
                    name: "role",
                    type: "text",
                    label: "observationcard.detail.role",
                    options: [],
                    formControl: new FormControl({
                        value: data.role,
                        disabled: true
                    })
                },
                {
                    name: "insert_date",
                    type: "datetime",
                    label: "observationcard.detail.insertdate",
                    options: [],
                    formControl: new FormControl({
                        value: data.insertDate,
                        disabled: true
                    })
                },
                {
                    name: "company",
                    type: "text",
                    label: "observationcard.detail.company",
                    options: [],
                    formControl: new FormControl({
                        value: data.company,
                        disabled: true
                    })
                },
                {
                    name: "location",
                    type: "text",
                    label: "observationcard.detail.location",
                    options: [],
                    formControl: new FormControl({
                        value: data.location,
                        disabled: true
                    })
                },
                {
                    name: "activity",
                    type: "text",
                    label: "observationcard.detail.activity",
                    options: [],
                    formControl: new FormControl({
                        value: data.activity,
                        disabled: true
                    })
                },
                {
                    name: "activity_type_id",
                    type: "text",
                    label: "observationcard.detail.type",
                    options: [],
                    formControl: new FormControl({
                        value: data.activityType.description,
                        disabled: true
                    })
                },
                {
                    name: "project_id",
                    type: "text",
                    label: "observationcard.detail.project",
                    options: [],
                    formControl: new FormControl({
                        value: data.project.description,
                        disabled: true
                    })
                },
                {
                    name: "observation",
                    type: "textarea",
                    label: "observationcard.detail.observation",
                    options: [],
                    formControl: new FormControl({
                        value: data.observation,
                        disabled: true
                    })
                },
                {
                    name: "action",
                    type: "textarea",
                    label: "observationcard.detail.action",
                    options: [],
                    formControl: new FormControl({
                        value: data.action,
                        disabled: true
                    })
                },
                {
                    name: "observation_reference_ids",
                    type: "multiSelect",
                    label: "observationcard.detail.reference",
                    options: this.referencesOption,
                    formControl: new FormControl({
                        value: data.ref,
                        disabled: true
                    })
                }
            ],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        });
    }

    async openEditDialog(data: Observation) {
        /* this.isDisabled = true;
        this.isLoading = true;
        const data = await this.getObservation(id);
        this.isDisabled = false;
        this.isLoading = false;*/
        if ((data.user === null || data.user === undefined) && data.status?.description === "INSERITA") {
            await this.setInProgress(data);
            data.status!.description = "PRESA IN CARICO";
        }
        const dialogFields: DialogData = {
            title: "observationcard.detail.title",
            headerText: "Card N° #" + data.cardNumber!.toString().padStart(8, "0") + " - " + data.status?.description,
            visibleButtonClose: true,
            uuid: data.id,
            fields: [
                {
                    name: "first_name",
                    type: "text",
                    label: "observationcard.detail.firstname",
                    options: [],
                    formControl: new FormControl({
                        value: data.firstName,
                        disabled: false
                    })
                },
                {
                    name: "last_name",
                    type: "text",
                    label: "observationcard.detail.lastname",
                    options: [],
                    formControl: new FormControl({
                        value: data.lastName,
                        disabled: false
                    })
                },
                {
                    name: "role",
                    type: "text",
                    label: "observationcard.detail.role",
                    options: [],
                    formControl: new FormControl({
                        value: data.role,
                        disabled: false
                    })
                },
                {
                    name: "insert_date",
                    type: "datetime",
                    label: "observationcard.detail.insertdate",
                    options: [],
                    formControl: new FormControl({
                        value: data.insertDate,
                        disabled: false
                    })
                },
                {
                    name: "company",
                    type: "text",
                    label: "observationcard.detail.company",
                    options: [],
                    formControl: new FormControl({
                        value: data.company,
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "location",
                    type: "text",
                    label: "observationcard.detail.location",
                    options: [],
                    formControl: new FormControl({
                        value: data.location,
                        disabled: false
                    })
                },
                {
                    name: "activity",
                    type: "text",
                    label: "observationcard.detail.activity",
                    options: [],
                    formControl: new FormControl({
                        value: data.activity,
                        disabled: false
                    })
                },
                {
                    name: "activity_type_id",
                    type: "select",
                    label: "observationcard.detail.type",
                    options: this.activityOption,
                    formControl: new FormControl({
                        value: data.activityType.id,
                        disabled: false
                    })
                },
                {
                    name: "project_id",
                    type: "selectInput",
                    label: "observationcard.detail.project",
                    options: this.projectOption,
                    formControl: new FormControl({
                        value: data.project.id,
                        disabled: false
                    })
                },
                {
                    name: "observation",
                    type: "textarea",
                    label: "observationcard.detail.observation",
                    options: [],
                    formControl: new FormControl({
                        value: data.observation,
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "action",
                    type: "textarea",
                    label: "observationcard.detail.action",
                    options: [],
                    formControl: new FormControl({
                        value: data.action,
                        disabled: false
                    })
                },
                {
                    name: "observation_reference_ids",
                    type: "multiSelect",
                    label: "observationcard.detail.reference",
                    options: this.referencesOption,
                    formControl: new FormControl({
                        value: data.ref,
                        disabled: false
                    }, [
                        Validators.required
                    ])
                }
            ],
            buttons: [
                {
                    label: "button.save",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        }).afterClosed().subscribe((response) => {
            if (response) {
                this.observationService.updateObservation(response.uuid, response.dataToSave).subscribe(() => {
                    this.resetPageEventEmitter.emit();
                    this.snackBar.open(this.translate.instant("observationcard.detail.success")
                        .replace("cardobs", data.cardNumber), undefined, {
                        duration: 3000,
                        panelClass: "app-notification-success"
                    });
                });
            }
        });
    }

    openDeleteDialog(data: Observation) {
        const dialogFields: DialogData = {
            title: "observationcard.delete.title",
            headerText: "observationcard.delete.headertext",
            fields: [],
            buttons: [
                {
                    label: "button.cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "button.delete",
                    type: "submit",
                    icon: "delete",
                    color: "accent"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {

                    this.observationService.deleteObservation(data.id).subscribe(() => {
                        this.resetPageEventEmitter.emit();
                        this.snackBar.open(this.translate.instant("observationcard.delete.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });

                }
            });
    }

    onFilterUpdate(searchString: string): void {
        this.currentFilterSearchString = searchString;
        this.resetPageEventEmitter.emit();
    }

    async extractPageNumberFromLink(link: string): Promise<number | null> {
        const match = link.match(/page=(\d+)/);
        return match ? parseInt(match[1], 10) : null;
    }

    async downloadCsv() {
        this.isDownloading = true;
        await this.fetchAllData();
        this.isDownloading = false;
    }

    extractDates(input: string): [string, string] | null {
        const dateRegex = /\b(\d{4}-\d{2}-\d{2})\b/g;
        const matches = input.match(dateRegex);

        if (matches && matches.length === 2) {
            return [
                matches[0], matches[1]
            ];
        } else {
            return null;
        }
    }

    calculateDateDifference(date1: string, date2: string): number {
        const date1Obj = new Date(date1);
        const date2Obj = new Date(date2);

        const diffInMs = Math.abs(date2Obj.getTime() - date1Obj.getTime());
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);

        return diffInDays;
    }

    async fetchAllData(): Promise<void> {
        const languages = await this.getTraslations();
        const lang = languages.content.find((language) => language.code === this.translate.currentLang);
        const dates = this.extractDates(this.currentFilterSearchString);

        if (dates) {
            const diffInDays = this.calculateDateDifference(dates[0], dates[1]);
            if (diffInDays <= 31) {
                const result = await lastValueFrom(this.observationService.
                    getAllObservations(this.currentFilterSearchString, this.sortTable, lang?.id, this.projects));
                this.exportService.downloadCSV(result.content);
            } else {
                this.snackBar.open(this.translate.instant("message.export.limit.warning"), undefined, {
                    duration: 3000,
                    panelClass: "app-notification-success"
                });
            }
        }
    }

    manageVisibilityExport(filterString: string): boolean {

        if (this.tableData.length === 0) {
            return false;
        }
        const regexPattern = /insertDate](.*?),/;
        const matchResult = filterString.match(regexPattern);
        const insertDateValue = matchResult ? matchResult[1] : null;

        if (insertDateValue) {
            const dateArray = insertDateValue.split(" ");
            const startDate = moment(dateArray[0], "YYYY-MM-DD");
            const endDate = moment(dateArray[1], "YYYY-MM-DD");

            const differenceInDays = endDate.diff(startDate, "days");
            if (differenceInDays > (365 * 2)) {
                return false;
            }
        } else {
            return false;
        }
        return true;
    }
}
