import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class LoadingOverlayService {

    private isLoading = new BehaviorSubject<boolean>(false);

    setLoading(isLoading: boolean) {
        this.isLoading.next(isLoading);
    }

    getLoading() {
        return this.isLoading.asObservable();
    }

}