<div class="homepage full-page">
  <main class="homepage__content content-margin">
    <div class="homepage__row homepage__row--header">
      <a>
        <img
          src="../../../../assets/images/bonatti-logo-text.jpg"
          class="left_logo-image"
          alt="Left logo"
        />
      </a>
      <div class="pull-right">
        <div class="hidden-xs"></div>
      </div>
    </div>
    <div
      class="homepage__row homepage__row--login homepage__row--gray no-padding"
    >
      <div class="visible-xs"></div>
      <div class="col-xs-12 col-sm-6 col-sm-offset-3">
        <form [formGroup]="loginForm">
          <div>
            <mat-form-field class="full-width">
              <mat-label>Email</mat-label>
              <input
                type="email"
                matInput
                formControlName="email"
                [placeholder]="'login.form.email.placeholder' | translate"
              />
              <mat-error
                *ngIf="
                  loginForm.controls['email'].invalid &&
                  loginForm.controls['email'].touched
                "
              >
                {{ "login.form.email.error" | translate }}
              </mat-error>
            </mat-form-field>

            <mat-form-field class="full-width">
              <mat-label>Password</mat-label>
              <input
                type="password"
                matInput
                formControlName="password"
                [placeholder]="'login.form.password.placeholder' | translate"
              />
              <mat-error
                *ngIf="
                  loginForm.controls['password'].invalid &&
                  loginForm.controls['password'].touched
                "
              >
              </mat-error>
            </mat-form-field>
            <div class="py-3">
              <input
                type="submit"
                class="forma-button forma-button--black btn btn-default"
                (click)="onSubmit()"
                (keyup.enter)="onSubmit()"
                [disabled]="loginForm.invalid"
                value="{{ 'login.form.submit' | translate }}"
              />

              <!--
              <button
                mat-raised-button
                color="primary"
                (click)="onSubmit()"
                (keyup.enter)="onSubmit()"
                [disabled]="loginForm.invalid"
              >
                {{ "login.form.submit" | translate }}
              </button>-->
              <div class="text-danger" *ngIf="loginError">
                {{ "login.form.credentials.error" | translate }}
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="homepage__row">
      <br />
      <div class="collapse homepage__collapsed-text js-collapsed-text">
        <app-version></app-version>
      </div>
    </div>
  </main>
</div>
