import { AutoMap } from "@automapper/classes";
import { Entity } from "../base/entity.model";
import { Reference } from "../reference/reference.model";
import { Observation } from "../observation/observation.model";

export class ObservationReference extends Entity {

    @AutoMap()
    public reference!: Reference;

    @AutoMap()
    public observation!: Observation;

}