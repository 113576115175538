import {
    CamelCaseNamingConvention,
    MappingProfile,
    SnakeCaseNamingConvention,
    createMap,
    namingConventions
} from "@automapper/core";
import { Token } from "./token.model";
import { TokenDto } from "../../dtos/login/response/token.dto";

export const tokenProfile: MappingProfile = (mapper) => {
    createMap(
        mapper,
        TokenDto,
        Token,
        namingConventions({
            source: new SnakeCaseNamingConvention(),
            destination: new CamelCaseNamingConvention()
        })
    );

    createMap(
        mapper,
        Token,
        Token
    );
};