import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { ProjectCreateDto } from "src/app/common/dtos/project/project-create.dto";
import { ProjectUpdateDto } from "src/app/common/dtos/project/project-update.dto";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { Project } from "src/app/common/models/project/project.model";
import { ProjectManager } from "../managers/project.manager";

@Injectable({
    providedIn: "root"
})
export class ProjectService {

    constructor(
        private projectManager: ProjectManager
    ) {}

    public getProjects(searchString?: string, page?: number, size?: number, sortBy?:string)
        : Observable<ProjectResponseDto> {
        return this.projectManager.getProjects(searchString, page, size, sortBy);
    }

    public getAllProjects()
    : Observable<ProjectResponseDto> {
        return this.projectManager.getAllProjects();
    }

    public createProject(project: ProjectCreateDto): Observable<Project | undefined> {
        if(project == null) return of(undefined);

        return this.projectManager.createProject(project);
    }

    public updateProject(id: string, project: ProjectUpdateDto): Observable<Project | undefined> {
        if(project == null) return of(undefined);

        return this.projectManager.updateProject(id, project);
    }

    public deleteProject(id: string): Observable<void> {
        return this.projectManager.deleteProject(id);
    }
}