import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatSelect } from "@angular/material/select";
import { Field } from "../filter/field";
import { Observable, map, of, startWith } from "rxjs";
import { Option } from "../filter/option";

@Component({
    selector: "app-select-filter",
    templateUrl: "./select-filter.component.html",
    styleUrls: [
        "./select-filter.component.scss"
    ]
})
export class SelectFilterComponent implements OnInit {

    @ViewChild(MatSelect) selectComponent!: MatSelect;

    @Input() field!: Field;

    filteredOptions!: Observable<Option[]>;

    @Input() disabled = false;

    ngOnInit(): void {
        this.filteredOptions = of(this.field.options);
        this.filteredOptions = this.field.formControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filter(value || ""))
        );
    }

    private _filter(value: string | Option): Option[] {
        const filterValue = typeof value === "string" ? value.toLowerCase() : (value as Option).key.toLowerCase();

        return this.field.options.filter((option) => option.key.toLowerCase().includes(filterValue));
    }

    openSelect(selectElement: MatSelect) {
        selectElement.open();
    }

    clearSelection(field: Field) {
        field.formControl.setValue("");
    }

    checkDisabled(field: Field) {
        if(field.mutuallyExcludes) {
            const fieldToExclude = this.field;
            switch(fieldToExclude?.fieldType) {
                case("autocomplete"): {
                    if(fieldToExclude.selectedFilter) {
                        field.formControl.disable();
                        return true;
                    } else {
                        field.formControl.enable();
                        return false;
                    }
                }
                default:
                {
                    if(fieldToExclude?.formControl.getRawValue()) {
                        field.formControl.disable();
                        return true;
                    } else {
                        field.formControl.enable();
                        return false;
                    }
                }
            }
        }

        return false;
    }

    clearSelectSelection(): void {
        this.selectComponent.options.forEach((option) => {
            option.deselect();
        });
        this.field.formControl.setValue("");
        this.field.selectedFilter = "";
    }
}
