import { Component, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";

@Component({
    selector: "app-dialog-message",
    styleUrls: [
        "./dialog-message.component.scss"
    ],
    templateUrl: "./dialog-message.component.html"
})

export class DialogMessageComponent {

    constructor(
        public dialogRef: MatDialogRef<DialogMessageComponent>,
        @Inject(MAT_DIALOG_DATA) public data: {msg: string}
    ) { }

    onNoClick(): void {
        this.dialogRef.close();
    }
}
