import { Injectable } from "@angular/core";
import { Token } from "../../../common/models/token/token.model";
import { LocalStorageRepositoryInterface } from "./local-storage.interface";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { Observable, map } from "rxjs";
import { LoginDto } from "src/app/common/dtos/login/body/login.dto";
import { TokenDto } from "src/app/common/dtos/login/response/token.dto";
import { Repository } from "../base/remote.repository";
import { API_URL } from "../../properties/shared.properties";

@Injectable()
export class LocalStorageRepository extends Repository implements LocalStorageRepositoryInterface {

    public saveToken(token: Token | undefined | null): void {
        localStorage.setItem("access_token", JSON.stringify(token));
    }

    public loadToken(): Token | undefined | null {
        const obj = localStorage.getItem("access_token");
        if(obj == null) {
            return obj;
        }
        const token: Token = JSON.parse(obj);
        return mapper.map(token, Token, Token);
    }

    public deleteToken(): void {
        localStorage.removeItem("access_token");
        localStorage.removeItem("username");
        localStorage.removeItem("role");
    }

    public login(body: LoginDto): Observable<Token> {
        return this.http.post<TokenDto>(API_URL + "/auth/login", body).pipe(
            map((token) => mapper.map(token, TokenDto, Token))
        );
    }

    public saveUsername(username: string | undefined | null): void {
        localStorage.setItem("username", JSON.stringify(username));
    }

    public loadUsername(): string | undefined | null {
        return localStorage.getItem("username");
    }

    public saveRole(role: string | undefined | null): void {
        localStorage.setItem("role", JSON.stringify(role));
    }
}
