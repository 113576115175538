import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ReferenceClassificationsResponseDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.response";
import { ReferenceClassificationManager } from "../managers/reference-classification.manager";
import { ReferenceClassificationsDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.dto";

@Injectable({
    providedIn: "root"
})
export class ReferenceClassificationService {

    constructor(
        private referenceClassificationManager: ReferenceClassificationManager
    ) {}

    public getReferenceClassifications(searchString?: string, page?: number, size?: number):
    Observable<ReferenceClassificationsResponseDto> {
        return this.referenceClassificationManager.getReferenceClassifications(searchString, page, size);
    }

    public getReferenceClassification(id: string): Observable<ReferenceClassificationsDto> {
        return this.referenceClassificationManager.getReferenceClassification(id);
    }
}