import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { HttpClient, HttpBackend } from "@angular/common/http";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { SharedModule } from "./shared/shared.module";
import { FrontendModule } from "./ui/frontend/frontend.module";
import { AppRoutingModule } from "./app-routing.module";
import { NgbModalModule } from "@ng-bootstrap/ng-bootstrap";
import { UserModule } from "./shared/services/user/user.module";

@NgModule({
    declarations: [
        AppComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "it" }
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        SharedModule,
        FrontendModule,
        TranslateModule.forRoot({
            defaultLanguage: "en",
            loader: {
                provide: TranslateLoader,
                useFactory: (http: HttpBackend) => new TranslateHttpLoader(new HttpClient(http)),
                deps: [
                    HttpBackend
                ]
            }
        }),
        NgbModalModule,
        UserModule.forRoot()
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {}

