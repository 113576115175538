import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from "@angular/core";
import moment from "moment";
import { AutoCompleteFilterComponent } from "../auto-complete-filter/auto-complete-filter.component";
import { Field } from "./field";
import { MatSelect } from "@angular/material/select";
import { SelectFilterComponent } from "../select-filter/select-filter.component";
import { FormControl, FormGroup } from "@angular/forms";

@Component({
    selector: "app-filter",
    templateUrl: "./filter.component.html",
    styleUrls: [
        "./filter.component.scss"
    ]
})
export class FilterComponent implements OnInit {

    @Input() fields!: Field[];

    @Output() applyClicked = new EventEmitter();

    @Input() localFilters = true;

    @Input() filterOnCreate = false;

    @ViewChildren(AutoCompleteFilterComponent) autocompleteComponents!: QueryList<AutoCompleteFilterComponent>;

    @ViewChildren(SelectFilterComponent) selectComponents!:QueryList<SelectFilterComponent>;

    range = new FormGroup({
        start: new FormControl<Date | null>(null),
        end: new FormControl<Date | null>(null)
    });

    ngOnInit(): void {
        if(this.filterOnCreate) {
            this.onApplyClicked();
        }
    }

    onApplyClicked(): void {

        if(this.localFilters) {
            this.applyLocalFilters();
        } else {
            this.applyRemoteFilters();
        }
    }

    applyRemoteFilters() {

        let searchString = "";
        this.fields.forEach((field: Field) => {
            switch(field.fieldType) {
                case("autocomplete"): {
                    if(field.selectedFilter) {
                        searchString = searchString.concat(field.field,
                            field.remoteOperator || "=", field.selectedFilter, ",");
                    }
                    break;
                }
                case("date"): {
                    if(field.formControl.getRawValue()) {
                        searchString = searchString.concat(field.field, field.remoteOperator || "=",
                            moment(field.formControl.getRawValue() as Date).format("YYYY-MM-DD"), ",");
                    }
                    break;
                }
                case("rangedate"): {

                    if(this.range.get("start")?.value != null && this.range.get("end")?.value != null) {
                        searchString = searchString.concat(field.field, field.remoteOperator || "]",
                            moment(this.range.get("start")?.value as Date).format("YYYY-MM-DD"), " ",
                            moment(this.range.get("end")?.value as Date).format("YYYY-MM-DD"), ",");
                    }
                    break;
                }
                case("text"): {
                    if(field.formControl.getRawValue()) {
                        searchString = searchString.concat(field.field, field.remoteOperator || ":",
                            field.formControl.getRawValue(), ",");
                    }
                    break;
                }
                default: {

                    if(field.formControl.getRawValue()) {
                        searchString = searchString.concat(field.field, field.remoteOperator || "=",
                            field.formControl.getRawValue(), ",");
                    }
                    break;
                }
            }
        });
        this.applyClicked.emit(searchString);
    }

    applyLocalFilters() {

        const filterObject: Map<string, {
            customValidation?: (filterValue: unknown, dataValue: unknown) => boolean,
            value: string
        }> = new Map<string, {
            customValidation?: (filterValue: unknown, dataValue: unknown) => boolean,
            value: string
        }>();

        this.fields.forEach((field: Field) => {
            // Ignore if the field filter is already set
            // Used for filters which may refer to the same column (e.g. Users and Service Supervisors)
            if(!filterObject.get(field.field)?.value) {
                switch(field.fieldType) {
                    case("autocomplete"): {
                        filterObject.set(field.field, {
                            customValidation: field.customValidation,
                            value: field.selectedFilter.toLowerCase()
                        });
                        break;
                    }
                    case("date"): {
                        filterObject.set(field.field, {
                            customValidation: field.customValidation,
                            value: (field.formControl.getRawValue() as Date).toString()
                        });
                        break;
                    }
                    case("number"): {
                        filterObject.set(field.field, {
                            customValidation: field.customValidation,
                            value: field.formControl.getRawValue()
                        });
                        break;
                    }
                    case("checkbox"): {
                        filterObject.set(field.field, {
                            customValidation: field.customValidation,
                            value: field.formControl.getRawValue()
                        });
                        break;
                    }
                    default: {
                        filterObject.set(field.field, {
                            customValidation: field.customValidation,
                            value: field.formControl.getRawValue().toLowerCase()
                        });
                        break;
                    }
                }
            }
        });

        this.applyClicked.emit(filterObject);
    }

    onClearClicked(): void {
        this.fields.forEach((field) => {
            if(field.default) {
                field.formControl.setValue(field.default);
            } else {
                field.formControl.setValue("");
            }
        });

        this.autocompleteComponents.forEach((autocompleteComponent) => {
            autocompleteComponent.clearAutocompleteSelection();
        });

        this.selectComponents.forEach((selectComponent) => {
            selectComponent.clearSelectSelection();
        });

        this.range.reset();

        this.onApplyClicked();
    }

    onRefreshClicked(): void {
        this.onApplyClicked();
    }

    checkDisabled(field: Field) {
        if(field.mutuallyExcludes) {
            const fieldToExclude = this.fields.find((f) => f.id === field.mutuallyExcludes);
            switch(fieldToExclude?.fieldType) {
                case("autocomplete"): {
                    if(fieldToExclude.selectedFilter) {
                        field.formControl.disable();
                        return true;
                    } else {
                        field.formControl.enable();
                        return false;
                    }
                }
                default:
                {
                    if(fieldToExclude?.formControl.getRawValue()) {
                        field.formControl.disable();
                        return true;
                    } else {
                        field.formControl.enable();
                        return false;
                    }
                }
            }
        }

        return false;
    }

    clearSelection(field: Field) {
        field.formControl.setValue("");
    }

    openSelect(selectElement: MatSelect) {
        selectElement.open();
    }
}