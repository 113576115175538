import { AutoMap } from "@automapper/classes";
import { ReferenceClassifications } from "../../models/reference-classifications/reference-classifications.model";

export class ReferenceDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public summary!: string;

    @AutoMap()
    public "reference_classifications"!: ReferenceClassifications;

    @AutoMap()
    public pictogram!: string;

}