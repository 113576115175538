import { AutoMap } from "@automapper/classes";
import { Model } from "./base.model";

export abstract class Entity extends Model {
    @AutoMap()
    public readonly id!: string;

    public override equals(obj: unknown): boolean {
        if(obj == null) return false;
        if(!(obj instanceof Entity)) return false;
        return this.id === obj.id;
    }
}