import { Component, OnInit, ViewChild } from "@angular/core";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Observable, lastValueFrom, map, shareReplay } from "rxjs";
import { MatSidenav } from "@angular/material/sidenav";
import { UserService } from "src/app/shared/services/user.service";
import { AuthService } from "src/app/shared/services/user/auth.service";
import { UserDto } from "src/app/common/dtos/user/user.dto";

@Component({
    selector: "app-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: [
        "./sidebar.component.scss"
    ]
})
export class SidebarComponent implements OnInit {

    @ViewChild(MatSidenav) sidenav!: MatSidenav;
    username!: string | undefined;
    user!: UserDto | undefined;
    isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset)
        .pipe(
            map((result) => result.matches),
            shareReplay()
        );

    menuItems = [
        { title:"observationcard.title", icon:"credit_card", routerLink:"observations" },
        // { title:"users.title", icon:"group", routerLink:"users" },
        { title:"projects.title", icon:"apps", routerLink:"projects" }
        // { title:"references.title", icon:"list", routerLink:"references" }
    ];

    menuItemsAdmin = [
        { title:"observationcard.title", icon:"credit_card", routerLink:"observations" },
        { title:"users.title", icon:"group", routerLink:"users" },
        { title:"projects.title", icon:"apps", routerLink:"projects" },
        { title:"references.title", icon:"list", routerLink:"references" }
    ];

    constructor(
        private breakpointObserver: BreakpointObserver,
        private authService: AuthService,
        private userService: UserService
    ) {
    }

    async ngOnInit(): Promise<void> {
        this.username = this.authService.getUsername()?.replace(/"|\u0027/g, "");
        const result = await lastValueFrom(this.userService.getUsers("username=" + this.username));
        this.user = result.data[0];
    }

    getMenuItemsBasedOnRole() {
        return this.user?.role === "ADMIN" ? this.menuItemsAdmin : this.menuItems;
    }

}
