import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { UserCreateDto } from "src/app/common/dtos/user/user-create.dto";
import { UserUpdatePasswordDto } from "src/app/common/dtos/user/user-update-password.dto";
import { UserUpdateDto } from "src/app/common/dtos/user/user-update.dto";
import { UserResponseDto } from "src/app/common/dtos/user/user.response";
import { User } from "src/app/common/models/user/user.model";

@Injectable({
    providedIn : "root"
})
export abstract class UsersRepositoryInterface {

    public abstract getUsers(searchString?: string, page?: number, size?: number, sortBy?: string)
    : Observable<UserResponseDto>;

    public abstract createUser(user: UserCreateDto): Observable<User>;

    public abstract updateUser(id: string, user: UserUpdateDto): Observable<User>;

    public abstract deleteUser(id: string): Observable<void>;

    public abstract getUser(id: string)
    : Observable<User>;

    public abstract updateUserPassword(id: string, user: UserUpdatePasswordDto): Observable<User>;

    public abstract resetPassword(username: string): Observable<User> ;
}