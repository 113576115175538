import { Component, Input, OnInit, ViewChild } from "@angular/core";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { MatOption } from "@angular/material/core";
import { map, Observable, of, startWith } from "rxjs";
import { Field } from "../filter/field";
import { Option } from "../filter/option";

@Component({
    selector: "app-auto-complete-filter",
    templateUrl: "./auto-complete-filter.component.html",
    styleUrls: [
        "./auto-complete-filter.component.scss"
    ]
})
export class AutoCompleteFilterComponent implements OnInit {

    @ViewChild(MatAutocomplete) autocompleteComponent!: MatAutocomplete;

    @Input() field!: Field;

    filteredOptions!: Observable<Option[]>;

    @Input() disabled = false;

    ngOnInit(): void {
        this.filteredOptions = of(this.field.options);
        this.filteredOptions = this.field.formControl.valueChanges.pipe(
            startWith(""),
            map((value) => this._filter(value || ""))
        );
    }

    private _filter(value: string | Option): Option[] {
        const filterValue = typeof value === "string" ? value.toLowerCase() : (value as Option).key.toLowerCase();

        return this.field.options.filter((option) => option.key.toLowerCase().includes(filterValue));
    }

    selectOption(option: MatOption): void {
        // If it was already selected, deselect and clear the field
        if(this.field.selectedFilter === (option.value as Option).value) {
            this.clearAutocompleteSelection();
        } else {
            this.field.selectedFilter = (option.value as Option).value;
        }
    }

    clearAutocompleteSelection(): void {
        this.autocompleteComponent.options.forEach((option) => {
            option.deselect();
        });
        this.field.formControl.setValue("");
        this.field.selectedFilter = "";
    }

    displayFunction(option: Option): string {
        return option.key;
    }
}
