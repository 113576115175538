<div class="row mx-1 mt-3">
  <div class="col-8">
    <h4 class="pageTitle">{{ "references.title" | translate }}</h4>
  </div>
  <div class="col-4" style="text-align: end">
    <button
      mat-raised-button
      color="primary"
      style="max-width: 200px"
      (click)="openCreateDialog()"
    >
      <mat-icon>bookmark_add</mat-icon>
      {{ "button.create" | translate }}
    </button>
  </div>
  <hr />
</div>
<app-filter
  [localFilters]="false"
  [fields]="filters"
  (applyClicked)="onFilterUpdate($event)"
></app-filter>
<app-table
  [filters]="filtersData"
  [columns]="tableColumns"
  [dataSource]="tableData"
  [rowActionIcons]="['edit']"
  (rowAction)="onActionFromTableEmitted($event)"
  (page)="requestPage($event)"
  [totalElements]="requestTotalElements"
  [resetPageEvent]="resetPageEventEmitter"
  (columnSort)="onSortTable($event)"
>
</app-table>
