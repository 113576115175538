import { Injectable } from "@angular/core";
import { ObservationsRepositoryInterface } from "../repositories/observations/observations.interface";
import { Observable } from "rxjs";
import { ObservationResponseDto } from "src/app/common/dtos/observation/observation.response";
import { ObservationUpdateDto } from "src/app/common/dtos/observation/observation-update.dto";
import { Observation } from "src/app/common/models/observation/observation.model";
import { ObservationCreateDto } from "src/app/common/dtos/observation/observation-create.dto";
import { ObservationStatusResponseDto } from "src/app/common/dtos/observation-status/observation-status.response";
import { ObservationStatus } from "src/app/common/models/observation-status/observation-status.model";
import { ObservationMediaResponseDto } from "src/app/common/dtos/observation-media/observation-media.response";
import { ObservationMedia } from "src/app/common/models/observation-media/observation-media.model";
import { ObservationResponseAllDto } from "src/app/common/dtos/observation/observation.response.all";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { UserProject } from "src/app/common/models/user-project/user-project.model";
import { UserProjectResponseDto } from "src/app/common/dtos/user-project/user-project.response";
import { ObservationDto } from "src/app/common/dtos/observation/observation.dto";

@Injectable({
    providedIn:"root"
})
export class ObservationManager {

    constructor(
        private observationRepository: ObservationsRepositoryInterface
    ) {}

    public getObservations(searchString?: string, page?: number, size?: number, sortBy?: string,
        lang?: string, projects?: string)
        : Observable<ObservationResponseDto> {
        return this.observationRepository.getObservations(searchString, page, size, sortBy, lang, projects);
    }

    public getAllObservations(searchString?: string, sortBy?: string, lang?: string, projects?: string)
        : Observable<ObservationResponseAllDto> {
        return this.observationRepository.getAllObservations(searchString, sortBy, lang, projects);
    }

    public getObservation(id: string, lang?: string)
        : Observable<Observation> {
        return this.observationRepository.getObservation(id, lang);
    }

    public createObservation(observation: ObservationCreateDto):Observable<Observation> {
        return this.observationRepository.createObservation(observation);
    }

    public updateObservation(id: string, observation: ObservationUpdateDto):Observable<Observation> {
        return this.observationRepository.updateObservation(id, observation);
    }

    public deleteObservation(id: string): Observable<void> {
        return this.observationRepository.deleteObservation(id);
    }

    public getObservationStatus(id: string): Observable<ObservationStatusResponseDto> {
        return this.observationRepository.getObservationStatus(id);
    }

    public createObservationStatus(observationId: string,
        statusId: string,
        comment?:string):Observable<ObservationStatus> {
        return this.observationRepository.createObservationStatus(observationId, statusId, comment);
    }

    public getObservationMedia(id: string): Observable<ObservationMediaResponseDto> {
        return this.observationRepository.getObservationMedia(id);
    }

    public createObservationMedia(observationId: string, formData: FormData):
    Observable<ObservationMedia> {
        return this.observationRepository.createObservationMedia(observationId, formData);
    }

    public deleteObservationMedia(id: string): Observable<void> {
        return this.observationRepository.deleteObservationMedia(id);
    }

    public getProjectsByUser(id: string) : Observable<UserProjectResponseDto> {
        return this.observationRepository.getProjectsByUser(id);
    }

    public createUserProject(userId: string, projectId: string):
    Observable<UserProject> {
        return this.observationRepository.createUserProject(userId, projectId);
    }

    public deleteUserProject(id: string): Observable<void> {
        return this.observationRepository.deleteUserProject(id);
    }
}