import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ProjectsRepositoryInterface } from "../repositories/projects/projects.interface";
import { ProjectResponseDto } from "src/app/common/dtos/project/project.response";
import { Project } from "src/app/common/models/project/project.model";
import { ProjectCreateDto } from "src/app/common/dtos/project/project-create.dto";
import { ProjectUpdateDto } from "src/app/common/dtos/project/project-update.dto";

@Injectable({
    providedIn:"root"
})
export class ProjectManager {

    constructor(
        private projectRepository: ProjectsRepositoryInterface
    ) {}

    public getProjects(searchString?: string, page?: number, size?: number, sortBy?: string)
        : Observable<ProjectResponseDto> {
        return this.projectRepository.getProjects(searchString, page, size, sortBy);
    }

    public getAllProjects()
    : Observable<ProjectResponseDto> {
        return this.projectRepository.getAllProjects();
    }

    public createProject(project: ProjectCreateDto):Observable<Project> {
        return this.projectRepository.createProject(project);
    }

    public updateProject(id: string, project: ProjectUpdateDto):Observable<Project> {
        return this.projectRepository.updateProject(id, project);
    }

    public deleteProject(id: string): Observable<void> {
        return this.projectRepository.deleteProject(id);
    }
}