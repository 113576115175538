import { observationStatusProfile } from "./../models/observation-status/observation.profile";
import { addProfile, createMapper } from "@automapper/core";
import { classes } from "@automapper/classes";
import { tokenProfile } from "../models/token/token.profile";
import { userProfile } from "../models/user/user.profile";
import { observationProfile } from "../models/observation/observation.profile";
import { activityTypeProfile } from "../models/activity-type/activity-type.profile";
import { referenceProfile } from "../models/reference/reference.profile";
import { countryProfile } from "../models/country/country.profile";
import { projectProfile } from "../models/project/project.profile";
import { observationReferenceProfile } from "../models/observation-reference/observation-reference.profile";
import { businessUnitProfile } from "../models/business-unit/business-unit.profile";
import { referenceClassificationsProfile } from "../models/reference-classifications/reference-classifications.profile";
import { observationMediaProfile } from "../models/observation-media/observation-media.profile";
import { userProjectProfile } from "../models/user-project/user-project.profile";

export const mapper = createMapper({
    strategyInitializer: classes()
});

addProfile(mapper, tokenProfile);
addProfile(mapper, userProfile);
addProfile(mapper, observationProfile);
addProfile(mapper, activityTypeProfile);
addProfile(mapper, referenceProfile);
addProfile(mapper, countryProfile);
addProfile(mapper, projectProfile);
addProfile(mapper, observationReferenceProfile);
addProfile(mapper, businessUnitProfile);
addProfile(mapper, observationStatusProfile);
addProfile(mapper, referenceClassificationsProfile);
addProfile(mapper, observationMediaProfile);
addProfile(mapper, userProjectProfile);