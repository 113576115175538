import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { Observable, map } from "rxjs";
import { BusinessUnitDto } from "src/app/common/dtos/business-unit/business-unit.dto";
import { BusinessUnitResponseDto } from "src/app/common/dtos/business-unit/business-unit.response";
import { BusinessUnitsRepositoryInterface } from "./business-units.interface";

@Injectable()
export class BusinessUnitsRepository extends Repository implements BusinessUnitsRepositoryInterface {

    public getBusinessUnits(): Observable<BusinessUnitResponseDto> {
        return this.http.get<BusinessUnitDto[]>("/business-unit").pipe(map((businessUnitDto: BusinessUnitDto[]) => {
            const dto = new BusinessUnitResponseDto();
            dto.content = businessUnitDto;
            return dto;
        }));
    }
}