import { LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TableComponent } from "./table.component";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatTableModule } from "@angular/material/table";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatIconModule } from "@angular/material/icon";
import { FilterModule } from "./filter/filter.module";
import { AutoCompleteFilterModule } from "./auto-complete-filter/auto-complete-filter.module";
import { SelectFilterModule } from "./select-filter/select-filter.module";
import { TranslateModule } from "@ngx-translate/core";
import { MatBadgeModule } from "@angular/material/badge";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
    declarations: [
        TableComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        MatTableModule,
        MatPaginatorModule,
        MatIconModule,
        FilterModule,
        AutoCompleteFilterModule,
        SelectFilterModule,
        TranslateModule,
        MatBadgeModule,
        MatTooltipModule
    ],
    exports: [
        TableComponent
    ],
    providers: [
        { provide: LOCALE_ID, useValue: "it-IT" }
    ]
})
export class TableModule { }
