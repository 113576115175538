<div class="container">
  <div>
    <div class="divTitle">
      <h2 class="title">{{ data.title | translate }}</h2>
    </div>
    <div class="divIcon">
      <button
        *ngIf="data.visibleButtonClose"
        mat-icon-button
        (click)="buttonClicked('back')"
        color="basic"
        style="min-width: auto"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>

  <p class="headerText" *ngIf="data.headerText">
    {{ data.headerText | translate }}
  </p>
  <!--<hr class="headerDivider" />-->
  <div class="scrollable-div">
    <ng-container
      class="item"
      *ngFor="let field of data.fields"
      [ngSwitch]="field.type"
    >
      <div *ngSwitchCase="'file'" class="formField">
        <div>
          <mat-toolbar>
            <input matInput [value]="fileName" />
          </mat-toolbar>

          <input
            type="file"
            id="fileInput"
            (change)="selectFile($event)"
            name="fileInput"
            [disabled]="disableUpload"
            accept="image/*"
          />
        </div>

        <button
          mat-raised-button
          [disabled]="!currentFile"
          cdkFocusInitial
          color="primary"
          (click)="upload(data)"
        >
          Upload
        </button>

        <mat-card class="upload-file" style="border-style: solid">
          <mat-card-header>
            <mat-card-title>List of Files</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-list role="list">
              <mat-spinner *ngIf="isLoading" diameter="50"></mat-spinner>
              <mat-list-item role="listitem" *ngFor="let file of fileInfos">
                <div class="list-item-container">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="downloadFile(file)"
                    class="action-button"
                  >
                    <mat-icon>download</mat-icon>
                  </button>
                  <div class="label-container">
                    {{ file.name }}
                  </div>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteFile(file.id, file.name)"
                    class="action-button"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>

      <div *ngSwitchCase="'file2'" class="formField">
        <div
          style="
            display: flex;
            justify-content: space-between;
            align-items: center;
          "
        >
          <input type="file" class="input-file" (change)="selectFile($event)" />
          <button
            mat-raised-button
            [disabled]="!currentFile"
            cdkFocusInitial
            color="primary"
            (click)="upload(data)"
          >
            Upload
          </button>
        </div>

        <mat-card class="upload-file" style="border-style: solid">
          <mat-card-header>
            <mat-card-title>List of Files</mat-card-title>
          </mat-card-header>
          <mat-card-content>
            <mat-list role="list">
              <mat-list-item role="listitem" *ngFor="let file of fileInfos">
                <div class="list-item-container">
                  <button
                    mat-icon-button
                    color="primary"
                    (click)="downloadFile(file)"
                    class="action-button"
                  >
                    <mat-icon>download</mat-icon>
                  </button>
                  <div class="label-container">
                    {{ file.name }}
                  </div>
                  <button
                    mat-icon-button
                    color="warn"
                    (click)="deleteFile(file.id, file.name)"
                    class="action-button"
                  >
                    <mat-icon>delete</mat-icon>
                  </button>
                </div>
              </mat-list-item>
            </mat-list>
          </mat-card-content>
        </mat-card>
      </div>

      <div *ngSwitchCase="'image'" class="formField">
        <mat-card>
          <mat-card-content>
            <img mat-card-sm-image [src]="field.formControl.value" />
          </mat-card-content>
        </mat-card>
      </div>

      <ng-container *ngSwitchCase="'list'">
        <mat-label>{{ field.label | translate }}</mat-label>
        <mat-selection-list #proj>
          <mat-list-option
            *ngFor="let opt of projectUserOption; let i = index"
            [value]="opt.value"
            (click)="toggleSelection(i)"
          >
            {{ opt.key }}
          </mat-list-option>
        </mat-selection-list>
      </ng-container>

      <mat-form-field *ngSwitchCase="'text'" class="formField">
        <mat-label>{{ field.label | translate }}</mat-label>
        <input
          matInput
          [readonly]="checkDisabled(field)"
          [formControl]="field.formControl"
          class="fullWidth"
        />
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'textarea'" class="formField">
        <mat-label>{{ field.label | translate }}</mat-label>
        <textarea
          matInput
          [readonly]="checkDisabled(field)"
          [formControl]="field.formControl"
          class="fullWidth"
        ></textarea>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'email'" class="formField">
        <mat-label>{{ field.label | translate }}</mat-label>
        <input
          matInput
          email
          [readonly]="checkDisabled(field)"
          [formControl]="field.formControl"
          class="fullWidth"
        />
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'password'" class="formField">
        <mat-label>{{ field.label | translate }}</mat-label>
        <input
          matInput
          #message
          type="password"
          [readonly]="checkDisabled(field)"
          [formControl]="field.formControl"
          class="fullWidth"
        />
        <mat-hint align="start"
          >La password deve contenere almeno una lettera maiuscola, un numero,
          un carattere speciale.(Min. 8, Max. 20)</mat-hint
        >
        <mat-hint align="end">{{ message.value.length }} / 20</mat-hint>
      </mat-form-field>
      <div
        *ngSwitchCase="'select'"
        style="width: 100%"
        class="containerHorizontal"
      >
        <mat-form-field class="formField itemHorizontal">
          <mat-label>{{ field.label | translate }}</mat-label>
          <input
            matInput
            class="fullWidth"
            *ngIf="field.autocomplete"
            [readonly]="checkDisabled(field)"
            (focus)="openSelect(selectElement)"
            [formControl]="field.autocomplete"
          />
          <mat-select
            #selectElement
            class="fullWidth"
            [disabled]="checkDisabled(field)"
            [formControl]="field.formControl"
            (selectionChange)="field.autocomplete?.setValue('')"
          >
            <mat-option
              *ngFor="let opt of field.filteredOptions || field.options"
              [value]="opt.value"
            >
              {{ opt.key | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span
          class="clickable itemHorizontal"
          *ngIf="field.formControl.getRawValue()"
          (click)="clearSelection(field)"
        >
          <mat-icon>close</mat-icon>
        </span>
      </div>

      <mat-form-field
        *ngSwitchCase="'selectInput'"
        appearance="fill"
        class="formField itemHorizontal"
      >
        <mat-label>{{ field.label | translate }}</mat-label>
        <mat-select
          [formControl]="field.formControl"
          class="fullWidth"
          (openedChange)="onSelectOpened($event)"
        >
          <input
            matInput
            #searchField
            class="search-input"
            (keyup)="filterSelectOptions($event, field)"
            placeholder="Cerca..."
          />
          <mat-option
            *ngFor="let opt of field.filteredOptions || field.options"
            [value]="opt.value"
            >{{ opt.key | translate }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <!--MULTISELECT-->
      <div
        *ngSwitchCase="'multiSelect'"
        style="width: 100%"
        class="containerHorizontal"
      >
        <mat-form-field class="formField itemHorizontal">
          <mat-label>{{ field.label | translate }}</mat-label>
          <input
            matInput
            class="fullWidth"
            *ngIf="field.autocomplete"
            [readonly]="checkDisabled(field)"
            (focus)="openSelect(selectElementMulti)"
            [formControl]="field.autocomplete"
          />
          <mat-select
            #selectElementMulti
            class="fullWidth"
            [disabled]="checkDisabled(field)"
            [formControl]="field.formControl"
            (selectionChange)="field.autocomplete?.setValue('')"
            multiple
          >
            <mat-option
              *ngFor="let opt of field.filteredOptions || field.options"
              [value]="opt.value"
            >
              {{ opt.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <span
          class="clickable itemHorizontal"
          *ngIf="field.formControl.getRawValue()"
          (click)="clearSelection(field)"
        >
          <mat-icon>close</mat-icon>
        </span>
      </div>
      <!--/////////////////////////-->
      <mat-checkbox
        [disabled]="checkDisabled(field)"
        [formControl]="field.formControl"
        *ngSwitchCase="'checkbox'"
        class="formField"
      >
        {{ field.label | translate }}
      </mat-checkbox>
      <mat-form-field *ngSwitchCase="'number'" class="formField">
        <mat-label>{{ field.label | translate }}</mat-label>
        <input
          [readonly]="checkDisabled(field)"
          type="number"
          [min]="field.boundaries?.min ?? null"
          [max]="field.boundaries?.max ?? null"
          matInput
          [formControl]="field.formControl"
          class="fullWidth"
        />
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'date'" class="formField">
        <mat-label>{{ field.label | translate }}</mat-label>
        <input
          [readonly]="checkDisabled(field)"
          [disabled]="checkDisabled(field)"
          [matDatepicker]="datePicker"
          matInput
          [formControl]="field.formControl"
          class="fullWidth"
        />
        <mat-datepicker-toggle
          matIconSuffix
          [for]="datePicker"
        ></mat-datepicker-toggle>
        <mat-datepicker #datePicker></mat-datepicker>
      </mat-form-field>
      <mat-form-field *ngSwitchCase="'datetime'" class="formField">
        <input
          matInput
          [ngxMatDatetimePicker]="datePicker"
          placeholder="Choose a date"
          [formControl]="field.formControl!"
          class="fullWidth"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="$any(datePicker)"
        ></mat-datepicker-toggle>
        <ngx-mat-datetime-picker
          #datePicker
          [showSpinners]="true"
          [showSeconds]="false"
          [stepHour]="1"
          [stepMinute]="1"
        >
        </ngx-mat-datetime-picker>
      </mat-form-field>
    </ng-container>
  </div>
</div>
<div style="display: flex; flex-direction: row; justify-content: center">
  <button
    *ngFor="let button of data.buttons"
    mat-raised-button
    class="dialogButtons"
    [color]="button.color"
    (click)="buttonClicked(button.type)"
  >
    {{ button.label | translate }}
    <mat-icon>{{ button?.icon }}</mat-icon>
  </button>
</div>
