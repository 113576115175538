import { Injectable } from "@angular/core";
import { UserManager } from "../managers/user.manager";
import { Observable, of } from "rxjs";
import { UserResponseDto } from "src/app/common/dtos/user/user.response";
import { UserUpdateDto } from "src/app/common/dtos/user/user-update.dto";
import { User } from "src/app/common/models/user/user.model";
import { UserCreateDto } from "src/app/common/dtos/user/user-create.dto";
import { UserUpdatePasswordDto } from "src/app/common/dtos/user/user-update-password.dto";

@Injectable({
    providedIn: "root"
})
export class UserService {

    constructor(
        private userManager: UserManager
    ) {}

    public getUsers(searchString?: string, page?: number, size?: number, sortBy?: string): Observable<UserResponseDto> {
        return this.userManager.getUsers(searchString, page, size, sortBy);
    }

    public getUser(id: string): Observable<User> {
        return this.userManager.getUser(id);
    }

    public createUser(user: UserCreateDto): Observable<User | undefined> {
        if(user == null) return of(undefined);

        return this.userManager.createUser(user);
    }

    public updateUser(id: string, user: UserUpdateDto): Observable<User | undefined> {
        if(user == null) return of(undefined);

        return this.userManager.updateUser(id, user);
    }

    public deleteUser(id: string): Observable<void> {
        return this.userManager.deleteUser(id);
    }

    public updateUserPassword(id: string, user: UserUpdatePasswordDto): Observable<User | undefined> {
        if(user == null) return of(undefined);

        return this.userManager.updateUserPassword(id, user);
    }

    public resetPassword(username: string): Observable<User | undefined> {
        if(username == null) return of(undefined);

        return this.userManager.resetPassword(username);
    }

}