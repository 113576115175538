/* eslint-disable camelcase */
import { AutoMap } from "@automapper/classes";

export class UserDto {

    @AutoMap()
    public id!: string;

    @AutoMap()
    public first_name!: string;

    @AutoMap()
    public last_name!: string;

    @AutoMap()
    public is_active!: boolean;

    @AutoMap()
    public username!: string;

    @AutoMap()
    public role?: string;

    @AutoMap()
    public company?: string;

}