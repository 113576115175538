import { Component, Input } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-error",
    templateUrl: "./error.component.html",
    styleUrls: [
        "./error.component.scss"
    ]
})
export class ErrorComponent {

    @Input() error?:Error;

    constructor(public activeModal: NgbActiveModal) { }

    onCloseClick(): void {
        this.activeModal.close();
    }
}
