/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Provider } from "@angular/core";
import { Repository } from "../../shared/repositories/base/remote.repository";
import { HttpBackend, HttpClient } from "@angular/common/http";

export type RepositoryFactory = (httpBackend: HttpBackend) => Repository;

export type RepositoryConstructor<R extends Repository> = new (http: HttpClient) => R;

export type HttpBackendConstructor<T extends HttpBackend> =
    new (http: HttpBackend, ...deps: any[]) => T;

export function RepositoryFactoryProvider<R extends Repository, B extends HttpBackend>({
    provide,
    repository,
    backend,
    multi,
    deps = []
}: {
    provide: any,
    repository: RepositoryConstructor<R>,
    backend: HttpBackendConstructor<B>,
    multi?: boolean,
    deps?: any[]
}): Provider {
    return {
        provide,
        multi,
        useFactory: (...deps: any[]) => {
            const http: HttpBackend = deps.splice(0, 1)[0];
            const httpBackend = new backend(http, ...deps);
            const httpClient = new HttpClient(httpBackend);
            return new repository(httpClient);
        },
        deps: [
            HttpBackend,
            ...deps
        ]
    };
}