import { AutoMap } from "@automapper/classes";
import { MetaResponseDto } from "../page/meta.response.";
import { LinksResponseDto } from "../page/links.response";
import { ProjectDto } from "./project.dto";

export class ProjectResponseDto {

    @AutoMap()
    public data!: ProjectDto[];

    @AutoMap()
    public meta?:MetaResponseDto;

    @AutoMap()
    public links?:LinksResponseDto;

    @AutoMap()
    public content?: ProjectDto[];

}