import { Injectable } from "@angular/core";
import { Repository } from "../base/remote.repository";
import { UsersRepositoryInterface } from "./users.interface";
import { Observable, map } from "rxjs";
import { UserResponseDto } from "src/app/common/dtos/user/user.response";
import { HttpParams } from "@angular/common/http";
import { UserDto } from "src/app/common/dtos/user/user.dto";
import { UserUpdateDto } from "src/app/common/dtos/user/user-update.dto";
import { User } from "src/app/common/models/user/user.model";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { UserCreateDto } from "src/app/common/dtos/user/user-create.dto";
import { UserUpdatePasswordDto } from "src/app/common/dtos/user/user-update-password.dto";

@Injectable()
export class UsersRepository extends Repository implements UsersRepositoryInterface {

    public getUsers(searchString?: string, page?: number, size?: number, sortBy?: string): Observable<UserResponseDto> {
        let httpParams: HttpParams = new HttpParams();
        if(page) {
            httpParams = httpParams.set("page", page);
        }
        if(size) {
            httpParams = httpParams.set("limit", size);
        }
        if(searchString) {
            httpParams = httpParams.set("search", "id!:16," + searchString);
        }else{
            httpParams = httpParams.set("search", "id!:16");
        }
        httpParams = httpParams.set("sortBy", sortBy!);

        // pagination simulation
        return this.http.get<UserResponseDto>("/users", { params: httpParams })
            .pipe(map((usersDto: UserResponseDto) => {
                const dto = new UserResponseDto();
                dto.data = usersDto.data;
                dto.meta = usersDto.meta;
                dto.links = usersDto.links;
                return dto;
            }));
    }

    public createUser(user: UserCreateDto): Observable<User> {
        return this.http.post<UserDto>("/users", user).pipe(
            map((createdUser) => mapper.map(createdUser, UserDto, User))
        );
    }

    public updateUser(id: string, user: UserUpdateDto): Observable<User> {
        return this.http.put<UserDto>(`/users/${id}`, user).pipe(
            map((updatedUser) => mapper.map(updatedUser, UserDto, User))
        );
    }

    public deleteUser(id: string): Observable<void> {
        return this.http.delete<void>(`/users/${id}`);
    }

    public getUser(id: string): Observable<User> {
        return this.http.get<UserDto>(`/users/${id}`).pipe(
            map((user) => mapper.map(user, UserDto, User))
        );
    }

    public updateUserPassword(id: string, user: UserUpdatePasswordDto): Observable<User> {
        return this.http.put<UserDto>(`/users/${id}`, user).pipe(
            map((updatedUserPassword) => mapper.map(updatedUserPassword, UserDto, User))
        );
    }

    public resetPassword(username: string): Observable<User> {
        const body = { email: username };
        return this.http.post<UserDto>("/users/reset", body).pipe(
            map((resetPassword) => mapper.map(resetPassword, UserDto, User))
        );
    }

}