import { Component, EventEmitter, OnInit } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTableDataSource } from "@angular/material/table";
import { lastValueFrom } from "rxjs";
import { ReferenceClassificationsDto }
    from "src/app/common/dtos/reference-classifications/reference-classifications.dto";
import { ReferenceDto } from "src/app/common/dtos/reference/reference.dto";
import { mapper } from "src/app/common/mapper/classes.mapper";
import { Reference } from "src/app/common/models/reference/reference.model";
import { DialogData } from "src/app/shared/components/dialog-prototype/dialog-data";
import { DialogPrototypeComponent } from "src/app/shared/components/dialog-prototype/dialog-prototype.component";
import { Column } from "src/app/shared/components/table/column";
import { Field } from "src/app/shared/components/table/filter/field";
import { ReferenceClassificationService } from "src/app/shared/services/reference-classification.service";
import { ReferenceService } from "src/app/shared/services/reference.service";
import { Option } from "./../../../../shared/components/table/filter/option";
import { TranslateService } from "@ngx-translate/core";
import { TranslationResponseDto } from "src/app/common/dtos/translation/translation.response";
import { TranslationService } from "src/app/shared/services/translation.service";

@Component({
    selector: "app-references",
    templateUrl: "./references.component.html",
    styleUrls: [
        "./references.component.scss"
    ]
})
export class ReferencesComponent implements OnInit {

    tableData!: Reference[];
    tableColumns!: Column[];

    filters!: Field[]; // =  this.getFilters();

    filterSource!: MatTableDataSource<unknown>;

    filtersData!: Map<string,
    { customValidation?: (filterValue: unknown, dataValue: unknown) => boolean, value: string }>;

    currentFilterSearchString = "";

    currentPageSize = 5;

    requestTotalElements = 0;

    sortTable = "id:DESC";

    resetPageEventEmitter: EventEmitter<void> = new EventEmitter<void>();

    referenceClassificationsOption!: Option[];

    constructor(
        private referenceService: ReferenceService,
        private referenceClassifications: ReferenceClassificationService,
        public dialog: MatDialog,
        private snackBar: MatSnackBar,
        private translate: TranslateService,
        private translationService: TranslationService
    ) { }

    async ngOnInit() {
        this.tableColumns = this.getColumns();
        this.getReferences(undefined, 0, this.currentPageSize, "referenceClassifications:ASC");
        this.filters = await this.getFilters();
        this.getReferenceClassifications();
    }

    requestPage(page: { page: number, size: number }) {
        this.currentPageSize = page.size;
        this.getReferences(this.currentFilterSearchString, page.page + 1, page.size, "referenceClassifications:ASC");
        this.getReferenceClassificationAsync();
    }

    getColumns(): Column[] {
        return [
            { caption: "references.form.summary", field: "summary", sort:true },
            { caption: "references.form.classification", field: "referenceClassifications.description" }
        ];
    }

    async getReferences(searchString?: string, page?: number, size?: number, sortBy?: string) {
        const languages = await this.getTraslations();
        const lang = languages.content.find((language) => language.code === this.translate.currentLang);
        await this.referenceService.getReferences(searchString, page, size, sortBy, lang?.id).subscribe((result) => {
            this.requestTotalElements = result.meta?.totalItems || 0;
            this.tableData = mapper.mapArray(result?.data, ReferenceDto, Reference);
        });
    }

    async getTraslations(): Promise<TranslationResponseDto> {
        const result = await lastValueFrom(this.translationService.getTranslations());
        return result;
    }

    getReferenceClassifications() :Option[] {
        this.referenceClassifications.getReferenceClassifications().subscribe((result) => {
            const optionList: Option[] =
        result.content.map((countryItem: ReferenceClassificationsDto) => {
            return {
                key: countryItem?.description?.toString(),
                value: countryItem?.id
            };
        });
            this.referenceClassificationsOption = optionList;
            return optionList;
        });
        return this.referenceClassificationsOption;
    }

    async getReferenceClassificationsAsync() {
        const result = await lastValueFrom(this.referenceClassifications.getReferenceClassifications());
        return result?.content.map((referenceClassificationItem: ReferenceClassificationsDto) => {
            return {
                key: referenceClassificationItem?.description?.toString(),
                value: referenceClassificationItem?.id
            };
        });
    }

    async getReferenceClassificationAsync() {
        const result = await lastValueFrom(this.referenceClassifications.getReferenceClassification("1"));
        return {
            key: result?.description?.toString(),
            value: result?.id
        };
    }

    async getFilters(): Promise<Field[]> {
        return [
            {
                id: "summary",
                caption: "references.form.summary",
                fieldType: "text",
                formControl: new FormControl(""),
                field: "summary",
                selectedFilter: "",
                options: []
            },
            {
                id: "referenceClassifications",
                caption: "references.form.classification",
                fieldType: "autocomplete",
                formControl: new FormControl(""),
                field: "referenceClassifications",
                selectedFilter: "",
                options: await this.getReferenceClassificationsAsync()
            }
        ];
    }

    onActionFromTableEmitted(eventData: unknown) {
        const dataEmitted = eventData as { action: string, data: Reference };

        if (dataEmitted.action === "delete") {
            this.openDeleteDialog(dataEmitted.data);
        } else if (dataEmitted.action === "edit") {
            this.openEditDialog(dataEmitted.data);
        }
    }

    onFilterDataSourceReceived(eventData: unknown) {
        const data = eventData as MatTableDataSource<unknown>;
        this.filterSource = data;
    }

    onSortTable(eventData: unknown) {
        const dataEmitted = eventData as { data: string };
        const splitted = this.sortTable.split(":");
        if(splitted[1] === "DESC") {
            this.sortTable = dataEmitted.data + ":ASC";
        }else{
            this.sortTable = dataEmitted.data + ":DESC";
        }

        this.getReferences(this.currentFilterSearchString, 1, this.currentPageSize, this.sortTable);
    }

    openCreateDialog(): void {
        const dialogFields: DialogData = {
            title: "references.button.create",
            visibleButtonClose: true,
            fields: [
                {
                    name: "summary",
                    type: "text",
                    label: "references.detail.description",
                    options: [],
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required
                    ])
                },
                {
                    name: "reference_classifications_id",
                    type: "select",
                    label: "references.detail.classification",
                    options: this.referenceClassificationsOption,
                    formControl: new FormControl({
                        value: "",
                        disabled: false
                    }, [
                        Validators.required
                    ])
                }
            ],
            buttons: [
                {
                    label: "Create",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.referenceService.createReference(response.dataToSave).subscribe(() => {
                        this.resetPageEventEmitter.emit();

                        this.snackBar.open(this.translate.instant("references.create.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });

                }
            });
    }

    openEditDialog(data: Reference) {
        const dialogFields: DialogData = {
            title: "references.detail.title",
            headerText: "",
            uuid: data.id,
            visibleButtonClose: true,
            fields: [
                /* {
                    name: "pictogram",
                    type: "image",
                    label: "references.detail.pictogram",
                    options: [],
                    formControl: new FormControl({
                        value: data.pictogram,
                        disabled: true
                    })
                },*/
                {
                    name: "summary",
                    type: "text",
                    label: "references.detail.description",
                    options: [],
                    formControl: new FormControl({
                        value: data.summary,
                        disabled: false
                    })
                },
                {
                    name: "reference_classifications_id",
                    type: "select",
                    label: "references.detail.classification",
                    options: this.referenceClassificationsOption,
                    formControl: new FormControl({
                        value: data.referenceClassifications.id,
                        disabled: false
                    })
                }
            ],
            buttons: [
                {
                    label: "Save",
                    type: "submit",
                    icon: "save",
                    color: "primary"
                }
            ]
        };
        this.dialog.open(DialogPrototypeComponent, {
            disableClose: true,
            data: dialogFields
        }).afterClosed().subscribe((response) => {
            if (response) {

                this.referenceService.updateReference(response.uuid, response.dataToSave).subscribe(() => {
                    this.resetPageEventEmitter.emit();
                    this.snackBar.open(this.translate.instant("references.detail.success"), undefined, {
                        duration: 3000,
                        panelClass: "app-notification-success"
                    });
                });

            }
        });
    }

    openDeleteDialog(data: Reference) {
        const dialogFields: DialogData = {
            title: "Delete Reference : " + data.summary,
            headerText: "Are you sure you want to delete this reference ?",
            fields: [],
            visibleButtonClose: false,
            buttons: [
                {
                    label: "Cancel",
                    type: "cancel",
                    icon: "close",
                    color: "warn"
                },
                {
                    label: "Delete",
                    type: "submit",
                    icon: "delete",
                    color: "primary"
                }
            ]
        };

        this.dialog.open(DialogPrototypeComponent, { disableClose: true, data: dialogFields })
            .afterClosed().subscribe((response) => {
                if (response) {
                    this.referenceService.deleteReference(data.id).subscribe(() => {
                        this.resetPageEventEmitter.emit();
                        this.snackBar.open(this.translate.instant("references.delete.success"), undefined, {
                            duration: 3000,
                            panelClass: "app-notification-success"
                        });
                    });

                }
            });
    }

    onFilterUpdate(searchString: string): void {
        this.currentFilterSearchString = searchString;
        this.resetPageEventEmitter.emit();
    }
}
