import { Injectable } from "@angular/core";
import { AuthManager } from "../../managers/auth.manager";

@Injectable({
    providedIn: "root"
})
export class TokenService {

    constructor(
        private authManager: AuthManager
    ) { }

}