import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { StatusResponseDto } from "src/app/common/dtos/status/status.response";
import { StatusManager } from "../managers/status.manager";

@Injectable({
    providedIn: "root"
})
export class StatusService {

    constructor(
        private statusManager: StatusManager
    ) {}

    public getStatus(): Observable<StatusResponseDto> {
        return this.statusManager.getStatus();
    }
}