import { NgModule } from "@angular/core";
import { RepositoryFactoryProvider } from "src/app/common/utils/backend.provider";
import { UsersRepositoryInterface } from "./users.interface";
import { UsersRepository } from "./users.repository";
import { UsersHttpBackend } from "./users.backend";
import { LocalStorageRepositoryInterface } from "../local-storage/local-storage.interface";
import { LoadingOverlayService } from "../../components/loading-overlay/services/loading-overlay.service";
import { RouterService } from "../../services/router.service";
import { TokenService } from "../../services/user/token.service";

@NgModule({
    providers: [
        RepositoryFactoryProvider({
            provide: UsersRepositoryInterface,
            repository: UsersRepository,
            backend: UsersHttpBackend,
            deps: [
                LocalStorageRepositoryInterface,
                RouterService,
                LoadingOverlayService,
                TokenService
            ]
        })
    ]
})
export class UsersRepositoryModule { }