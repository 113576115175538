import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TranslationManager } from "../managers/translation.manager";
import { TranslationResponseDto } from "src/app/common/dtos/translation/translation.response";

@Injectable({
    providedIn: "root"
})
export class TranslationService {

    constructor(
        private translationManager: TranslationManager
    ) {}

    public getTranslations(): Observable<TranslationResponseDto> {
        return this.translationManager.getTranslations();
    }
}