import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BackofficeComponent } from "./backoffice.component";
import { BrowserModule } from "@angular/platform-browser";
import { SidebarModule } from "./components/sidebar/sidebar.module";
import { SharedModule } from "src/app/shared/shared.module";
import { UsersModule } from "./components/users/users.module";
import { ObservationsModule } from "./components/observations/observations.module";
import { ProjectsModule } from "./components/projects/projects.module";
import { ReferencesModule } from "./components/references/references.module";

@NgModule({
    declarations: [
        BackofficeComponent
    ],
    imports: [
        BrowserModule,
        CommonModule,
        SidebarModule,
        SharedModule,
        UsersModule,
        ObservationsModule,
        ProjectsModule,
        ReferencesModule
    ],
    exports: [
        BackofficeComponent
    ]
})
export class BackofficeModule { }
