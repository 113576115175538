import { AutoMap } from "@automapper/classes";

export class MetaResponseDto {

    @AutoMap()
    public currentPage?: number;

    @AutoMap()
    public itemsPerPage?: number;

    @AutoMap()
    public sortBy? : string[];

    @AutoMap()
    public totalItems?: number;

    @AutoMap()
    public totalPages?: number;
}