import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { SelectFilterComponent } from "./select-filter.component";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
    declarations: [
        SelectFilterComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatIconModule
    ],
    exports: [
        SelectFilterComponent
    ]
})
export class SelectFilterModule { }
