import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCardModule } from "@angular/material/card";
import { MatOptionModule } from "@angular/material/core";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { AutoCompleteFilterComponent } from "./auto-complete-filter.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { TranslateModule } from "@ngx-translate/core";
@NgModule({
    declarations: [
        AutoCompleteFilterComponent
    ],
    imports: [
        CommonModule,
        MatCardModule,
        MatTableModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        TranslateModule
    ],
    exports: [
        AutoCompleteFilterComponent
    ]
})
export class AutoCompleteFilterModule { }
