import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { LoginDto } from "src/app/common/dtos/login/body/login.dto";
import { Token } from "src/app/common/models/token/token.model";
import { AuthManager } from "src/app/shared/managers/auth.manager";
import { RouterService } from "src/app/shared/services/router.service";
import { BackofficeComponent } from "../../backoffice/backoffice.component";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ObservationsComponent } from "../../backoffice/components/observations/observations.component";

@Component({
    selector: "app-login",
    templateUrl: "./login.component.html",
    styleUrls: [
        "./login.component.scss"
    ]
})
export class LoginComponent implements OnInit {
    loginForm!: FormGroup;
    loginError?: Error;
    token!: Token;

    constructor(
        private formBuilder: FormBuilder,
        private authManager: AuthManager,
        private router: RouterService,
        private snackBar: MatSnackBar
    ) {

    }

    ngOnInit(): void {
        this.formGenerate();

        if (this.authManager.logged) {
            this.router.navigateTo(BackofficeComponent);
        }
    }

    formGenerate(): void {
        this.loginForm = this.formBuilder.group({
            email: this.formBuilder.control("", [
                Validators.required,
                Validators.pattern(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/),
                Validators.minLength(5),
                Validators.maxLength(50)
            ]),
            password: this.formBuilder.control("", [
                Validators.required,
                Validators.minLength(8),
                Validators.maxLength(20)
            ])
        });
    }

    onSubmit(): void {
        const loginDto: LoginDto = {
            username: this.loginForm.get("email")?.value.toLowerCase(),
            password: this.loginForm.get("password")?.value
        };
        this.login(loginDto);
    }

    // TODO: manage unsuccessfull login attempt
    login(loginDto: LoginDto): void {
        this.authManager.login(loginDto).subscribe(
            () => {
                if (this.authManager.logged) {
                    this.router.navigateTo(ObservationsComponent);
                }
            },
            (error) => {
                this.snackBar.open("Wrong username or password", undefined, {
                    duration: 5000,
                    panelClass: "app-notification-error"
                });
            }
        );
    }

}