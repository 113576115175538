<mat-toolbar style="background-color: #d50000">
  <button
    mat-icon-button
    aria-label="Toggle sidenav"
    (click)="toggleSidenav.emit()"
    style="color: white"
  >
    <mat-icon>menu</mat-icon>
  </button>
  <span style="color: white">HSE Observation Card</span>
  <span class="spacer"></span>
  <!-- <span>{{Auth.getFullNameUser()}}</span> -->
  <!--
  <select #selectedLang (change)="switchLang(selectedLang.value)" class="language-select">
    <option *ngFor="let language of translate.getLangs()" [value]="language"
      [selected]="language === translate.currentLang">      
      <img src="../../../../../assets/images/flag/{{ language }}.png" alt="{{ language }}" class="flag-icon" />
      {{ language }}        
    </option>
  </select>

  <span style="width: 6%;">
    <mat-select class="language-select" #selectedLang
      (selectionChange)="switchLang(selectedLang.value)" disableOptionCentering [value]="'it'" style="padding-left: 0;">
      <mat-option *ngFor="let language of translate.getLangs()" [value]="language" >
        <img src="../../../../../assets/images/flag/{{ language }}.png" alt="{{ language }}" class="flag-icon" />
        {{ language }}
      </mat-option>
    </mat-select>
  </span>
-->
  <button mat-icon-button [matMenuTriggerFor]="menu" style="color: white">
    <mat-icon>account_circle_outlined</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item>
      <mat-icon>person</mat-icon>
      <span>{{ this.username }}</span>
    </button>

    <button mat-menu-item [matMenuTriggerFor]="flags">
      <img
        src="../../../../../assets/images/flag/{{ translate.currentLang }}.svg"
        alt="{{ translate.currentLang }}"
        class="flag-icon"
      />
      <span>{{ translate.currentLang | translate }}</span>
    </button>
    <button mat-menu-item [matMenuTriggerFor]="password">
      <mat-icon>lock_person</mat-icon>
      <span>Manage Password</span>
    </button>
    <button mat-menu-item (click)="onUserLogout()">
      <mat-icon>logout</mat-icon>
      <span>LogOut</span>
    </button>
  </mat-menu>
  <mat-menu #flags="matMenu">
    <button
      mat-menu-item
      *ngFor="let language of translate.getLangs()"
      (click)="switchLang(language)"
    >
      <img
        src="../../../../../assets/images/flag/{{ language }}.svg"
        alt="{{ language }}"
        class="flag-icon"
      />
      <span>{{ language | translate }}</span>
      <!--<mat-icon class="check-icon" *ngIf="language === translate.currentLang">check</mat-icon>-->
    </button>
  </mat-menu>
  <mat-menu #password="matMenu">
    <!--<button
      mat-menu-item
      (click)="resetPassword()"
      [disabled]="this.user?.role === 'USER'"
    >
      <mat-icon>lock_reset</mat-icon>
      <span>Reset Password</span>
    </button>-->
    <button mat-menu-item (click)="changePassword()">
      <mat-icon>settings</mat-icon>
      <span>Change Password</span>
    </button>
  </mat-menu>
</mat-toolbar>
