<div class="container">
  <div>
    <div class="divTitle">
      <h2 class="title">{{ data.title | translate }}</h2>
    </div>
    <div class="divIcon">
      <button
        mat-icon-button
        (click)="buttonClicked('back')"
        color="basic"
        style="min-width: auto"
        tabindex="-1"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <p class="headerText" *ngIf="data.headerText">
    {{ data.headerText | translate }}
  </p>

  <mat-stepper orientation="horizontal" linear="true" class="stepper" #stepper>
    <mat-step
      *ngFor="let step of data.steps"
      [stepControl]="step.form!"
      errorMessage="Controllare i dati inseriti."
    >
      <form [formGroup]="step.form!" style="text-align: center">
        <ng-template matStepLabel>{{ step.title | translate }} </ng-template>

        <ng-container
          class="item"
          *ngFor="let field of step.fields"
          [ngSwitch]="field.type"
        >
          <mat-form-field *ngSwitchCase="'text'" class="formField">
            <mat-label>{{ field.label | translate }}</mat-label>
            <input
              matInput
              [id]="field.fieldName!"
              [formControlName]="field.fieldName!"
              class="fullWidth"
            />
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'textarea'" class="formField">
            <mat-label>{{ field.label | translate }}</mat-label>
            <textarea
              matInput
              [id]="field.fieldName!"
              [formControlName]="field.fieldName!"
              class="fullWidth"
            ></textarea>
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'number1'" class="formField">
            <mat-label>{{ field.label | translate }}</mat-label>
            <input
              type="number"
              [min]="field.boundaries?.min ?? null"
              [max]="field.boundaries?.max ?? null"
              matInput
              [formControl]="field.formControl!"
              class="fullWidth"
            />
          </mat-form-field>

          <mat-form-field *ngSwitchCase="'number'" class="formField">
            <input
              type="number"
              matInput
              [id]="field.fieldName!"
              [formControlName]="field.fieldName!"
              class="fullWidth"
            />
          </mat-form-field>

          <div
            *ngSwitchCase="'select'"
            style="width: 100%"
            class="containerHorizontal"
          >
            <mat-form-field appearance="fill" class="formField itemHorizontal">
              <mat-label>{{ field.label | translate }}</mat-label>
              <mat-select
                [id]="field.fieldName!"
                [formControlName]="field.fieldName!"
                class="fullWidth"
              >
                <mat-option
                  *ngFor="let opt of field.filteredOptions || field.options"
                  [value]="opt.value"
                  >{{ opt.key | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <span
              class="clickable itemHorizontal"
              *ngIf="field.formControl!.getRawValue()"
              (click)="clearSelection(field)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </div>

          <mat-form-field
            *ngSwitchCase="'selectInput'"
            appearance="fill"
            class="formField itemHorizontal"
          >
            <mat-label>{{ field.label | translate }}</mat-label>
            <mat-select
              [formControlName]="field.fieldName!"
              [id]="field.fieldName!"
              class="fullWidth"
              (openedChange)="onSelectOpened($event)"
            >
              <input
                matInput
                #searchField
                class="search-input"
                (keyup)="filterSelectOptions($event, field)"
                placeholder="Cerca..."
              />
              <mat-option
                *ngFor="let opt of field.filteredOptions || field.options"
                [value]="opt.value"
                >{{ opt.key | translate }}</mat-option
              >
            </mat-select>
          </mat-form-field>

          <!--MULTISELECT-->
          <div
            *ngSwitchCase="'multiSelect'"
            style="width: 100%"
            class="containerHorizontal"
          >
            <mat-form-field appearance="fill" class="formField itemHorizontal">
              <mat-label>{{ field.label | translate }}</mat-label>
              <mat-select
                [id]="field.fieldName!"
                [formControlName]="field.fieldName!"
                class="fullWidth"
                multiple
              >
                <mat-option
                  *ngFor="let opt of field.filteredOptions || field.options"
                  [value]="opt.value"
                  >{{ opt.key | translate }}</mat-option
                >
              </mat-select>
            </mat-form-field>
            <span
              class="clickable itemHorizontal"
              *ngIf="field.formControl!.getRawValue()"
              (click)="clearSelection(field)"
            >
              <mat-icon>close</mat-icon>
            </span>
          </div>
          <!--
          <div
          *ngSwitchCase="'tabs'"
          style="width: 100%"
          class="containerHorizontalTabs"
            >
            <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="center" style="max-height: 50%;">
              <mat-tab *ngFor="let tab of field.tabs">
                <ng-template mat-tab-label>
                  {{ tab.label }}
                </ng-template>
                
                  <p *ngFor="let f of tab.content">
                    <mat-checkbox >{{f}}</mat-checkbox>
                  </p>
              
                
              </mat-tab>
          </mat-tab-group>
          </div>
-->
          <mat-form-field
            *ngSwitchCase="'datetime'"
            apparence="fill"
            style="width: 90%"
          >
            <input
              matInput
              [ngxMatDatetimePicker]="picker"
              [placeholder]="field.label | translate"
              [formControlName]="field.fieldName!"
              class="fullWidth"
              [min]="minDate"
              [max]="maxDateTime"
              [disabled]="disabled"
            />
            <mat-datepicker-toggle
              matSuffix
              [for]="$any(picker)"
            ></mat-datepicker-toggle>
            <ngx-mat-datetime-picker
              #picker
              [showSpinners]="showSpinners"
              [showSeconds]="showSeconds"
              [stepHour]="stepHour"
              [stepMinute]="stepMinute"
              [stepSecond]="stepSecond"
              [touchUi]="touchUi"
              [color]="color"
              [enableMeridian]="enableMeridian"
            >
            </ngx-mat-datetime-picker>
          </mat-form-field>

          <mat-form-field
            *ngSwitchCase="'date'"
            apparence="fill"
            style="width: 90%"
          >
            <mat-label>{{ field.label | translate }}</mat-label>
            <input
              [matDatepicker]="datePicker"
              matInput
              [id]="field.fieldName!"
              [formControlName]="field.fieldName!"
              class="fullWidth"
            />
            <mat-datepicker-toggle matIconSuffix [for]="datePicker"
              ><mat-icon matDatepickerToggleIcon
                >keyboard_arrow_down</mat-icon
              ></mat-datepicker-toggle
            >
            <mat-datepicker #datePicker></mat-datepicker>
          </mat-form-field>
        </ng-container>
      </form>

      <div
        style="
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        "
      >
        <div
          *ngFor="let button of step.buttons"
          style="width: -webkit-fill-available"
        >
          <button
            mat-raised-button
            matStepperPrevious
            *ngIf="button.type === 'previous'"
            style="min-width: 100%"
            [color]="button.color"
          >
            <mat-icon>{{ button.icon }}</mat-icon>
            {{ button.label | translate }}
          </button>

          <button
            mat-raised-button
            style="min-width: 100%"
            *ngIf="button.type !== 'next' && button.type !== 'previous'"
            (click)="buttonClicked(button.type)"
            [color]="button.color"
          >
            {{ button.label | translate }}
            <mat-icon>{{ button.icon }}</mat-icon>
          </button>

          <button
            mat-raised-button
            matStepperNext
            *ngIf="button.type === 'next'"
            style="min-width: 100%"
            [color]="button.color"
          >
            {{ button.label | translate }}
            <mat-icon iconPositionEnd>{{ button.icon }}</mat-icon>
          </button>
        </div>
      </div>
    </mat-step>
  </mat-stepper>
</div>
