import { AutoMap } from "@automapper/classes";
import { ActivityType } from "../activity-type/activity-type.model";
import { Reference } from "../reference/reference.model";
import { Status } from "../status/status.model";

export class ObservationExport {
    @AutoMap()
    public insertDate!: Date;

    @AutoMap()
    public firstName!: string;

    @AutoMap()
    public lastName!: string;

    @AutoMap()
    public company! : string;

    @AutoMap()
    public role?: string;

    @AutoMap()
    public project!:string;

    @AutoMap()
    public activity!: string;

    @AutoMap()
    public observation! : string;

    @AutoMap()
    public action!: string;

    @AutoMap()
    public activityType!: string;

    @AutoMap()
    public refString!: string[];

    @AutoMap()
    public statusDescription?: string;

}
